import React from 'react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Wrapper } from './styles'
import {
  AVAILABLE_HOLDERS,
  AVAILABLE_SUBSCRIPTIONS,
  computeIntentSummary,
  HolderCode,
  BusinessOrderIntent,
} from '@meniudigital/shared'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
SwiperCore.use([Autoplay, Navigation])

const useColorChange = (_: any, __: any): any => {}

const flashOptions = {
  higher: '#11b7f3',
  lower: '#ff5723',
  duration: 3800,
}

type Props = {
  order: BusinessOrderIntent
  compact?: boolean
  withEdit?: boolean
}

export default function BusinessOrderSummary({ order, compact, withEdit }: Props) {
  const navigate = useNavigate()

  const { stickerHolderCount = 0, laminatedHolderCount = 0, plexiglassHolderCount = 0, subscriptionCode } = order

  const { totalPrice, hasDelivery, deliveryPrice } = computeIntentSummary(order)

  const stickerHolderCountStyle = useColorChange(stickerHolderCount, flashOptions)
  const laminatedHolderCountStyle = useColorChange(laminatedHolderCount, flashOptions)
  const plexiglassHolderCountStyle = useColorChange(plexiglassHolderCount, flashOptions)
  const subscriptionStyle = useColorChange(subscriptionCode, flashOptions)
  const totalStyle = useColorChange(totalPrice, flashOptions)

  const stickerHolder = AVAILABLE_HOLDERS.find(x => x.code === HolderCode.Sticker)!
  const laminatedHolder = AVAILABLE_HOLDERS.find(x => x.code === HolderCode.Laminated)!
  const plexiglassHolder = AVAILABLE_HOLDERS.find(x => x.code === HolderCode.Plexiglass)!

  if (!totalPrice) {
    return null
  }

  return (
    <Wrapper className={'panel' + (compact ? ' compact' : '')}>
      {Boolean(subscriptionCode) && (
        <div className="line">
          <span style={subscriptionStyle}>
            {AVAILABLE_SUBSCRIPTIONS.find(x => x.code === subscriptionCode)?.name}
            {withEdit && (
              <span className="product-edit-button" title="Editează" onClick={() => navigate(Screen.ChooseSubscription)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            )}
          </span>
          <span style={subscriptionStyle}>{AVAILABLE_SUBSCRIPTIONS.find(x => x.code === subscriptionCode)?.price || 0} RON</span>
        </div>
      )}
      {Boolean(stickerHolderCount) && (
        <div className="line">
          <span>{stickerHolder.name}</span>
          <span className="quantity" style={stickerHolderCountStyle}>
            <span className="small">x</span>
            {stickerHolderCount}
          </span>
          <span style={stickerHolderCountStyle}>{stickerHolderCount * (stickerHolder.price || 0)} RON</span>
        </div>
      )}
      {Boolean(laminatedHolderCount) && (
        <div className="line">
          <span>{laminatedHolder?.name}</span>
          <span className="quantity" style={laminatedHolderCountStyle}>
            <span className="small">x</span>
            {laminatedHolderCount}
          </span>
          <span style={laminatedHolderCountStyle}>{laminatedHolderCount * (laminatedHolder.price || 0)} RON</span>
        </div>
      )}
      {Boolean(plexiglassHolderCount) && (
        <div className="line">
          <span>{plexiglassHolder?.name}</span>
          <span className="quantity" style={plexiglassHolderCountStyle}>
            <span className="small">x</span>
            {plexiglassHolderCount}
          </span>
          <span style={plexiglassHolderCountStyle}>{plexiglassHolderCount * (plexiglassHolder.price || 0)} RON</span>
        </div>
      )}
      {hasDelivery && (
        <div className="line">
          <span>Livrare</span>
          <span>{deliveryPrice} RON</span>
        </div>
      )}

      <div className="line strong">
        <span>Total</span>
        <span style={totalStyle}>{totalPrice} RON</span>
      </div>
    </Wrapper>
  )
}
