import React, { useEffect } from 'react'
import Button from 'src/global/Button'
import { Wrapper } from './styles'
import { businessOrderEndpoints, userEndpoints } from 'src/api'
import { BillingInfo, BillingType, BusinessOrder } from '@meniudigital/shared'
import { addToOrderIntent, isCuiValid } from 'src/state/business-orders'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import useForm from 'src/helpers/useForm'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'

export default function EnterBillingInfo() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isValid, formValues, register, setValue } = useForm()
  const orderIntent = useSelector((state: State) => state.businessOrders.orderIntent)!

  const billingTypeFromParams = new URLSearchParams(window.location.search).get('type') as BillingType

  const billingType = billingTypeFromParams || orderIntent.billingInfo?.billingType

  const submitForm = async () => {
    const billingInfo: BillingInfo = {
      billingType,
      cui: billingType === BillingType.Company ? formValues['cui']?.value : '',
      address: formValues['address'].value,
      city: formValues['city'].value,
      county: formValues['county'].value,
      country: 'România',
      name: formValues['name'].value,
      phoneNumber: formValues['phoneNumber'].value,
    }

    try {
      const { fbq } = window as any
      fbq?.('track', 'AddPaymentInfo')
    } catch (e) {}

    dispatch(addToOrderIntent({ billingInfo }))
    navigate(Screen.FinalizeOrder)
  }

  useEffect(() => {
    if (orderIntent?.billingInfo) {
      autoFillWith(orderIntent.billingInfo)
    } else {
      autoFillFromLastOrderOrFactureaza()
    }
  }, [])

  const autoFillWith = async (billingInfo: BillingInfo) => {
    Object.keys(billingInfo).forEach(key => setValue(key, (billingInfo as any)[key]))
  }

  const autoFillFromLastOrderOrFactureaza = async () => {
    const mostRecentOrder = await businessOrderEndpoints.getMostRecent()

    if (mostRecentOrder?.billingInfo && mostRecentOrder.billingInfo.billingType === billingType) {
      autoFillWith(mostRecentOrder.billingInfo)
    } else {
      const billingInfoFromFactureaza = await userEndpoints.getBillingInfo()

      if (billingInfoFromFactureaza?.address && billingInfoFromFactureaza?.billingType === billingType) {
        autoFillWith(billingInfoFromFactureaza)
      }
    }
  }

  const autoFillFromAnafIfExists = () => {
    setTimeout(async () => {
      if (isCuiValid(formValues['cui']?.value)) {
        const billingInfo: BusinessOrder['billingInfo'] = await businessOrderEndpoints.getCompanyBillingInfo(
          formValues['cui']?.value
        )

        if (billingInfo?.address?.length) {
          Object.keys(billingInfo).forEach(key => (billingInfo as any)[key] && setValue(key, (billingInfo as any)[key]))
        }
      }
    }, 0)
  }

  const autoFillFromShippingIfExists = () => {
    const shippingInfo = orderIntent.shippingInfo!

    Object.keys(shippingInfo).forEach(x => setValue(x, (shippingInfo as any)[x]))
  }

  const isCuiRequiredAndValid = billingType !== BillingType.Company || isCuiValid(formValues['cui']?.value)

  return (
    <Wrapper>
      <div className="page">
        <h3>Ce date de facturare să folosim?</h3>
        <form>
          {billingType === BillingType.Company && (
            <>
              <label aria-required={true}>CUI firmă</label>
              <input
                {...register('cui', false, autoFillFromAnafIfExists)}
                type="number"
                min="0"
                pattern="[0-9]*"
                placeholder="CUI firmă"
                className={formValues['cui']?.value.length > 1 && !isCuiValid(formValues['cui']?.value) ? 'invalid' : ''}
              />
            </>
          )}

          {orderIntent?.shippingInfo && billingType !== BillingType.Company && (
            <Button variant="as-text" text="Aceleași ca la livrare" onClick={autoFillFromShippingIfExists} />
          )}

          {billingType === BillingType.Company ? (
            <>
              <label aria-required={true}>Nume companie</label>
              <input placeholder="Nume companie" {...register('name')} />
            </>
          ) : (
            <>
              <label aria-required={true}>Nume complet</label>
              <input placeholder="Nume complet" {...register('name')} />
            </>
          )}

          <label aria-required={true}>Adresă</label>
          <input placeholder="Stradă, număr/bloc, apartament, etc." {...register('address')} />

          <label aria-required={true}>Oraș</label>
          <input placeholder="Oraș" {...register('city')} />

          <label aria-required={true}>Județ</label>
          <input placeholder="Județ" {...register('county')} />

          <label aria-required={true}>Telefon</label>
          <input placeholder="Telefon" {...register('phoneNumber')} />
        </form>
        <Button
          onClick={submitForm}
          className="continue-button"
          text="Continuă"
          icon={faArrowRight}
          disabled={!isValid || !isCuiRequiredAndValid}
        />
      </div>
    </Wrapper>
  )
}
