import React from 'react'
import styled from 'styled-components/macro'
import HeaderDarkButtonIcon from './Icon'
import { headerItem } from 'src/global/helpers'
import { useDispatch } from 'react-redux'
import { toggleDarkTheme } from 'src/state/theme'

const DarkButton = styled.span`
  ${headerItem};

  padding: 0 8px;
  cursor: pointer;
  margin-right: 4px;

  @media (hover: hover) {
    :hover path {
      fill: ${props => props.theme.accent};
    }
  }
`

export default function HeaderDarkButton() {
  const dispatch = useDispatch()

  return (
    <DarkButton onClick={() => dispatch(toggleDarkTheme())}>
      <HeaderDarkButtonIcon />
    </DarkButton>
  )
}
