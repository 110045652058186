import React, { ComponentPropsWithoutRef } from 'react'
import { Wrapper } from './styles'

type Props = {
  text: String | JSX.Element
  isChecked: boolean
  disabled?: boolean
  onChange: () => void
} & ComponentPropsWithoutRef<'div'>

export default function Checkbox(props: Props) {
  return (
    <Wrapper className={props.disabled ? 'disabled' : ''}>
      <label className="checkbox">
        <input type="checkbox" name="check" checked={props.isChecked} onChange={props.onChange} />
        <span className="label-text">{props.text}</span>
      </label>
    </Wrapper>
  )
}
