import styled from 'styled-components/macro'

export const Wrapper = styled.button`
  height: 45px;
  border: 0;
  border-radius: 30px;
  padding: 0 32px;
  background: ${props => props.theme.accent};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: ${props => props.theme.white};
  cursor: pointer;
  outline: none;
  margin-top: 16px;
  position: relative;
  white-space: nowrap;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${props => props.theme.white};
  }

  .fa-gear-btm {
    font-size: 21px !important;
    margin-top: 2px;
    margin-left: -2px;
  }

  span {
    margin-bottom: 0 !important;
  }

  &[data-responsive] {
    @media (max-width: 768px) {
      width: 45px;
      padding-right: 8px !important;
      padding-left: 26px !important;

      span {
        display: none;
      }
    }
  }

  &[data-circular] {
    width: 45px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-wrapper,
    .icon-wrapper-inline {
      margin: 0;
      width: auto;
      height: auto;

      i,
      svg {
        font-size: 16px;
        color: ${props => props.theme.accent};
        margin-bottom: -1px;
      }
    }

    span {
      display: none;
    }
  }

  &[data-notext] {
    .icon-wrapper {
      right: 13px !important;
      color: ${props => props.theme.accent}!important;
      background: ${props => props.theme.white}!important;
      font-size: 28px !important;
    }
  }

  &[data-variant='secondary'] {
    border: 1px solid #dde2e5;
    color: ${props => props.theme.accent};
    background: ${props => props.theme.white};
    padding: 12px 24px;
    font-weight: 600;
    transition: none;

    .icon-wrapper {
      width: 20px;
      height: 20px;
      right: -8px;
      top: 13px;
      background: ${props => props.theme.accent};
      color: #fff;
    }

    :hover {
      filter: none;
      border-color: ${props => props.theme.accent};
    }

    .icon {
      font-size: 12px;
      color: ${props => props.theme.white};
    }
  }

  &[data-variant='as-text'],
  &[data-variant='as-text-danger'] {
    background: transparent;
    color: ${props => props.theme.accent};
    font-weight: 600;

    &[disabled] {
      background: transparent;
      color: ${props => props.theme.disabledButton};
      cursor: default;
      pointer-events: none;

      .icon-wrapper {
        display: none;
      }
    }
  }

  &[data-variant='as-text-danger'] {
    color: ${props => props.theme.red};
  }

  :hover,
  :active {
    filter: brightness(109%);
    transition: all 0.1s ease-out;
  }

  .icon-wrapper {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -10px;
    top: 12px;
    background: ${props => props.theme.white};
    color: #11b7f3;
    border-radius: 100%;
    padding-bottom: 1px;
  }

  .icon-wrapper-inline {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    margin-left: -10px;

    i {
      color: #11b7f3;
      font-size: 25px;

      &.fa-plus-mdc {
        margin-left: -2px;
      }
    }
  }

  .icon {
    font-size: 13px;
    margin-top: 1px;
  }

  .image-icon {
    width: 22px;
    margin-left: -16px;
    margin-right: 8px;
  }

  &[disabled] {
    background: ${props => props.theme.disabledButton};
    cursor: default;
    pointer-events: none;

    .icon-wrapper {
      display: none;
    }
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .loader {
    animation: spin 1s infinite linear;
    border: 0.3rem solid ${props => props.theme.accent + '4d'};
    border-top-color: ${props => props.theme.accent};
    border-radius: 50%;
    width: 27px;
    height: 27px;
    position: absolute;
    left: 25px;
    top: 22px;
  }
`
