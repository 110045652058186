import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  @keyframes away-and-back-in {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    70% {
      transform: translateX(-200px);
      opacity: 0;
    }
    71% {
      transform: translateX(200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  padding: 0px !important;
  right: 8px !important;
  width: 245px !important;

  @media (min-width: 768px) {
    width: 260px !important;
  }

  &.animating {
    .question-wrapper {
      animation: away-and-back-in 1s ease-in-out forwards;
      animation-delay: 0.4s;
    }

    &.clicked-yes {
      .full-button.no {
        flex: 0.0001 !important;
        width: 0 !important;
        padding: 0 !important;
      }
    }

    &.clicked-no {
      .full-button.yes {
        flex: 0.0001 !important;
        width: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .body {
    padding: 16px;

    span {
      padding-right: 8px;
    }
  }

  .question-wrapper {
    animation-fill-mode: forwards;
  }

  img {
    height: 40px;
    border-radius: 3px;
    margin-bottom: 4px;
    display: inline;
  }

  .footer {
    display: flex;

    .full-button {
      padding: 16px;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      display: flex;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      flex: 1;

      &:hover {
        filter: brightness(1.1);
      }

      &.yes {
        background: ${props => props.theme.accent};
        color: ${props => props.theme.white};
        border-top-right-radius: 8px;
      }

      &.no {
        color: ${props => props.theme.accent};
      }
    }
  }
`
