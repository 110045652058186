import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import Toggle from 'react-toggle'
import { UserPatchType } from '@meniudigital/shared'

export default function MaintenanceSetting() {
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)

  if (!user) {
    return null
  }

  const toggleMaintenanceMode = async () => {
    try {
      if (!user.inMaintenanceMode) {
        await window.showModal({
          title: `Dezactivare meniu`,
          text: `Ești sigur că dorești dezactivarea completă a meniului tău? Acesta nu va mai putea fi accesat de către clienți până la reactivare.`,
          confirmButtonText: 'Da, dezactivează',
          cancelButtonText: 'Nu',
        })
        const payload = {
          type: UserPatchType.InMaintenanceMode,
          data: true,
        }
        await dispatch(patchCurrentUser(payload as any))
        await window.showConfirm({ text: `Meniul tău a fost dezactivat.` })
      } else {
        await window.showModal({
          title: `Activare meniu`,
          text: `Ești sigur că dorești reactivarea meniului tău? Acesta va fi accesibil de către clienți.`,
          confirmButtonText: 'Da, reactivează',
          cancelButtonText: 'Nu',
        })
        const payload = {
          type: UserPatchType.InMaintenanceMode,
          data: false,
        }
        await dispatch(patchCurrentUser(payload as any))
        await window.showConfirm({ text: `Meniul tău a fost reactivat.` })
      }
    } catch {}
  }

  return (
    <div className="grouped">
      <div className="setting-explainer">
        {user.inMaintenanceMode ? 'Dorești să reactivezi codul tău QR?' : 'Dorești dezactivarea completă a codului tău QR?'}
      </div>
      <div className="setting">
        <div className="setting-name">
          <div className="setting-icon" style={user.inMaintenanceMode ? { filter: 'saturate(0)' } : {}}>
            {' '}
            <img src="/icons/qr-menu.png" alt="QR Code icon" />
          </div>
          <span>Disponibilitate Cod QR</span>
          {!user?.inMaintenanceMode && <div className="bullet" />}
        </div>

        <div className="right-side">
          <span className="only-desktop">{!user?.inMaintenanceMode ? 'Accesibil' : 'Indisponibil'}</span>
          <Toggle className="toggle" checked={!user?.inMaintenanceMode} icons={false} onChange={toggleMaintenanceMode} />
        </div>
      </div>
    </div>
  )
}
