import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .chart-wrapper {
    display: flex;
    overflow: auto;
    padding: 24px;
    background: ${props => props.theme.white};
    border-radius: 16px;
    margin: 16px 0;

    @media (max-width: 768px) {
      padding: 8px;
      border-radius: 0;
      width: 100%;
    }
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px 0 34px 0;
    width: 720px;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    @media (max-width: 768px) {
      width: min(1024px, calc(100vw - 32px)) !important;
      margin-right: 0;
    }
  }

  .order {
    justify-content: flex-start;
    flex-direction: row;
    padding: 0;
    width: calc(100% - 48px);
    margin: 0 24px 24px 24px;
    align-items: stretch;
    position: relative;
    box-shadow: 4px 0 12px ${props => props.theme.shadow};

    .field {
      display: flex;
      flex-direction: column;
      padding: 16px;
      padding-right: 16px;
      border-right: 1px solid ${props => props.theme.border};
      flex: 1;
      padding-bottom: 60px;

      @media (max-width: 768px) {
        padding-bottom: 16px;
      }

      &.active-bg {
        background: ${props => props.theme.inputBackground};
      }

      &.shipping-and-billing {
        div {
          margin-bottom: 8px;
        }

        code {
          background: ${props => props.theme.shadow};
          padding: 2px 5px;
          border-radius: 4px;
          border: 1px solid ${props => props.theme.border};
          margin-left: 2px;
          display: inline-block;

          max-width: min(500px, 100vw - 68px);
          overflow: auto;
          white-space: nowrap;
        }
      }

      label {
        margin-top: 0;
        margin-bottom: 6px;

        &:not(:first-of-type) {
          margin-top: 20px;
        }
      }

      .client-info {
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 16px;
        }
      }

      img {
        width: 50px;
        margin-right: 8px;
        margin-bottom: 16px;
      }

      :last-of-type {
        border-right-width: 0;
      }

      [data-variant='as-text'] {
        height: 30px;
      }

      .order-status {
        position: absolute;
        font-weight: 700;
        color: ${props => props.theme.white};
        padding: 8px;
        border-radius: 16px;
        background-color: orange;
        text-align: center;
        bottom: 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
        width: calc(100%);
        width: 290px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 14px;
      }

      .red {
        color: ${props => props.theme.red};
        white-space: normal !important;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding-bottom: 50px;
      width: calc(100% - 32px);

      .field {
        border-right: none;
      }

      button {
        max-width: calc(100% - 8px);
      }
    }
  }

  .compact {
    margin-bottom: 0 !important;
  }

  .with-wifi {
    font-size: 14px;

    .icon {
      font-size: 14px;
      margin-right: 8px;
      color: ${props => props.theme.accent};
    }
  }

  .info {
    p {
      text-align: center;

      :last-of-type {
        margin-top: 16px;
      }
    }

    button {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`
