import React from 'react'
import { Wrapper } from './styles'

export default function LoadingProgressBar() {
  return (
    <Wrapper>
      <div className="progress-bar">
        <div className="progress-bar-value"></div>
      </div>
    </Wrapper>
  )
}
