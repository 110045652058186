import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  .chart-wrapper {
    display: flex;
    overflow: auto;
    padding: 24px;
    background: ${props => props.theme.white};
    border-radius: 16px;

    @media (max-width: 768px) {
      padding: 8px;
      border-radius: 0;
    }
  }
`
