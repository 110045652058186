import React, { useState } from 'react'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector } from 'src/state/selectors'
import { Wrapper } from './styles'
import moment from 'moment'
import wait from 'src/helpers/wait'
import { patchProduct } from 'src/state/products'
import { ProductPatchType } from '@meniudigital/shared'

export default function StockReminderToast() {
  const dispatch = useDispatch()
  const [hasDismissedToast, setHasDismissedToast] = useState<boolean>(false)
  const [answeredProductIds, setAnsweredProductIds] = useState<string[]>([])
  const [isAnimating, setIsAnimating] = useState<boolean | 'clicked-no' | 'clicked-yes'>(false)

  const { list: products } = useSelector(productsSelector)

  const fourDaysAgo = moment().subtract(4, 'days')
  const reminderableProducts = products.filter(x => moment(x.updatedAt).isBefore(fourDaysAgo) && !x.isAvailable)

  const currentProduct = reminderableProducts.find(x => !answeredProductIds.includes(x.id))

  if (!reminderableProducts || !currentProduct || hasDismissedToast) {
    return null
  }

  const updateProductWithNotYet = async () => {
    dispatch(
      patchProduct({
        type: ProductPatchType.IsAvailable,
        data: false,
        productId: currentProduct.id,
      })
    )

    setIsAnimating('clicked-no')
    animateAndGoToNextProduct()
  }

  const updateProductWithYes = async () => {
    dispatch(
      patchProduct({
        type: ProductPatchType.IsAvailable,
        data: true,
        productId: currentProduct.id,
      })
    )

    setIsAnimating('clicked-yes')
    animateAndGoToNextProduct()
  }

  const animateAndGoToNextProduct = async () => {
    await wait(1000)
    setAnsweredProductIds([...answeredProductIds, currentProduct?.id])
    await wait(600)
    setIsAnimating(false)
  }

  return (
    <Wrapper className={'info-prompt' + (isAnimating ? ` animating ${isAnimating} ` : '')}>
      <div className="body">
        <div className="question-wrapper">
          {currentProduct?.imageUrl && <img src={currentProduct?.imageUrl} />}
          <div className="question">
            A revenit pe stoc produsul <strong>"{currentProduct?.names['ro']}"</strong>? 📦
          </div>
        </div>
        <CloseButton title="Închide" onClick={() => setHasDismissedToast(true)} />
      </div>
      <div className="footer">
        <div onClick={updateProductWithYes} className="full-button yes" title="Actualizează produsul ca disponibil">
          Da, actualizează
        </div>
        <div
          onClick={updateProductWithNotYet}
          className="full-button no"
          title="Actualizează produsul ca indisponibil în continuare"
        >
          Nu încă
        </div>
      </div>
    </Wrapper>
  )
}
