import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: 0 0px 12px ${props => props.theme.mutedText}44;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.white};
  background: ${props => props.theme.white};
  box-shadow: none;
  border: 1px solid ${props => props.theme.border};
  overflow: hidden;
  width: 100%;
  position: relative;

  label {
    font-size: 17px;
    font-weight: 500;
    margin-top: 0;
    text-transform: none;
  }

  .left-side {
    width: 100%;
    text-align: start;

    input {
      width: 100%;
      padding-right: 8px;
    }
  }
  &:first-of-type {
    margin-top: 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-width: 0px;
  }

  &:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-width: 0px;
  }

  .toggle {
    margin-left: 8px;

    &.react-toggle .react-toggle-thumb {
      height: 28px;
      width: 28px;
    }

    &.react-toggle--focus .react-toggle-thumb {
      box-shadow: none !important;
    }

    &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: ${props => props.theme.mutedText}44;
    }

    &.react-toggle .react-toggle-track {
      height: 30px;
      width: 56px;
      background-color: ${props => props.theme.mutedText}88;
    }

    &.react-toggle:not(.react-toggle--checked) .react-toggle-thumb {
      border-color: ${props => props.theme.mutedText}88;
    }

    &.react-toggle--checked .react-toggle-thumb {
      border-color: ${props => props.theme.accent};
    }

    &.react-toggle--checked .react-toggle-track {
      background-color: ${props => props.theme.accent};
    }

    &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: ${props => props.theme.accent}bb;
    }
  }
`
