import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 66px;

  @media(max-width: 768px) {
    gap: 8px;
  }

  .day-pill {
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    height: 28px;
    font-size: 14px;
    box-shadow: -4px 0 12px ${props => props.theme.shadow};

    img {
      width: 20px;
      margin-right: 6px;
      margin-left: -4px;
    }
  }

  .day-letter {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #11b7f3;
    color: ${props => props.theme.white};
    padding-left: 10px;
    padding-right: 7px;
    font-weight: 600;
  }

  .hour-interval {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.normalText};
    padding-left: 9px;
    padding-right: 12px;
  }
`
