import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;

  .next-bill-date {
    text-align: center;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;

    &.red {
      color: ${props => props.theme.red};
      font-weight: 600;
    }
  }
`
