import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { businessOrderEndpoints } from 'src/api'
import { addToOrderIntent } from 'src/state/business-orders'
import { BusinessOrderPaymentType, SubscriptionCode } from '@meniudigital/shared'

export default function RenewSubscription() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const createOrderAndGoToFinalize = async () => {
    try {
      const mostRecentOrder = await businessOrderEndpoints.getMostRecent()

      if (!mostRecentOrder.billingInfo) {
        navigate(Screen.ChooseSubscription)
        return
      }

      dispatch(
        addToOrderIntent({
          billingInfo: mostRecentOrder.billingInfo,
          paymentType: BusinessOrderPaymentType.OnlineCard,
          subscriptionCode: SubscriptionCode.MDBUSINESS12,
        })
      )
      navigate(Screen.FinalizeOrder, { replace: true });
    } catch (e) {
      navigate(Screen.ChooseSubscription)
    }
  }

  useEffect(() => {
    createOrderAndGoToFinalize()
  }, [])

  return null
}
