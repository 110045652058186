import React from 'react'
import { Wrapper } from './styles'

export default class ChangePassword extends React.Component {
  render() {
    return (
      <Wrapper>
        <div className="page">
          <img src="/logo.svg" alt="MeniuDigital logo" width="40px" style={{ marginBottom: '32px' }} />

          <h1>Politica privind confidențialitatea</h1>
          <p>
            Acest document privind politica de confidențialitate conține tipuri de informații care sunt colectate și
            înregistrate de MeniuDigital și modul în care le folosim.
          </p>
          <p>
            Dacă aveți întrebări suplimentare sau aveți nevoie de mai multe informații despre politica noastră de
            confidențialitate, nu ezitați să ne contactați.
          </p>
          <p>
            Această politică de confidențialitate se aplică numai activităților noastre online și este valabilă pentru
            vizitatorii Platformei cu privire la informațiile pe care le-au distribuit și / sau colectat în MeniuDigital. Această politică nu se aplică informațiilor colectate offline sau prin alte canale decât acest site
            web.
          </p>
          <h3>Consimțământ</h3>
          <p>
            Prin utilizarea Platformei noastre web, sunteți de acord cu politica noastră de confidențialitate și sunteți
            de acord cu termenii acesteia.
          </p>
          <h3>Informațiile pe care le colectăm</h3>
          <p>
            Informațiile personale pe care vi se cere să le furnizați și motivele pentru care vi se solicită să le
            furnizați, vă vor fi clarificate în momentul în care vă rugăm să furnizați informațiile dvs. personale.
          </p>
          <p>
            Dacă ne contactați direct, este posibil să primim informații suplimentare despre dvs., cum ar fi numele
            dvs., adresa de e-mail, numărul de telefon, datele de identificare ale companiei dvs., sau orice alte
            informații pe care ați putea să le furnizați.
          </p>
          <p>
            Când vă înregistrați pentru un cont, este posibil să vă solicităm informațiile de contact, inclusiv elemente
            precum numele, numele companiei, adresa, date de identificare ale companiei, adresa de e-mail și/sau numărul
            de telefon.
          </p>
          <h3>Cum utilizăm informația</h3>
          <p>Folosim informațiile pe care le colectăm în diferite moduri, inclusiv pentru:</p>
          <ul style={{ textAlign: 'left' }}>
            <li>Furnizarea, operarea și întreținerea Platformei noastre</li>
            <li>Îmbunătățirea, personalizarea și extinderea Platformei noastre</li>
            <li>Înțelegerea și analizarea modului în care utilizați site-ul nostru web</li>
            <li>Dezvoltăm produse, servicii, caracteristici și funcționalități noi</li>
            <li>
              Comunicarea cu dvs., direct sau prin intermediul unuia dintre partenerii noștri, inclusiv pentru servicii
              pentru clienți, pentru a vă oferi actualizări și alte informații referitoare la site-ul web și în scopuri
              de marketing și de promovare
            </li>
            <li>Trimiterea de email-uri</li>
            <li>Găsirea și prevenirea fraudei</li>
          </ul>
          <h3>Fișiere jurnal</h3>
          <p>
          MeniuDigital urmează o procedură standard de utilizare a fișierelor jurnal. Aceste fișiere înregistrează
            vizitatori atunci când accesează site-uri web. Toate companiile de găzduire fac acest lucru și o parte din
            analiza serviciilor de găzduire. Informațiile colectate de fișierele jurnal includ adrese de protocol de
            internet (IP), tip de browser, furnizor de servicii Internet (ISP), ștampilă de dată și oră, pagini de
            trimitere / ieșire și, eventual, numărul de clicuri. Acestea nu sunt legate de nicio informație care poate
            fi identificată personal. Scopul informațiilor este de a analiza tendințele, de a administra site-ul, de a
            urmări mișcarea utilizatorilor de pe site și de a colecta informații demografice.
          </p>
          <h3>Cookie-uri și semnalizatoare web</h3>
          <p>
            Ca orice alt site web, MeniuDigital folosește „cookie-uri”. Aceste cookie-uri sunt utilizate pentru a
            stoca informații, inclusiv preferințele vizitatorilor, și paginile de pe site-ul web pe care vizitatorul
            le-a accesat sau le-a vizitat. Informațiile sunt utilizate pentru a optimiza experiența utilizatorilor prin
            personalizarea conținutului paginii noastre web pe baza tipului de browser al vizitatorilor și / sau a altor
            informații.
          </p>
          <p>
            Pentru informații mai generale despre cookie-uri, vă rugăm să citiți{' '}
            <a href="https://www.cookieconsent.com/what-are-cookies/">"Ce Sunt Cookie-urile?"</a>.
          </p>
          <h3>Politici de confidențialitate pentru partenerii de publicitate</h3>
          <p>
            Puteți consulta această listă pentru a găsi Politica de confidențialitate pentru fiecare dintre partenerii
            de publicitate ai MeniuDigital.
          </p>
          <p>
            Serverele de publicitate terțe sau rețelele publicitare utilizează tehnologii precum cookie-uri, JavaScript
            sau Web Beacon-uri care sunt utilizate în reclamele și linkurile respective care apar pe MeniuDigital,
            care sunt trimise direct în browserul utilizatorilor. Ei primesc automat adresa dvs. IP atunci când se
            întâmplă acest lucru. Aceste tehnologii sunt utilizate pentru a măsura eficiența campaniilor lor publicitare
            și / sau pentru a personaliza conținutul publicitar pe care îl vedeți pe site-urile web pe care le vizitați.
          </p>
          <p>
            Rețineți că MeniuDigital nu are acces sau control asupra acestor cookie-uri care sunt utilizate de
            agenții de publicitate terți.
          </p>
          <h3>Politici de confidențialitate ale terților</h3>
          <p>
            Politica de confidențialitate a MeniuDigital nu se aplică altor agenți de publicitate sau site-uri web.
            Astfel, vă sfătuim să consultați Politicile de confidențialitate respective ale acestor servere de anunțuri
            terțe pentru informații mai detaliate. Poate include practicile și instrucțiunile lor despre cum să renunțe
            la anumite opțiuni.
          </p>
          <p>
            Puteți alege să dezactivați cookie-urile prin opțiunile individuale ale browserului. Pentru a afla
            informații mai detaliate despre gestionarea cookie-urilor cu anumite browsere web, acestea pot fi găsite pe
            site-urile web respective ale browserelor.
          </p>
          <h3>Drepturile de protecție a datelor GDPR</h3>
          <p>
            Dorim să ne asigurăm că sunteți pe deplin conștienți de toate drepturile dvs. de protecție a datelor.
            Fiecare utilizator are dreptul la următoarele:
          </p>
          <p>
            Dreptul de acces - Aveți dreptul de a solicita copii ale datelor dvs. personale. Este posibil să vă taxăm o
            mică taxă pentru acest serviciu.
          </p>
          <p>
            Dreptul la rectificare - Aveți dreptul să solicitați să corectăm orice informație pe care o considerați
            inexactă. De asemenea, aveți dreptul să solicitați completarea informațiilor pe care le considerați
            incomplete.
          </p>
          <p>
            Dreptul la ștergere - Aveți dreptul să solicitați ștergerea datelor dvs. personale, în anumite condiții.
          </p>
          <p>
            Dreptul de a restricționa prelucrarea - Aveți dreptul să solicitați restricționarea prelucrării datelor dvs.
            personale, în anumite condiții.
          </p>
          <p>
            Dreptul de a vă opune prelucrării - Aveți dreptul de a vă opune prelucrării datelor dumneavoastră cu
            caracter personal, în anumite condiții.
          </p>
          <p>
            Dreptul la portabilitatea datelor - Aveți dreptul să solicitați transferul datelor pe care le-am colectat
            către o altă organizație sau direct către dvs., în anumite condiții.
          </p>
          <p>
            Dacă faceți o solicitare, avem o lună pentru a vă răspunde. Dacă doriți să vă exercitați oricare dintre
            aceste drepturi, vă rugăm să ne contactați.
          </p>
          <h3>Informațiile minorilor</h3>
          <p>
            O altă parte a priorității noastre este adăugarea de protecție pentru copii în timpul utilizării
            internetului. Îi încurajăm pe părinți și tutori să observe, să participe și / sau să monitorizeze și să
            îndrume activitatea lor online.
          </p>
          <p>
            MeniuDigital nu colectează cu bună știință nicio informație personală de identificare de la copiii cu
            vârsta sub 13 ani. Dacă credeți că copilul dumneavoastră a furnizat acest tip de informații pe site-ul
            nostru, vă încurajăm cu tărie să ne contactați imediat și vom depune toate eforturile pentru eliminați
            astfel de informații din înregistrările noastre.
          </p>
        </div>
      </Wrapper>
    )
  }
}
