import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPlus, faWifi } from '@fortawesome/free-solid-svg-icons'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { Screen } from 'src/screens'
import Button from 'src/global/Button'
import { getBusinessOrders, confirmDeliveryFor, confirmBankPaymentFor, cancelBusinessOrder } from 'src/state/business-orders'
import {
  HolderCode,
  BusinessOrder,
  BusinessOrderStatus,
  BusinessOrderPaymentType,
  ProductType,
  SubscriptionCode,
  User,
} from '@meniudigital/shared'
import { Button as ButtonStyle, SearchInput } from '../../HomeScreen/styles'
import OrderSummary from '../ChooseHolders/BusinessOrderSummary'
import moment from 'moment'
import AggregatedBusinessStats from './AggregatedBusinessStats'
import BusinessPurchasesGraphDaily from './BusinessPurchasesGraphDaily'

const PaymentTypeLabels = {
  OnlineCard: 'Online, cu cardul',
  BankTransfer: 'Transfer bancar',
}

export enum CourierCompany {
  Cargus = 'cargus',
  FanCourier = 'fan_courier',
  Sameday = 'sameday',
  Dpd = 'dpd',
  NemoExpress = 'nemo_express',
  Gls = 'gls',
}
const CourierLabels = {
  [CourierCompany.Cargus]: 'Cargus',
  [CourierCompany.FanCourier]: 'FAN Courier',
  [CourierCompany.Sameday]: 'Sameday',
  [CourierCompany.Dpd]: 'DPD',
  [CourierCompany.NemoExpress]: 'Nemo Express',
  [CourierCompany.Gls]: 'GLS',
}

const OrderStatusLabels = {
  [BusinessOrderStatus.Activated]: 'Plătită',
  [BusinessOrderStatus.CurrentlyFulfilling]: 'Se înregistrează',
  [BusinessOrderStatus.Failed]: 'Eșuată',
  [BusinessOrderStatus.Initiated]: 'Inițiată',
  [BusinessOrderStatus.WaitingForOnlinePayment]: 'Se așteaptă plata online',
  [BusinessOrderStatus.WaitingForBankPayment]: 'Se așteaptă transferul bancar',
  [BusinessOrderStatus.Paid]: 'Plătită',
  [BusinessOrderStatus.PendingActivation]: 'Se așteaptă activarea',
}

export default function OrderedHolders() {
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()

  const { list: orders } = useSelector((state: State) => state.businessOrders)
  const { user: { isSuperAdmin } = {} } = useSelector((state: State) => state.users)

  useEffect(() => {
    dispatch(getBusinessOrders())
  }, [])

  const startOrder = () => {
    return navigate(Screen.ChooseHolders)
  }

  const confirmBankPayment = async (order: BusinessOrder & { user: User }) => {
    await window.showModal({
      title: `Anulează comanda`,
      text: `Ești sigur că dorești să confirmi primirea plății prin transfer bancar 
      pentru comanda ${order.orderNumber} în valoare de ${order.requestedAmount} RON 
      pentru clientul ${order.user.name}? \n\nConfirmarea se va trimite la adresa ${order.user?.email}.`,
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    })

    await dispatch(confirmBankPaymentFor(order.id))
    await dispatch(getBusinessOrders())
    setTimeout(() => {
      alert('Confirmarea plății s-a făcut cu succes!')
    }, 300)
  }

  const confirmDelivery = async (order: BusinessOrder & { user: User }) => {
    await window.showModal({
      title: `Confirmă livrarea`,
      textComponent: (
        <span>
          Ești sigur că dorești să confirmi livrarea produselor din comanda {order.orderNumber} către {order.user?.name}? <br />
          <br />
          Produse: <br />
          {order.products
            .filter(x => x.type === ProductType.Holder)
            .map(x => x.code + ' x' + x.quantity)
            .join(', ')}
          <br />
          <br /> Confirmarea se va face către adresa: {order.user?.email}
        </span>
      ),
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    })

    const awb = window.prompt('Introdu AWB: ')

    if (!awb) {
      return
    }
    await dispatch(confirmDeliveryFor({ orderId: order.id, awb }))
    await dispatch(getBusinessOrders())
    setTimeout(() => {
      alert('Confirmarea livrării s-a făcut cu succes!')
    }, 300)
  }

  const promptCancelOrder = async (order: BusinessOrder) => {
    await window.showModal({
      title: `Anulează comanda`,
      text: `Ești sigur că dorești să anulezi comanda cu numărul ${order.orderNumber}?`,
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    })
    await dispatch(cancelBusinessOrder(order.id))
    alert('Comanda a fost anulată cu succes!')
    await dispatch(getBusinessOrders())
  }

  const downloadProformaFor = (order: BusinessOrder) => {
    downloadAndOpenPdf(`${process.env.REACT_APP_SERVER_URL}/api/holder-orders/${order.id}/proforma`)
  }

  const downloadInvoiceFor = (order: BusinessOrder) => {
    downloadAndOpenPdf(`${process.env.REACT_APP_SERVER_URL}/api/holder-orders/${order.id}/invoice`)
  }

  const downloadAndOpenPdf = (url: string) => {
    var postData = new FormData()
    var windowReference = window.open()

    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.token}`)
    xhr.responseType = 'blob'
    xhr.onload = function (e: any) {
      var blob = e.currentTarget.response
      var generatedUrl = window.URL.createObjectURL(blob)
      windowReference!.location = generatedUrl
      windowReference!.focus()
    }
    xhr.send(postData)
  }

  const filteredOrders = orders
    .filter(
      x =>
        (x.orderNumber || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.username || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.name || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.city || '').toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => (+moment(b.createdAt) > +moment(a.createdAt!) ? 1 : -1))
    .slice(0, 50)

  return (
    <>
      <Wrapper>
        {isSuperAdmin && (
          <>
            <AggregatedBusinessStats />
            <div className="chart-wrapper">
              <BusinessPurchasesGraphDaily />
            </div>
          </>
        )}

        {orders.length ? (
          <div className="top-bar">
            <SearchInput
              placeholder="Caută comandă..."
              value={query}
              onChange={e => {
                setQuery(e.target.value)
              }}
            />
            {!isSuperAdmin && (
              <ButtonStyle
                style={{
                  height: '43px',
                  padding: '16px',
                  paddingTop: '15px',
                  backgroundColor: 'transparent',
                }}
                className="green"
                title="Comandă nouă"
                onClick={startOrder}
              >
                <FontAwesomeIcon icon={faPlus} />
                Comandă nouă
              </ButtonStyle>
            )}
          </div>
        ) : (
          <div className="panel info" style={{ padding: '40px 16px' }}>
            <div className="background-waves">
              <img src="/misc/waves.png" />
            </div>
            <p>Momentan nu ai nicio comandă.</p>
            <p>Poți începe prima comandă făcând click aici: </p>
            <Button text="Comandă" onClick={() => navigate(Screen.ChooseHolders)} />
          </div>
        )}

        {filteredOrders.map(order => (
          <div className="panel order" style={{ maxWidth: isSuperAdmin ? '' : '1400px' }} key={order.id}>
            <div className="field">
              <label>Număr comandă</label>
              <span>
                <b>{order.orderNumber}</b>
              </span>

              <label>ID</label>
              <span style={{ wordBreak: 'break-all' }}>{order.id}</span>

              <label>Dată și oră</label>
              <span>{moment((order as any).createdAt).format('DD MMM @ HH:mm')}</span>
            </div>
            {isSuperAdmin && (
              <div className="field active-bg" style={{ flex: 1.3 }}>
                <label>Client</label>
                <div className="client-info">
                  <img loading="lazy" src={order.user.logoUrl} alt="User logo" />
                  <span className="bolder">{order.user.name}</span>
                  <span>
                    <FontAwesomeIcon style={{ marginRight: '8px', color: '#11b7f3' }} icon={faMapMarkerAlt} />
                    {order.user.city}
                  </span>
                  <span>/{order.user.username}</span>
                  <span>{order.user.email}</span>
                </div>
              </div>
            )}

            <div className="field active-bg">
              <label>Produse</label>
              <OrderSummary
                compact
                order={{
                  laminatedHolderCount: order.products.find(x => x.code === HolderCode.Laminated)?.quantity || 0,
                  plexiglassHolderCount: order.products.find(x => x.code === HolderCode.Plexiglass)?.quantity || 0,
                  stickerHolderCount: order.products.find(x => x.code === HolderCode.Sticker)?.quantity || 0,
                  subscriptionCode: order.products.find(x => x.type === ProductType.Subscription)?.code as SubscriptionCode,
                }}
              />

              {order.withWifiInfo && (
                <span className="with-wifi">
                  <FontAwesomeIcon icon={faWifi} className="icon" />
                  Informațiile WiFi vor fi afișate pe holdere.
                </span>
              )}

              {order.proformaInvoiceId && (
                <Button variant="as-text" text="🧾 Vezi factura proformă" onClick={() => downloadProformaFor(order)} />
              )}
              {order.finalInvoiceId && (
                <Button variant="as-text" text="🧾 Vezi factura fiscală" onClick={() => downloadInvoiceFor(order)} />
              )}

              {(order.awb || '').length > 0 && (
                <Button
                  variant="as-text"
                  text={`🚚 Urmărește livrarea (${order.courierCompany ? CourierLabels[order.courierCompany] : ''})`}
                  onClick={() => window.open(`https://www.cargus.ro/en/tracking/?t=${order.awb}`)}
                />
              )}

              {!isSuperAdmin && (
                <>
                  {(order.status === BusinessOrderStatus.WaitingForBankPayment ||
                    order.status === BusinessOrderStatus.WaitingForOnlinePayment) && (
                    <Button variant="as-text-danger" text="❌ Anulează comanda" onClick={() => promptCancelOrder(order)} />
                  )}
                </>
              )}

              <span
                className="order-status"
                style={{
                  background: computeBackgroundColor(
                    order.status,
                    order.products.some(x => x.type === ProductType.Holder),
                    (order.awb || '').length > 0
                  ),
                }}
              >
                {(OrderStatusLabels[order.status] || order.status).toUpperCase()}{' '}
                {order.products.some(x => x.type === ProductType.Holder)
                  ? (order.awb || '').length > 0
                    ? ' & LIVRATĂ'
                    : ' & NELIVRATĂ'
                  : ''}
              </span>
            </div>

            <div className="field shipping-and-billing" style={{ flex: 1.5 }}>
              <label>Tip plată</label>
              <span>
                {order.paymentType === BusinessOrderPaymentType.BankTransfer ? '🏦' : '💳'} {PaymentTypeLabels[order.paymentType]}
              </span>

              {order.localError && (
                <>
                  <label className="red">Eroare</label>
                  <code className="red">{order.localError}</code>
                </>
              )}

              {Boolean(isSuperAdmin && order.mobilPayResponse) && (
                <>
                  <label>Răspuns MobilPay</label>
                  <code>{order.mobilPayResponse}</code>
                </>
              )}

              <label>Facturare</label>
              {Object.keys(order.billingInfo || {})
                .filter(x => (order.billingInfo as any)[x])
                .map(x => (
                  <span key={x}>
                    {/* <span style={{ fontSize: '12px' }}>{x}: </span> */}
                    <code>{(order.billingInfo as any)[x]}</code>
                  </span>
                ))}
            </div>
            {Object.keys(order.shippingInfo || {}).length > 0 && (
              <div className="field shipping-and-billing" style={{ flex: 2 }}>
                <label>Livrare</label>

                {Object.keys(order.shippingInfo || {}).map(x => (
                  <div key={x}>
                    <span style={{ fontSize: '12px' }}>{x}: </span>
                    <code>{(order.shippingInfo as any)[x]}</code>
                  </div>
                ))}
                <InfoLine label="Comentarii" value={order.extraCommentsFromUser} />
              </div>
            )}
            {isSuperAdmin && (
              <div className="field active-bg" style={{ paddingTop: 0 }}>
                {order.status === BusinessOrderStatus.WaitingForBankPayment && (
                  <Button
                    onClick={() => confirmBankPayment(order)}
                    style={{ paddingBottom: '3px' }}
                    text="💸 Confirmă încasare plată"
                  />
                )}
                {order.products.some(x => x.type === ProductType.Holder) &&
                  !order.awb &&
                  order.status !== BusinessOrderStatus.Failed && (
                    <Button
                      style={{ paddingBottom: '3px' }}
                      onClick={() => confirmDelivery(order)}
                      text="🚚 Confirmă livrare produse"
                    />
                  )}
                {order.proformaInvoiceId && (
                  <Button variant="as-text" text="🧾 Vezi factura proformă" onClick={() => downloadProformaFor(order)} />
                )}

                <Button
                  variant="as-text"
                  text="📱 Vezi meniu"
                  onClick={() => window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${order.user.username}`)}
                />
                {order.products.some(x => x.code === HolderCode.Laminated) && (
                  <Button
                    variant="as-text"
                    text="📄 Vezi Holder - Card laminat A6"
                    onClick={() => window.open(`${process.env.REACT_APP_SERVER_URL}/${order.user.username}/holder-cards`)}
                  />
                )}
                {order.products.some(x => x.code === HolderCode.Plexiglass) && (
                  <>
                    {order.withWifiInfo ? (
                      <Button
                        variant="as-text"
                        text="🪟 Vezi Holder - Plexiglas cu Wifi"
                        onClick={() =>
                          window.open(`${process.env.REACT_APP_SERVER_URL}/${order.user.username}/holder-plexi-wifi`)
                        }
                      />
                    ) : (
                      <Button
                        variant="as-text"
                        text="🪟 Vezi Holder - Plexiglas"
                        onClick={() => window.open(`${process.env.REACT_APP_SERVER_URL}/${order.user.username}/holder-plexi`)}
                      />
                    )}
                  </>
                )}
                {order.products.some(x => x.code === HolderCode.Sticker) && (
                  <Button
                    variant="as-text"
                    text="🏷️ Vezi Holder - Stickere"
                    onClick={() => window.open(`${process.env.REACT_APP_SERVER_URL}/${order.user.username}/holder-stickers`)}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </Wrapper>
    </>
  )
}

type FieldProps = {
  label: string
  value?: string | number
}

function InfoLine({ label, value }: FieldProps) {
  if (!value) {
    return null
  }

  return (
    <>
      <label>{label}</label>
      <span>{value}</span>
    </>
  )
}

function computeBackgroundColor(orderStatus: BusinessOrderStatus, needsDelivery: boolean, isDelivered: boolean) {
  const colors: { [key in BusinessOrderStatus]: string } = {
    [BusinessOrderStatus.Initiated]: '#800000',
    [BusinessOrderStatus.Paid]: '#c66600',
    [BusinessOrderStatus.WaitingForBankPayment]: '#9fc100',
    [BusinessOrderStatus.WaitingForOnlinePayment]: '#7a9401',
    [BusinessOrderStatus.CurrentlyFulfilling]: '#08e9a3',
    [BusinessOrderStatus.PendingActivation]: '#01abaa',
    [BusinessOrderStatus.Activated]: '#03a9f4',
    [BusinessOrderStatus.Failed]: '#f44336',
  }

  const statusColor = colors[orderStatus] as string

  if (needsDelivery) {
    if (isDelivered) {
      return `linear-gradient(to right, ${statusColor}, ${statusColor}, #00ffd6)`
    } else {
      return `linear-gradient(to right, ${statusColor}, ${statusColor}, orange)`
    }
  }

  return statusColor
}
