import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .page {
    animation: none;
    transform: unset;
  }

  form {
    button {
      align-self: center;
      margin-top: 24px !important;
    }
  }

  .bottom-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    @media (min-width: 768px) {
      margin-bottom: -24px;
    }

    .small-separator {
      border-right: 1px solid ${props => props.theme.accent};
      height: 16px;
      margin: 0 16px;
    }

    button {
      margin: 0;
      margin-top: 0 !important;
      padding: 0;
      flex: 1;
    }
  }

  .centered {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .or-line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 16px;

    .line {
      width: 50px;
      margin: 0 8px;
      border-top: 1px solid ${props => props.theme.border};
    }
  }
`
