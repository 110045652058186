import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .title {
    margin-bottom: 40px;
    margin-top: 16px;
  }

  p {
    line-height: 26px;
    padding: 0 50px !important;
  }

  .error {
    color: ${props => props.theme.red}
  }
`
