import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { productEndpoints } from 'src/api'
import { MenuProduct } from '@meniudigital/shared'

export type State = {
  list: MenuProduct[]
  isLoading: boolean
}

export const initialState: State = {
  list: [],
  isLoading: false,
}

export const createProduct = createAsyncThunk('products/createProduct', productEndpoints.create)
export const editProduct = createAsyncThunk('products/editProduct', productEndpoints.edit)
export const getProducts = createAsyncThunk('products/getProducts', productEndpoints.getAll)
export const moveProduct = createAsyncThunk('products/moveProduct', productEndpoints.move)
export const transferProduct = createAsyncThunk('products/transferProduct', productEndpoints.transfer)
export const removeProduct = createAsyncThunk('products/removeProduct', productEndpoints.remove)
export const patchProduct = createAsyncThunk('products/patchProduct', productEndpoints.patch)

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: actions => {
    // PENDINGS & REJECTEDS
    ;[createProduct, editProduct, getProducts, removeProduct].forEach(reducerAction => {
      actions.addCase(reducerAction.pending, (state: State) => {
        state.isLoading = true
      })
      actions.addCase(reducerAction.rejected, (state: State) => {
        state.isLoading = false
      })
    })

    actions.addCase(moveProduct.pending, (state, action: any) => {
      const { productId, destinationIndex } = action.meta.arg

      const productCategoryId = state.list.find(x => x.id === productId)!.categoryId!
      const productsInCategory = [...state.list].filter(x => x.categoryId === productCategoryId)

      const draggedItem = productsInCategory.find(x => x.id === productId)
      const listWithoutItem = productsInCategory.filter(x => x.id !== productId).sort((a, b) => a.index - b.index)

      const newListInCategory = [
        ...listWithoutItem.slice(0, destinationIndex - 1),
        draggedItem,
        ...listWithoutItem.slice(destinationIndex - 1),
      ]

      newListInCategory.forEach((x, i) => {
        x!.index = i + 1
      })

      const updatedFullList = [...state.list].map(product => {
        const newProduct = newListInCategory.find(x => x?.id === product.id)
        if (newProduct) {
          return {
            ...product,
            index: newProduct.index,
          }
        } else {
          return { ...product }
        }
      })

      state.list = updatedFullList
    })
    actions.addCase(transferProduct.pending, (state, action: any) => {
      const { productId, destinationCategoryId, destinationIndex } = action.meta.arg

      setTimeout(() => {
        window.openCategory[destinationCategoryId]()
      }, 300)

      const transferredProduct = state.list.find(x => x.id === productId)!

      transferredProduct.categoryId = destinationCategoryId
      transferredProduct.index = destinationIndex

      const productsInCategory = [...state.list].filter(x => x.categoryId === destinationCategoryId)

      const listWithoutItem = productsInCategory.filter(x => x.id !== productId).sort((a, b) => a.index - b.index)

      const newListInCategory = [
        ...listWithoutItem.slice(0, destinationIndex - 1),
        transferredProduct,
        ...listWithoutItem.slice(destinationIndex - 1),
      ]

      newListInCategory.forEach((x, i) => {
        x.index = i + 1
      })

      const updatedFullList = [...state.list].map(product => {
        const newProduct = newListInCategory.find(x => x?.id === product.id)
        if (newProduct) {
          return {
            ...product,
            index: newProduct.index,
          }
        } else {
          return { ...product }
        }
      })

      state.list = updatedFullList
    })
    actions.addCase(createProduct.fulfilled, (state: State, { payload }: PayloadAction<MenuProduct>) => {
      setTimeout(() => {
        window.openCategory[payload.categoryId]()
      }, 300)
      state.isLoading = false
      state.list = [...state.list, payload]
    })
    actions.addCase(editProduct.fulfilled, (state, { payload }: PayloadAction<MenuProduct>) => {
      setTimeout(() => {
        window.openCategory[payload.categoryId]()
      }, 300)
      state.isLoading = false
      state.list = [...state.list.filter(x => x.id !== payload.id), payload]
    })
    actions.addCase(getProducts.fulfilled, (state, { payload }: PayloadAction<MenuProduct[]>) => {
      state.isLoading = false
      state.list = payload
    })
    actions.addCase(moveProduct.fulfilled, (state, { payload }: PayloadAction<MenuProduct[]>) => {
      state.isLoading = false
      const newProductsFromCategory = payload
      const updatedList = [...state.list].map(product => {
        const newProduct = newProductsFromCategory.find(x => x.id === product.id)
        if (newProduct) {
          return {
            ...product,
            index: newProduct.index,
          }
        } else {
          return { ...product }
        }
      })

      state.list = updatedList
    })
    actions.addCase(transferProduct.fulfilled, (state, { payload }: PayloadAction<MenuProduct[]>) => {
      state.isLoading = false
      const newProductsFromCategory = payload
      const updatedList = [...state.list].map(product => {
        const newProduct = newProductsFromCategory.find(x => x.id === product.id)
        if (newProduct) {
          return {
            ...product,
            index: newProduct.index,
          }
        } else {
          return { ...product }
        }
      })

      state.list = updatedList
    })
    actions.addCase(removeProduct.fulfilled, (state, { payload }: PayloadAction<MenuProduct>) => {
      setTimeout(() => {
        window.openCategory[payload.categoryId]()
      }, 300)
      state.isLoading = false
      state.list = [...state.list.filter(x => x.id !== payload.id)]
    })
  },
})

export default slice.reducer
