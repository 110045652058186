import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { categoriesSelector, productsSelector, usersSelector } from 'src/state/selectors'
import { faBolt, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isBusiness } from '@meniudigital/shared'

const iframeDomain = process.env.REACT_APP_MENU_BASE_URL

type Props = {
  showsBanner: boolean
}

export enum PickedImageType {
  Logo = 'logo',
  CoverPhoto = 'coverPhoto',
}

export default function MenuPreview({ showsBanner }: Props) {
  const [iframeVersion, setIframeVersion] = useState(1)
  const { list: products } = useSelector(productsSelector)
  const { list: categories } = useSelector(categoriesSelector)
  const { user } = useSelector(usersSelector)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const { username } = user!

  useEffect(() => {
    if (products.length < 5 && categories.length < 5) {
      refreshIframe()
    }
  }, [products, categories, user])

  const refreshIframe = () => {
    setIframeVersion(+new Date())
  }

  return (
    <Wrapper style={{ top: showsBanner ? '118px' : '82px' }}>
      <div className={'preview'}>
        <div className="iframe-wrapper">
          <img className="notch-area" src="/notch_area.jpeg" alt="iPhone 13 notch area" />
          <iframe
            ref={iframeRef}
            seamless
            src={`${iframeDomain}/${username}?v=${iframeVersion}&fromIframe=true`}
            title="web-menu"
          />
        </div>
        <img className={'iphone-outline'} src="/iphone-outline-flat.png" alt="iPhone 13 oultine" />
      </div>
      {!(products.length < 5 && categories.length < 5) && (
        <div className="refresh-button" title="Reîncarcă" onClick={refreshIframe}>
          <FontAwesomeIcon icon={faRefresh} />
        </div>
      )}

      <div
        className={`priority-indicator ${isBusiness(user) ? 'active' : ''}`}
        title="Trafic prioritar"
        onClick={() => window.openBusinessModal?.('priorityTraffic')}
      >
        <FontAwesomeIcon icon={faBolt} />
        <div className="explainer">
          <span>Meniul tău {isBusiness(user) ? '' : 'nu '}beneficiază de trafic prioritar la orele de vârf.</span>
        </div>
      </div>
    </Wrapper>
  )
}
