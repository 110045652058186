import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from './styles'
import Lottie from 'lottie-react'
import * as emailAnimation from 'src/assets/animations/email-tick.json'
import { Screen } from 'src/screens'
import useForm from 'src/helpers/useForm'
import Button from 'src/global/Button'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { userEndpoints } from 'src/api'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const { formValues, register } = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const submit = async () => {
    const { email } = formValues

    if ((email.value || '').length < 2 || !validateEmail(email.value)) {
      alert('Introdu o adresă de e-mail validă.')

      return
    }

    setIsSubmitting(true)

    await userEndpoints.sendResetPasswordEmail(email.value)

    setHasSubmitted(true)
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      submit()
    }
  }

  return (
    <Wrapper onKeyDown={onKeyDown}>
      <div className="page">
        {!hasSubmitted && (
          <>
            <h3>Resetează parola</h3>
            <form>
              <label>E-mail</label>
              <input type="text" maxLength={100} placeholder="Email-ul tău" {...register('email')} />

              <Button
                text="Continuă"
                isLoading={isSubmitting}
                disabled={isSubmitting || (formValues.email?.value || '').length < 2 || !validateEmail(formValues.email?.value)}
                icon={faArrowRight}
                style={{ width: '170px' }}
                onClick={submit}
              />
            </form>
          </>
        )}

        {hasSubmitted && (
          <div className="panel">
            <div className="icon">
              <Lottie
                {...{
                  loop: true,
                  autoplay: true,
                  animationData: emailAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={100}
                width={100}
              />
            </div>
            <h3>Cerere înregistrată!</h3>
            <span>Dacă există un cont cu această adresă de e-mail vei primi un mesaj cu linkul de resetare.</span>
            <Button text="Continuă" onClick={() => navigate(Screen.Login)} />
          </div>
        )}
      </div>
    </Wrapper>
  )
}
