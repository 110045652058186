import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { statEndpoints } from 'src/api'
import { StatDailyBusinessPurchase } from 'src/state/users'

const BusinessPurchasesGraph = () => {
  const [graphData, setGraphData] = useState<StatDailyBusinessPurchase[]>()

  useEffect(() => {
    const allDates = getLast90DaysDates()
    statEndpoints.getBusinessPurchasesGraphDaily().then(data => {
      const dataMap = new Map(data.map(item => [item.date, item]))
      const mergedData = allDates.map(date => {
        return (
          dataMap.get(date) || {
            date,
            new6: 0,
            new12: 0,
            renew6: 0,
            renew12: 0,
          }
        )
      })
      setGraphData(mergedData)
    })
  }, [])

  if (!graphData) {
    return null
  }

  return (
    <Bar
      width={Math.max(window.innerWidth - 120, 370)}
      height={250}
      options={{
        responsive: false,
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'start',
          },
          title: {
            position: 'top',
            align: 'start',
            display: true,
            text: `New subscriptions and renewals - daily - last 90 days`,
          },
        },
      }}
      data={{
        labels: graphData.map(item => item.date),
        datasets: [
          {
            label: 'New Business 6 months',
            data: graphData.map(item => item.new6 * 6),
            backgroundColor:  '#32bd98',
            // @ts-ignore
            borderDash: [5, 5], // Dashed line for Business 6,
            stack: 'stack0',
          },
          {
            label: 'Renew Business 6 months',
            data: graphData.map(item => item.renew6 * 6),
            backgroundColor: createDiagonalPattern('#32bd98'),
            // @ts-ignore
            borderDash: [5, 5], // Dashed line for Business 6
            stack: 'stack0',
          },
          {
            label: 'New Business 12 months',
            data: graphData.map(item => item.new12 * 12),
            backgroundColor: '#11b7f3',
            stack: 'stack0',
          },
          {
            label: 'Renew Business 12 months',
            data: graphData.map(item => item.renew12 * 12),
            backgroundColor: createDiagonalPattern('#11b7f3'),
            stack: 'stack0',
          },
        ],
      }}
    />
  )
}

const getLast90DaysDates = () => {
  const dates = []
  let currentDate = moment().subtract(90, 'days')

  for (let i = 0; i <= 90; i++) {
    dates.push(currentDate.format('DD/MM'))
    currentDate.add(1, 'day')
  }

  return dates
}

function createDiagonalPattern(color = 'black') {
  let shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  let c = shape.getContext('2d')!

  c.strokeStyle = color
  c.lineWidth = 5 // Set the line width to 2 pixels or any other value for thickness

  c.beginPath()
  c.moveTo(2, 0)
  c.lineTo(10, 8)
  c.stroke()

  c.beginPath()
  c.moveTo(0, 8)
  c.lineTo(2, 10)
  c.stroke()

  return c.createPattern(shape, 'repeat')!
}

export default BusinessPurchasesGraph
