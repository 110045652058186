import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import { Wrapper } from './styles'
import { Panel } from '../../HomeScreen/styles'
import { HolderCode, BusinessOrder } from '@meniudigital/shared'
import Button from 'src/global/Button'
import { businessOrderEndpoints } from 'src/api'
import { faArrowRotateForward, faHomeLg } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { Screen } from 'src/screens'
import * as errorAnimation from 'src/assets/animations/error.json'
import OrderSummary from '../ChooseHolders/BusinessOrderSummary'
import { useSelector } from 'react-redux'
import { State } from 'src/state'
import LoadingProgressBar from 'src/global/LoadingProgressBar'

export default function FailedOrder() {
  const navigate = useNavigate()
  const { user } = useSelector((state: State) => state.users)
  const [order, setOrder] = useState<BusinessOrder | null>(null)
  const { id: orderId } = useParams()
  const { orderIntent } = useSelector((state: State) => state.businessOrders)

  useEffect(() => {
    getOrder()
  }, [])

  const getOrder = async () => {
    setOrder(await businessOrderEndpoints.getById(orderId!))
  }

  const resumeOrder = () => {
    if (orderIntent?.billingInfo) {
      return navigate(Screen.FinalizeOrder)
    }

    if (orderIntent?.shippingInfo) {
      return navigate(Screen.EnterBillingInfo)
    }

    return navigate(Screen.ChooseHolders)
  }

  if (!order || !user) {
    return <LoadingProgressBar />
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Wrapper>
      <h2 className="title">Plată eșuată / abandonată</h2>

      <Panel className="centered">
        <Lottie {...lottieOptions} style={{ width: 200 }} />
        <p className="error">
          Plata pentru comanda ta cu numărul <b>{order.orderNumber}</b> a eșuat. <br />
          Status comandă: "{order.status}"
        </p>
        <p>Poți încerca o modalitate de plată diferită sau poți re-încerca din nou mai târziu.</p>

        <OrderSummary
          order={{
            laminatedHolderCount: order.products.find(x => x.code === HolderCode.Laminated)?.quantity || 0,
            plexiglassHolderCount: order.products.find(x => x.code === HolderCode.Plexiglass)?.quantity || 0,
            stickerHolderCount: order.products.find(x => x.code === HolderCode.Sticker)?.quantity || 0,
          }}
        />
        <Button text="Mergi la ecranul principal" icon={faHomeLg} onClick={() => navigate(Screen.Home)} />
        {orderIntent && <Button text="Reîncearcă" icon={faArrowRotateForward} onClick={resumeOrder} />}
      </Panel>
    </Wrapper>
  )
}
