import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Flag from 'src/global/Flag'
import { State } from 'src/state'
import { categoriesSelector, productsSelector } from 'src/state/selectors'
import { LanguageCode } from '@meniudigital/shared'
import { Wrapper } from './styles'

type Props = {
  onLanguageCodeChange: (newLanguageCode: LanguageCode) => void
}
export default function LanguageToggle(props: Props) {
  const [languageCode, setLanguageCode] = useState<LanguageCode>(LanguageCode.Romanian)

  const { user } = useSelector((state: State) => state.users)
  const { list: products } = useSelector(productsSelector)
  const { list: categories } = useSelector(categoriesSelector)

  const isReady = (targetLanguageCode: LanguageCode) => {
    const areProductsReady = !products.some(x => !x.names[targetLanguageCode])
    const areCategoriesReady = !categories.some(x => !x.names[targetLanguageCode])

    return areProductsReady && areCategoriesReady
  }

  const userLanguages = user?.menuLanguages?.length ? user.menuLanguages : [LanguageCode.English, LanguageCode.Romanian]
  const sortedMenuLanguages = Object.values(LanguageCode).filter(x =>
    userLanguages.includes(x as LanguageCode)
  ) as LanguageCode[]
  const displayedLanguages = sortedMenuLanguages.filter(isReady).reverse()

  const changeLanguageCode = (newValue: LanguageCode) => {
    if (languageCode === newValue && displayedLanguages.length === 2) {
      const otherLanguage = sortedMenuLanguages.find(x => x !== newValue)!
      setLanguageCode(otherLanguage)
      setTimeout(() => props.onLanguageCodeChange(otherLanguage), 0)
    } else {
      setLanguageCode(newValue)
      setTimeout(() => props.onLanguageCodeChange(newValue), 0)
    }
  }

  const activeIndex = sortedMenuLanguages.indexOf(languageCode)

  return (
    <Wrapper>
      <div className="rail" style={{ width: displayedLanguages.length * 50 }}>
        <div
          className="active-indicator"
          style={{
            width: 100 / displayedLanguages.length + '%',
            transform: `translateX(calc(-${100 * activeIndex + (activeIndex > 0 ? 3 : 0)}%))`,
          }}
        />

        {displayedLanguages.map(x => (
          <div key={x} className={'language-icon' + (languageCode === x ? ' active' : '')} onClick={() => changeLanguageCode(x)}>
            <Flag of={x} />
          </div>
        ))}
      </div>
    </Wrapper>
  )
}
