import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchInput from './SearchInput'
import Button from 'src/global/Button'
import { faBars, faCopy, faExternalLink, faGear, faPlus, faPrint, faShare, faSort } from '@fortawesome/free-solid-svg-icons'
import LanguageToggle from '../LanguageToggle'
import { LanguageCode } from '@meniudigital/shared'
import { Wrapper } from './styles'
import { usersSelector } from 'src/state/selectors'
import { useSelector } from 'react-redux'
import { isBusiness } from '@meniudigital/shared'

type Props = {
  onQueryChange: (text: string) => void
  onLanguageCodeChange: (languageCode: LanguageCode) => void
  onCategoryAddPress: () => void
  onReorderCategoriesPress: () => void
}

export default function MenuActionsRow({
  onQueryChange,
  onLanguageCodeChange,
  onCategoryAddPress,
  onReorderCategoriesPress,
}: Props) {
  const [showsMenu, setShowsMenu] = useState(false)
  const { user } = useSelector(usersSelector)
  const menuUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username}`
  const reservationsUrl = `${menuUrl}/rezervare`

  const toggleMenu = () => {
    if (!showsMenu) {
      showMenu()
    } else {
      setShowsMenu(false)
    }
  }

  const showMenu = async () => {
    setShowsMenu(true)
  }

  const showMenuIfDesktop = () => {
    if (!('ontouchstart' in document.documentElement)) {
      showMenu()
    }
  }

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${menuUrl}`
    window.open(facebookUrl, 'Facebook Share', 'width=600,height=400')
  }

  const copyLink = (linkType: string) => {
    if (linkType === 'reservations') {
      navigator.clipboard?.writeText(reservationsUrl)
      window.showConfirm({
        text: ` Linkul către pagina ta de rezervări a fost copiat în clipboard.\n\n ${reservationsUrl}`,
      })
    }

    if (linkType === 'menu') {
      navigator.clipboard?.writeText(menuUrl)
      window.showConfirm({ text: ` Linkul către meniul tău a fost copiat în clipboard.\n\n ${menuUrl}` })
    }
  }

  return (
    <Wrapper>
      <div className="left">
        <SearchInput onQueryChange={onQueryChange} />
        <Button responsive iconInline={faPlus} text="Adaugă categorie" variant="secondary" onClick={onCategoryAddPress} />
        <div
          className="button-menu-wrapper"
          onMouseOver={showMenuIfDesktop}
          onMouseLeave={() => setShowsMenu(false)}
          onClick={toggleMenu}
        >
          <Button circular iconInline={faBars} variant="secondary" style={{ marginLeft: '8px' }} />
          <div className={`dropdown-menu ${showsMenu ? 'open' : ''}`}>
            <div className="menu-item" onClick={() => window.openSettingsModal()}>
              <FontAwesomeIcon icon={faGear} />
              Setări meniu
            </div>
            <div className="menu-item" onClick={onReorderCategoriesPress}>
              <FontAwesomeIcon icon={faSort} />
              Reordonează categorii
            </div>
            <div className="separator"></div>
            <div className="menu-item" onClick={() => copyLink('menu')}>
              <FontAwesomeIcon icon={faCopy} />
              Copiază link meniu
            </div>
            <div
              className="menu-item"
              onClick={() => (isBusiness(user) ? copyLink('reservations') : window.openBusinessModal('reservations'))}
            >
              <FontAwesomeIcon icon={faCopy} />
              Copiază link rezervări
            </div>
            <div className="menu-item" onClick={() => window.open(menuUrl)}>
              <FontAwesomeIcon icon={faExternalLink} />
              Deschide în browser
            </div>
            <div className="menu-item" onClick={shareToFacebook}>
              <FontAwesomeIcon icon={faShare} />
              Share pe Facebook
            </div>
            <div className="menu-item" onClick={() => window.openPrintMenuModal()}>
              <FontAwesomeIcon icon={faPrint} />
              Printează meniul...
            </div>
            {/* <div className="menu-item">
              <FontAwesomeIcon icon={faFilePdf} />
              Exportă în PDF/Excel...
            </div> */}
          </div>
        </div>
      </div>

      <LanguageToggle onLanguageCodeChange={onLanguageCodeChange} />
    </Wrapper>
  )
}
