import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Wrapper } from './styles'

type Option = { value: string | boolean; label: string }

type Props = {
  initialValue?: string
  value?: string | boolean
  values: Option[]
  withIcon?: boolean
  onChange: (newValue: string | boolean) => void
}

export default function IosToggle(props: Props) {
  const { values, onChange } = props
  const [localValue, setLocalValue] = useState<string | boolean>(props.initialValue || props.value || props.values[0].value)

  const selectedValue = props.value || localValue

  const toggle = () => {
    const otherValue = values.find(x => x.value !== selectedValue)!
    setLocalValue(otherValue.value)
    onChange?.(otherValue.value)
    if (!props.value) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const activeIndex = values.findIndex(x => x.value === selectedValue)

  return (
    <Wrapper className="ios-toggle" onClick={toggle}>
      <div className="active-indicator" style={{ transform: `translateX(calc(${100 * activeIndex}%))` }} />

      {values.map(x => (
        <div key={x.value + ''} className={'option' + (selectedValue === x.value ? ' active' : '')}>
          {props.withIcon && <FontAwesomeIcon icon={faCheckCircle} />}
          {x.label}
        </div>
      ))}
    </Wrapper>
  )
}
