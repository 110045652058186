import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import Toggle from 'react-toggle'
import { formatAddress, UserPatchType } from '@meniudigital/shared'
import Button from 'src/global/Button'
import { GoogleMap, OverlayView } from '@react-google-maps/api'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { DEFAULT_LOGO_URL } from 'src/global/Header/TopRightDropdown'

export default function LocationSetting() {
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)
  const navigate = useNavigate()

  const goToLocationPicker = () => {
    window.hideSettingsModal()
    navigate(Screen.SetLocation)
  }

  if (!user) {
    return null
  }

  const { coords, logoUrl, name } = user

  const toggleCoords = () => {
    const payload = {
      type: UserPatchType.Location,
      data: { ...(coords || {}), isVisible: !coords?.isVisible },
    }
    dispatch(patchCurrentUser(payload as any))
  }

  const hasCoords = Boolean(coords?.latitude && coords?.longitude)

  return (
    <div className="setting grouped">
      <div className="left-side">
        <div className="setting-name">
          <div className="setting-icon">
            <img src="/logos/location.png" alt="Location icon" />
          </div>
          <span>
            Locația
            <span className="only-desktop"> Restaurantului</span>
          </span>
        </div>
        {hasCoords && coords?.isVisible && (
          <div style={{ marginTop: '16px', borderWidth: 10, borderColor: 'red' }}>
            <GoogleMap
              mapContainerClassName="map-container"
              center={{ lat: coords!.latitude, lng: coords!.longitude }}
              zoom={17}
              options={{
                gestureHandling: 'none',
                mapTypeControl: false,
                fullscreenControl: false,
              }}
            >
              <OverlayView
                position={{ lat: coords?.latitude!, lng: coords?.longitude! }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div className="client-marker-wrapper">
                  <div className="client-marker">
                    <img loading="lazy" src={logoUrl || DEFAULT_LOGO_URL} alt="Restaurant logo" className="client-logo" />
                    <span className="client-name">{name}</span>
                  </div>
                </div>
              </OverlayView>
              {coords?.addressObject && <div className="address-container">{formatAddress(coords?.addressObject)}</div>}
            </GoogleMap>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
          <Button
            variant="as-text"
            text={hasCoords ? 'Schimbă locația...' : 'Alege locația exactă...'}
            onClick={goToLocationPicker}
          />
        </div>
      </div>
      <div className="right-side" style={{ position: 'absolute', top: 14, right: 16, zIndex: 10 }}>
        <span className="only-desktop">{coords?.isVisible ? 'Afișată pe meniu' : 'Ascunsă'}</span>
        <Toggle className="toggle" checked={coords?.isVisible} icons={false} onChange={toggleCoords} />
      </div>
    </div>
  )
}
