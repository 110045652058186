import React, { useState } from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import CloseButton from 'src/global/CloseButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { categoriesSelector } from 'src/state/selectors'

export default function CategoryInfoToast() {
  const [hasDismissedCategoryEditInfo, setHasDismissedCategoryEditInfo] = useState<boolean>(
    localStorage.hasDismissedCategoryEditInfo
  )

  const categories = useSelector(categoriesSelector)

  if (hasDismissedCategoryEditInfo || !categories.list.length) {
    return null
  }

  return (
    <div className="info-prompt">
      <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '8px' }} />
      Ține apăsat pe o categorie pentru editare sau reordonare.
      <CloseButton
        title="Închide"
        onClick={() => {
          setHasDismissedCategoryEditInfo(true)
          localStorage.hasDismissedCategoryEditInfo = true
        }}
      />
      <div className="emoji">💡</div>
    </div>
  )
}
