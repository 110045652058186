import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 100vw;
  position: fixed;
  top: 54px;
  left: 0;
  z-index: 100;

  .progress-bar {
    height: 4px;
    width: 100%;
    overflow: hidden;
  }

  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.accent};
    animation: indeterminate 3s infinite ease-in;
    transform-origin: 0% 50%;
  }

  @keyframes indeterminate {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`
