import { ConsumerOrderProduct, ConsumerOrderProductViewModel, CurrencyCode } from '@meniudigital/shared'
import React from 'react'

import { Wrapper } from './styles'

type Props = {
  products: ConsumerOrderProduct[]
  currencyCode: CurrencyCode | undefined
}

const OrderProductList = (props: Props) => {
  const { products: orderProducts, currencyCode } = props

  const getImageStyle = () => {
    return { width: 32, height: 32 }
  }

  const renderProductName = (product: ConsumerOrderProduct, isSmall?: boolean) => {
    const isChild = product.parentProduct?.productId

    let quantityText = product.isSideDishToLineId ? null : <span className="inline-quantity"> x{product.quantity}</span>

    if (isChild) {
      return (
        <>
          <div className="row">
            <span className="product-name" style={isSmall ? { fontSize: 14, lineHeight: 1.2 } : {}}>
              {product.parentProduct?.name}
              {quantityText}
            </span>
          </div>
          <div className="variant-tag">
            <span className="variant-tag-text">{product.name}</span>
          </div>
        </>
      )
    } else {
      return (
        <div className="row">
          <span className="product-name" style={isSmall ? { fontSize: 14, lineHeight: 1.2 } : {}}>
            {product.name}
            {quantityText}
          </span>
        </div>
      )
    }
  }

  const mainProducts = [...orderProducts.filter(x => !x.isSideDishToLineId)].sort((a, b) => a.addedInOrderAt - b.addedInOrderAt)

  return (
    <Wrapper>
      {mainProducts.map(mainProduct => {
        const { thumbnailUrl } = mainProduct as ConsumerOrderProductViewModel
        const productSides = orderProducts.filter(x => mainProduct.hasSideDishLineIds?.includes(x.productLineItemId))
        const hasComments = (mainProduct.extraComments || '').length > 0
        return (
          <>
            <div className="product-row">
              {(productSides.length > 0 || hasComments) && (
                <div className="connector-continuation" style={{ bottom: 0, height: '50%' }} />
              )}
              <div className="left-side">
                {thumbnailUrl && (
                  <div className="product-image-wrapper" style={getImageStyle()}>
                    <img className="product-image" src={thumbnailUrl} />
                  </div>
                )}
                <div className="product-name-row">{renderProductName(mainProduct)}</div>
              </div>

              <div className="price">
                {mainProduct.effectivePrice! * mainProduct.quantity}
                <span className="currency"> {currencyCode}</span>
              </div>
            </div>

            {productSides.map((sideDish, j, a) => (
              <div key={sideDish.productLineItemId} className="product-row side-row">
                <div className="connector-wrapper">
                  <div className="connector" />
                </div>
                {(j < a.length - 1 || hasComments) && <div className="connector-continuation" />}
                <div className="left-side">
                  <div className="product-name-row">{renderProductName(sideDish, true)}</div>
                </div>
                <div className="price small">
                  {sideDish.effectivePrice! * sideDish.quantity} <span className="currency"> {currencyCode}</span>
                </div>
              </div>
            ))}
            {hasComments && (
              <div className="product-row side-row">
                <div className="connector-wrapper">
                  <div className="connector" />
                </div>
                <div className="left-side">
                  <span className="extra-comments-text">💬 "{mainProduct.extraComments}"</span>
                </div>
              </div>
            )}
          </>
        )
      })}
    </Wrapper>
  )
}

export default OrderProductList
