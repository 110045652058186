import React from 'react'
import Toggle from 'react-toggle'
import { IS_DESKTOP_OR_TABLET } from '../..'

type Props = {
  title: string
  isVisible: boolean | undefined
  iconPath: string
  value?: string | undefined
  placeholder?: string
  onValueUpdate?: (value: string) => void
  onEveryKeyPress?: (value: string) => void
  onVisibilityToggle: () => void
  hasError?: boolean
  // In case there's 2 inputs
  withSecondaryValue?: boolean
  secondaryValue?: string
  secondaryPlaceholder?: string
  onSecondaryInputBlur?: (value: string) => void
  noInput?: boolean
  notGrouped?: boolean
  multiline?: boolean
  isBusinessOnly?: boolean
  isDisabledInCurrentPlan?: boolean
}

export default function Setting(props: Props) {
  const { title, isVisible, iconPath, value, placeholder, onValueUpdate, onVisibilityToggle, onEveryKeyPress } = props
  const { withSecondaryValue, secondaryValue, onSecondaryInputBlur, secondaryPlaceholder } = props
  const { hasError, noInput, notGrouped, multiline, isBusinessOnly, isDisabledInCurrentPlan } = props

  const InputComponent = multiline ? 'textarea' : 'input'

  return (
    <div
      className={`setting ${notGrouped ? '' : 'grouped'} ${isDisabledInCurrentPlan ? 'disabled-in-plan' : ''}`}
      onClick={() => isDisabledInCurrentPlan && window.openBusinessModal('customMenuMessage')}
      title={
        isDisabledInCurrentPlan
          ? 'Această funcționalitate este disponibilă doar în planul Business.'
          : 'Mesaj personalizat pe meniu'
      }
    >
      {isBusinessOnly && (
        <div className="business-tag">
          <img src="/logo-white.svg" />
          {isDisabledInCurrentPlan && IS_DESKTOP_OR_TABLET && <div className="business-text">Business</div>}
        </div>
      )}

      <div className="left-side">
        <div className="setting-name">
          <div className="setting-icon">
            <img src={iconPath} alt={`${title} icon`} />
          </div>
          <span>{title}</span>
        </div>
        {(Boolean(isVisible && !noInput) || isBusinessOnly) && (
          <div>
            <InputComponent
              defaultValue={isDisabledInCurrentPlan ? '' : value}
              onBlur={e => onValueUpdate?.(e.target.value)}
              onChange={e => onEveryKeyPress?.(e.target.value)}
              placeholder={placeholder}
              style={multiline ? { height: '80px', paddingRight: '8px' } : {}}
              maxLength={200}
            />
            {withSecondaryValue && onSecondaryInputBlur && (
              <input
                defaultValue={secondaryValue}
                onBlur={e => onSecondaryInputBlur(e.target.value)}
                onPaste={e => onSecondaryInputBlur((e.target as any).value)}
                placeholder={secondaryPlaceholder}
              />
            )}
            {hasError && <div className="invalid-url">URL invalid. Copiază URL-ul din browser de pe {title}.</div>}
          </div>
        )}
      </div>
      <div className="right-side">
        <Toggle className="toggle" checked={isVisible && !isDisabledInCurrentPlan} icons={false} onChange={onVisibilityToggle} />
      </div>
    </div>
  )
}
