import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { waiterCallEndpoints } from 'src/api'

type ApiResponseList = Awaited<ReturnType<typeof waiterCallEndpoints.getAll>>

export type State = {
  isLoading: boolean
  list: ApiResponseList
}

export const initialState: State = {
  list: [] as ApiResponseList,
  isLoading: false,
}

export const getWaiterCalls = createAsyncThunk('waiterCalls/getWaiterCalls', waiterCallEndpoints.getAll)

const slice = createSlice({
  name: 'waiterCalls',
  initialState,
  reducers: {},
  extraReducers: actions => {
    actions.addCase(getWaiterCalls.pending, state => {
      state.isLoading = true
    })
    // Fulfilled
    actions.addCase(getWaiterCalls.fulfilled, (state, { payload }: PayloadAction<ApiResponseList>) => {
      state.isLoading = false
      state.list = [...payload].sort((a, b) => (+new Date(b.createdAt || '') > +new Date(a.createdAt || '') ? 1 : -1))
    })
  },
})

export default slice.reducer
