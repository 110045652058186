import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import Button from 'src/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addToOrderIntent } from 'src/state/business-orders'
import Textarea from 'src/global/form/Textarea'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { faArrowRight, faCheck, faCreditCard, faInfoCircle, faPencilAlt, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { State } from 'src/state/index'
import OrderSummary from '../ChooseHolders/BusinessOrderSummary'
import { BillingType, BusinessOrderPaymentType, PaymentRequest, BusinessOrder, computeIntentSummary } from '@meniudigital/shared'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import smoothscroll from 'smoothscroll-polyfill'
import wait from 'src/helpers/wait'
import { businessOrderEndpoints } from 'src/api'
import { Screen } from 'src/screens'
smoothscroll.polyfill()
SwiperCore.use([Autoplay, Navigation])

export default function FinalizeOrder() {
  const { orderIntent = {} } = useSelector((state: State) => state.businessOrders)
  const { user } = useSelector((state: State) => state.users)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [hasAcceptedTnc, setHasAcceptedTnc] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const [isLoading, setIsLoading] = useState(false)

  const { totalPrice, hasDelivery } = computeIntentSummary(orderIntent)

  const sendOrder = async () => {
    setIsLoading(true)

    try {
      try {
        const { fbq } = window as any
        fbq?.('track', 'Purchase', {
          currency: 'RON',
          value: totalPrice,
          content_ids: [orderIntent.subscriptionCode],
          content_name: orderIntent.subscriptionCode,
          content_type: 'product',
          contents: [{ id: orderIntent.subscriptionCode, quantity: 1 }],
          num_items: 1,
        })
      } catch (e) {}

      if (orderIntent.paymentType === BusinessOrderPaymentType.OnlineCard) {
        if (paymentRequest?.url) {
          formRef.current?.submit()
          return
        }
        const createdPaymentRequest = await businessOrderEndpoints.create<PaymentRequest>(orderIntent)
        setPaymentRequest(createdPaymentRequest)
        setTimeout(() => {
          formRef.current?.submit()
        }, 300)
      } else {
        const newOrder = await businessOrderEndpoints.create<BusinessOrder>(orderIntent)
        navigate('/completed-order/' + newOrder.id)
      }
    } catch (e) {
      alert('A apărut o eroare. Te rog încearcă mai târziu sau contactează-ne pentru a remedia problema mai repede.')
      setIsLoading(false)
    }
    await wait(3000)
    setIsLoading(false)
  }

  const shippingInfo = orderIntent.shippingInfo!
  const billingInfo = orderIntent.billingInfo!

  const wantsBankTransfer = orderIntent.paymentType === BusinessOrderPaymentType.BankTransfer
  const wantsCard = orderIntent.paymentType === BusinessOrderPaymentType.OnlineCard

  const selectPaymentType = async (paymentType: BusinessOrderPaymentType) => {
    dispatch(addToOrderIntent({ paymentType }))
    await wait(150)
    window.scrollTo({ top: window.outerHeight + 500, behavior: 'smooth' })
  }

  return (
    <Wrapper>
      <div className="page">
        <h3>{hasDelivery ? '📦' : '⚡'} Finalizează comanda</h3>

        {hasDelivery && (
          <div className="panel condensed">
            <div className="line strong">
              <span>Livrare</span>
              <span className="muted">în 3-5 zile lucrătoare</span>
            </div>
            <div className="line">{shippingInfo.name}</div>
            <div className="line">
              {['address', 'city', 'county', 'country'].map(key => (shippingInfo as any)[key]).join(', ')}
            </div>
            <div className="line">{shippingInfo.phoneNumber}</div>
            {shippingInfo.extraInfo?.length > 0 && <div className="line">{shippingInfo!.extraInfo}</div>}
          </div>
        )}

        <div className="panel condensed">
          <div className="line strong">
            <span>Facturare</span>
            <span className="muted">{billingInfo.billingType === BillingType.Company ? ' pe firmă' : ' pe persoană fizică'}</span>
          </div>

          <div className="line">
            {billingInfo.name}
            {billingInfo.billingType === BillingType.Company ? ` (CIF ${billingInfo.cui})` : ''}
          </div>
          <div className="line">{['address', 'city', 'county', 'country'].map(key => (billingInfo as any)[key]).join(', ')}</div>
          <div className="line">{billingInfo.phoneNumber}</div>
          <div className="line">{user?.email || '<email necunoscut>'}</div>
          <div className="edit-button" title="Editează" onClick={() => navigate(Screen.ChooseBillingType)}>
            <FontAwesomeIcon style={{ margin: '0 -1px' }} icon={faPencilAlt} />
          </div>
        </div>

        <OrderSummary order={orderIntent} withEdit />

        <div className={'panel condensed ' + (orderIntent.paymentType ? 'any-selected' : '')}>
          <div className="line strong">Plată</div>

          <div className="line">Cum dorești să faci plata?</div>

          <div
            onClick={() => selectPaymentType(BusinessOrderPaymentType.OnlineCard)}
            className={'payment-type ' + (wantsCard ? 'selected' : '')}
          >
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faCreditCard} />
            </div>
            <div>
              <div className="name">Online, cu cardul</div>
              {wantsCard && (
                <div className="description">
                  Plata se va face online printr-un portal securizat oferit de{' '}
                  <a href="https://netopia-payments.com/" target="_blank" rel="noreferrer">
                    Netopia Payments
                  </a>
                </div>
              )}
            </div>

            <div className="selected-indicator">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </div>
          <div
            onClick={() => selectPaymentType(BusinessOrderPaymentType.BankTransfer)}
            className={'payment-type ' + (wantsBankTransfer ? 'selected' : '')}
          >
            <div className="icon-wrapper">
              <FontAwesomeIcon style={{ fontSize: '23px' }} icon={faUniversity} />
            </div>
            <div>
              <div className="name">Transfer bancar</div>
              {wantsBankTransfer && (
                <div className="description">Îți trimitem factura proformă pe e-mail după trimiterea comenzii.</div>
              )}
            </div>

            <div className="selected-indicator">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </div>
        </div>

        {orderIntent?.paymentType ? (
          <>
            <div className="panel">
              <div className="line strong">Comentarii</div>

              <Textarea
                placeholder="Dorești să ne mai transmiți ceva?"
                value={orderIntent.extraCommentsFromUser || ''}
                onChange={e => dispatch(addToOrderIntent({ extraCommentsFromUser: e.target.value }))}
              />

              <p className="info">
                <FontAwesomeIcon icon={faInfoCircle} className="icon" />
                Dacă întâmpini probleme contactează-ne telefonic la <a href="tel:+40774566954">+40774566954</a> sau pe e-mail la{' '}
                <a href="mailto:contact@meniudigital.ro?subject=Salutare">contact@meniudigital.ro</a>
              </p>
            </div>

            <div className="row-wrapper">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="check"
                  checked={hasAcceptedTnc}
                  onChange={() => setHasAcceptedTnc(!hasAcceptedTnc)}
                />
                <span className="label-text">
                  {' '}
                  Accept{' '}
                  <a target="_blank" rel="noreferrer" href="/terms-and-conditions">
                    Termenii și Condițiile
                  </a>{' '}
                  platformei
                </span>
              </label>
            </div>

            <Button
              className="continue-button"
              text={wantsBankTransfer ? 'Trimite comanda' : 'Plătește'}
              disabled={!orderIntent.paymentType || !hasAcceptedTnc}
              icon={wantsBankTransfer ? faCheck : faArrowRight}
              onClick={sendOrder}
              isLoading={isLoading}
            />
          </>
        ) : (
          <div style={{ height: '0px' }}></div>
        )}
      </div>
      {paymentRequest && (
        <form style={{ height: 0, overflow: 'hidden', padding: 0 }} method="POST" action={paymentRequest?.url} ref={formRef}>
          <input type="hidden" name="env_key" value={paymentRequest?.env_key} />
          <input type="hidden" name="data" value={paymentRequest?.data} />
        </form>
      )}
    </Wrapper>
  )
}
