import styled from 'styled-components/macro'

export const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 57px;
  left: 0;
  z-index: 101;
  background: ${props => props.theme.backdrop};
  transition: opacity 0.25s ease-in-out;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }
`

export const Modal = styled.div<{ width?: number }>`
  position: fixed;
  z-index: 102;
  top: 10vh;
  left: 0;
  right: 0;
  margin: auto;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  padding: 15px;
  margin: -15px;

  margin-right: -11px;

  :hover {
    opacity: 0.6;
  }
`

export const ModalContent = styled.div`
  padding: 16px;
  position: relative;
`

export const ModalText = styled.div`
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
  word-break: break-word;
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
