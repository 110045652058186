import React, { FocusEventHandler, useState } from 'react'
import Toggle from 'react-toggle'
import { Wrapper } from './styles'

type Props = {
  title: string
  placeholder?: string
  hasInput: boolean
  regex?: RegExp
  onToggle: () => void
  onBlur: (input: string) => void
  checked?: boolean
  isDisabled?: boolean
  initialValue?: string
}

export default function ContactSetting(props: Props) {
  const [isEnabled, setIsEnabled] = useState(props.checked)
  const [inputValue, setInputValue] = useState('')
  const hasError = inputValue && !props.regex?.test(inputValue)

  const updateInputValue: FocusEventHandler<HTMLInputElement> = e => {
    const newValue = e.target.value
    setInputValue(newValue)
    props.onBlur(newValue)
  }

  return (
    <Wrapper style={props.isDisabled ? { backgroundColor: '#f8f9f9' } : {}}>
      <div className="left-side">
        <label>{props.title}</label>
        {isEnabled && props.hasInput && (
          <input
            type="text"
            defaultValue={props.initialValue}
            onBlur={updateInputValue}
            placeholder={props.placeholder}
            style={hasError ? { color: 'red', borderColor: 'red' } : {}}
          ></input>
        )}
      </div>
      <Toggle
        disabled={props.isDisabled}
        className="toggle"
        icons={false}
        checked={props.checked || false}
        onChange={() => {
          setIsEnabled(!isEnabled)
          props.onToggle()
        }}
      />
    </Wrapper>
  )
}
