import { Area } from 'react-easy-crop'

const createImage = (urlOrBase64: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = urlOrBase64
  })

export default async function getCroppedImageFile(imageBase64: string, cropArea: Area) {
  return new Promise<File>(async (resolve, reject) => {
    try {
      const image = await createImage(imageBase64)
      const canvas = document.createElement('canvas')
      canvas.width = cropArea.width
      canvas.height = cropArea.height
      const ctx = canvas.getContext('2d')!

      ctx.fillStyle = '#ffffff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(image, cropArea.x, cropArea.y, cropArea.width, cropArea.height, 0, 0, cropArea.width, cropArea.height)

      canvas.toBlob(blob => {
        resolve(blob! as File)
      }, 'image/jpeg')
    } catch (e) {
      reject(e)
    }
  })
}
