import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  .any-selected {
    .subscription:not(.selected) {
      transform: scale(0.95);
      color: ${props => props.theme.mutedText};

      .price {
        opacity: 0.5;
      }

      .duration {
        .icon {
          color: ${props => props.theme.mutedText};
        }
      }

      .discount-tag {
        filter: brightness(0.95);
      }

      :hover {
        transform: scale(0.97);
      }
    }
  }

  .subscription {
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding-right: 16px;
    overflow: hidden;
    width: 450px;
    max-width: calc(100vw - 32px);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    font-size: 14px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
    transition: all 0.15s ease-in-out;
    width: 100%;
    max-width: 500px;
    margin: 16px 0;

    .selected-indicator {
      position: absolute;
      color: ${props => props.theme.accent};
      opacity: 0;
      transition: all 0.3s ease-in-out;
      top: 14px;
      right: 16px;
      font-size: 18px;
      margin: auto;
      height: 20px;
    }

    .price {
      background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
      color: #fff;
      border-radius: 100%;
      height: 132px;
      width: 132px;
      margin-left: -53px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-end;
      padding-right: 8px;
      text-align: center;
      flex-direction: column;
      flex-shrink: 0;
      font-weight: 700;
      transition: all 0.2s ease-in-out;

      .old-price {
        display: inline-block;
        position: relative;
        color: #ff7424;

        background: linear-gradient(120deg, #ffa080, #ff5722);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        font-weight: 700;
        margin-bottom: -12px;
        display: inline;
        flex-shrink: 0;
        padding: 0 4px;
      }

      .old-price:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;

        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
      }

      .price-inner {
        display: flex;
        flex-direction: column;
        width: 69px;

        .currency {
          margin-top: -2px;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .icon {
        color: #fff;
      }
    }

    .name {
      font-size: 18px;
      font-weight: 600;
      padding-right: 16px;
    }

    .code {
      font-size: 14px;
      color: ${props => props.theme.mutedText};
      margin-top: 4px;
      font-weight: 600;
      padding-right: 16px;
    }

    :hover {
      box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
      transform: scale(1.015);

      .price {
        filter: brightness(1.15);
      }

      .discount-tag {
        filter: brightness(1.05);
      }
    }

    .duration {
      margin-top: 6px;
      font-weight: 600;
      color: ${props => props.theme.mutedText};

      .icon {
        margin-right: 6px;
        color: ${props => props.theme.blue};
      }
    }

    &.selected {
      border-color: ${props => props.theme.accent};
      box-shadow: 0 4px 24px rgb(0 0 0 / 20%);

      transform: scale(1.03);

      .selected-indicator {
        opacity: 1;
      }
    }

    p {
      text-align: center;
      max-width: 500px;
      font-weight: 500;
      color: ${props => props.theme.mutedText};
      margin-top: 24px;
      font-size: 15px;

      .icon {
        margin-right: 8px;
      }

      span {
        margin-top: 8px;
        display: inline-block;
      }

      margin-bottom: 32px;
    }

    .discount-tag {
      position: absolute;
      bottom: 12px;
      right: 0;
      /* background: linear-gradient(120deg, ${props => props.theme.orange}, ${props => props.theme.redOrange}); */
      background: linear-gradient(120deg, #ffa080, #ff5722);
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      height: 28px;
      width: 65px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${props => props.theme.white};
      font-weight: 600;
      font-size: 16px;
      transition: all 0.2s ease-in-out;
    }
  }

  .continue-button {
    &[disabled] {
      background: #61778a55;
    }
  }
`
