import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  button[data-variant='as-text'] {
    padding-left: 0;
    margin-top: -8px !important;
  }

  input {
    padding-right: 8px;

    &.invalid:not(:focus) {
      border-color: ${props => props.theme.red};
    }
  }
`
