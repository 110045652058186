import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { featureVotesEndpoints } from 'src/api'
import { FeatureVote } from '@meniudigital/shared'

export type State = {
  isLoading: boolean
  myVotes?: FeatureVote[]
}

export const initialState: State = {
  isLoading: false,
  myVotes: [],
}

export const getMyVotes = createAsyncThunk('featureVotes/getAll', featureVotesEndpoints.getAll)

const slice = createSlice({
  name: 'featureVotes',
  initialState,
  reducers: {},
  extraReducers: actions => {
    actions.addCase(getMyVotes.pending, (state: State) => {
      state.isLoading = true
    })
    actions.addCase(getMyVotes.fulfilled, (state, { payload }: PayloadAction<FeatureVote[]>) => {
      state.isLoading = false
      state.myVotes = payload
    })
    actions.addCase(getMyVotes.rejected, (state, { error: _ }) => {
      state.isLoading = false
    })
  },
})
export default slice.reducer
