import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import theme from './theme'
import GlobalStyle from './styles'
import Header from './global/Header'
import ErrorNotificationContainer from './global/ErrorNotification/Container'
import Login from './screens/Auth/Login'
import ChangePassword from './screens/Auth/ChangePassword'
import Register from './screens/Auth/Register'
import SetNewPassword from './screens/Auth/SetNewPassword'
import ForgotPassword from './screens/Auth/ForgotPassword'
import Personalize from './screens/PersonalizeScreen'
import ChooseHolders from './screens/BusinessOrders/ChooseHolders'
import ChooseBillingType from './screens/BusinessOrders/ChooseBillingType'
import EnterBillingInfo from './screens/BusinessOrders/EnterBillingInfo'
import EnterShippingInfo from './screens/BusinessOrders/EnterShippingInfo'
import FinalizeOrder from './screens/BusinessOrders/FinalizeOrder'
import CompletedOrder from './screens/BusinessOrders/CompletedOrder'
import FailedOrder from './screens/BusinessOrders/FailedOrder'
import ConsumerOrdersScreen from './screens/ConsumerOrders/index'
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import ContactScreen from './screens/Contact'
import UsersScreen from './screens/SuperAdmin/Users'
import UserActivityScreen from './screens/SuperAdmin/UserActivity'
import StatsScreen from './screens/SuperAdmin/StatsScreen'
import Home, { IS_DESKTOP_OR_TABLET, firstOfSeptember } from './screens/HomeScreen'
import BusinessOrdersScreen from './screens/BusinessOrders/BusinessOrderHistory'
import 'react-toggle/style.css' // for ES6 modules
import { useSelector } from 'react-redux'
import ScrollToTop from './helpers/scrollToTop'
import { State } from './state'
import { Screen } from './screens'
import ConfirmationModal from './global/ConfirmationModal'
import { User, isBusiness } from '@meniudigital/shared'
import SettingsModal from './screens/HomeScreen/SettingsModal'
import ConfirmEmail from './screens/Auth/ConfirmEmail'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import LanguageModal from './screens/HomeScreen/LanguageModal'
import SetLocation from './screens/SetLocationScreen'
import MySubscription from './screens/MySubscriptionScreen'
import MyQrCodeModal from './screens/HomeScreen/MyQrCodeModal'
import BusinessModal from './screens/HomeScreen/BusinessModal'
import ChooseSubscription from './screens/BusinessOrders/ChooseSubscription'
import ConsumerOrdersSuperadminScreen from './screens/ConsumerOrders/ConsumerOrdersSuperadmin'
import setEnvironmentLogo from './helpers/setEnvironmentLogo'
import RenewSubscription from './screens/Auth/RenewSubscription'
import WaiterCallsSuperadmin from './screens/ConsumerOrders/WaiterCallsSuperadmin'
import SetSchedule from './screens/SetScheduleScreen'
import ReservationSettingsScreen from './screens/ReservationSettingsScreen'
import ReservationsScreen from './screens/ReservationsScreen'

export const isUserComplete = (user?: User) =>
  (user?.name || '').length > 0 && (user?.city || '').length > 0 && (user?.username || '').length > 0

export default function App() {
  const { isDark } = useSelector((state: State) => state.theme)
  const { user } = useSelector((state: State) => state.users)
  const { orderIntent } = useSelector((state: State) => state.businessOrders)

  const goToHome = <Navigate to={Screen.Home} />
  const goToLogin = <Navigate to={Screen.Login} />
  const goToPersonalize = <Navigate to={Screen.PersonalizeInFlow} />
  const goToSetLocation = <Navigate to={Screen.SetLocation + '?isOrderGuard=true'} />

  const isUserActive = Boolean(user?.isEmailConfirmed || +new Date(user?.joinDate || 0) <= firstOfSeptember)

  const canB2bOrder = Boolean(isUserActive)
  const hasCoords = Boolean(user?.coords?.latitude && user.coords.longitude)

  useEffect(() => {
    //Set Google AdSense code if not business
    if (!isBusiness(user) && IS_DESKTOP_OR_TABLET) {
      const script = document.createElement('script')
      script.setAttribute('data-ad-client', 'ca-pub-4310566476706904')
      script.setAttribute('async', '')
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4310566476706904'
      document.getElementsByTagName('head')[0].appendChild(script)
    }

    setEnvironmentLogo()
  }, [])

  return (
    <ThemeProvider theme={theme(isDark)}>
      <BrowserRouter>
        <GlobalStyle />
        <Header />
        <ScrollToTop />
        <ErrorNotificationContainer />
        <Routes>
          <Route path={Screen.Login} element={isUserComplete(user) ? goToHome : <Login />} />
          <Route path={Screen.ChangePassword} element={isUserComplete(user) ? <ChangePassword /> : goToLogin} />
          <Route path={Screen.ForgotPassword} element={isUserComplete(user) ? goToHome : <ForgotPassword />} />
          <Route path={Screen.ConfirmEmail} element={<ConfirmEmail />} />
          <Route path={Screen.RenewSubscription} element={isUserComplete(user) ? <RenewSubscription /> : goToLogin} />
          <Route
            path={Screen.Register}
            element={
              <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY!} useEnterprise={true}>
                <Register />
              </GoogleReCaptchaProvider>
            }
          />
          <Route path={Screen.SetNewPassword} element={<SetNewPassword />} />
          <Route path={Screen.Personalize} element={isUserComplete(user) ? <Personalize /> : goToLogin} />
          <Route path={Screen.SetLocation} element={isUserComplete(user) ? <SetLocation /> : goToLogin} />
          <Route path={Screen.SetSchedule} element={isUserComplete(user) ? <SetSchedule /> : goToLogin} />
          <Route path={Screen.MySubscription} element={isUserComplete(user) ? <MySubscription /> : goToLogin} />
          <Route path={Screen.ChooseSubscription} element={canB2bOrder ? <ChooseSubscription /> : goToPersonalize} />
          <Route path={Screen.ChooseHolders} element={canB2bOrder ? <ChooseHolders /> : goToPersonalize} />
          <Route path={Screen.ChooseBillingType} element={canB2bOrder ? <ChooseBillingType /> : goToPersonalize} />
          <Route path={Screen.EnterBillingInfo} element={canB2bOrder ? <EnterBillingInfo /> : goToPersonalize} />
          <Route path={Screen.EnterShippingInfo} element={canB2bOrder ? <EnterShippingInfo /> : goToPersonalize} />
          <Route
            path={Screen.FinalizeOrder}
            element={canB2bOrder ? orderIntent?.billingInfo ? <FinalizeOrder /> : goToHome : goToPersonalize}
          />
          <Route path={Screen.CompletedOrder} element={<CompletedOrder />} />
          <Route path={Screen.FailedOrder} element={<FailedOrder />} />
          <Route path={Screen.TermsAndConditions} element={<TermsAndConditionsScreen />} />
          <Route path={Screen.PrivacyPolicy} element={<PrivacyPolicyScreen />} />
          <Route path={Screen.BusinessOrders} element={<BusinessOrdersScreen />} />
          <Route path={Screen.ConsumerOrders} element={hasCoords ? <ConsumerOrdersScreen /> : goToSetLocation} />
          <Route
            path={Screen.ConsumerOrdersSuperadmin}
            element={user?.isSuperAdmin ? <ConsumerOrdersSuperadminScreen /> : goToHome}
          />
          <Route path={Screen.WaiterCallsSuperadmin} element={user?.isSuperAdmin ? <WaiterCallsSuperadmin /> : goToHome} />
          <Route path={Screen.Reservations} element={isUserComplete(user) ? <ReservationsScreen /> : goToLogin} />
          <Route path={Screen.ReservationSettings} element={isUserComplete(user) ? <ReservationSettingsScreen /> : goToLogin} />
          <Route path={Screen.Users} element={user?.isSuperAdmin ? <UsersScreen /> : goToHome} />
          <Route path={Screen.UserActivity} element={<UserActivityScreen />} />
          <Route path={Screen.Stats} element={<StatsScreen />} />
          <Route path={Screen.Contact} element={<ContactScreen />} />
          <Route path={Screen.Home} element={isUserComplete(user) ? <Home /> : goToLogin} />
        </Routes>
        <ConfirmationModal />
        {user && <SettingsModal />}
        {user && <LanguageModal />}
        {user && <MyQrCodeModal />}
        <BusinessModal />
      </BrowserRouter>
    </ThemeProvider>
  )
}
