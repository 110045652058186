import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  .billing-type {
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding-right: 16px;
    overflow: hidden;
    width: 450px;
    height: 80px;
    max-width: calc(100vw - 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    cursor: pointer;
    font-weight: 400;
    font-size: 32px;
    position: relative;
    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
    transition: all 0.1s ease-in-out;
    padding-left: 70px;

    .name {
      font-size: 18px;
    }

    .icon-wrapper {
      background: ${props => props.theme.accent}21;
      position: absolute;
      left: 0;
      height: 100%;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      color: ${props => props.theme.accent};
    }

    :hover {
      background: ${props => props.theme.accentForHover};
      color: ${props => props.theme.accent};
    }
  }

  .continue-button {
    margin-top: 0;
  }
`
