import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Button from 'src/global/Button'
import { State } from 'src/state'
import { userEndpoints } from 'src/api'
import { logOut } from 'src/state/users'

export default function ChangePassword() {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  const { isLoading: loading } = useSelector((state: State) => state.users)

  const onSubmit = async (passwords: { oldPassword: string; newPassword: string; newPasswordAgain: string }) => {
    const { newPassword, newPasswordAgain } = passwords

    if (newPassword.length < 6) {
      await window.showConfirm({ text: `Parola trebuie să aibă cel puțin 6 caractere.` })
      return
    }

    if (newPassword !== newPasswordAgain) {
      await window.showConfirm({ text: `Parolele nu sunt la fel.` })
      return
    }

    await userEndpoints.changePassword(passwords)
    await window.showConfirm({ text: `Parola a fost schimbată cu succes.` })

    dispatch(logOut())
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  return (
    <div className="page">
      <form
        //@ts-ignore
        loading={loading}
        onSubmit={handleSubmit(onSubmit as any)}
      >
        <label>parola curentă</label>
        <input type="password" maxLength={50} placeholder="parola curentă" {...register('oldPassword')} />

        <label>parola nouă</label>
        <input type="password" maxLength={50} placeholder="parolă nouă" {...register('newPassword')} />

        <label>confirmă parola nouă</label>
        <input type="password" maxLength={50} placeholder="confirmă parola nouă" {...register('newPasswordAgain')} />

        <Button type="submit" text="Schimbă" />
      </form>
    </div>
  )
}
