import React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'src/state'
import { CurrencyCode } from '@meniudigital/shared'
import CurrencyToggle from '../CurrencyToggle'

export default function CurrencySetting() {
  const { user } = useSelector((state: State) => state.users)

  if (!user) {
    return null
  }

  return (
    <>
      <div className="setting-explainer" style={{ marginTop: '40px' }}>
        În ce monedă dorești să introduci prețul produselor tale?
      </div>
      <div className="setting">
        <div className="left-side">
          <div className="setting-name">
            <div className="setting-icon">
              <img src="/icons/currency.png" alt="Suggestion icon" />
            </div>
            <span>
              <span className="only-desktop">Valuta produselor tale este </span>
              <span>{(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}</span>
            </span>
          </div>
        </div>
        <div className="right-side">
          <CurrencyToggle />
        </div>
      </div>
    </>
  )
}
