import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { confirmEmail } from 'src/state/users'
import { Wrapper } from './styles'
import Lottie from 'lottie-react'
import * as emailAnimation from 'src/assets/animations/email-tick.json'
import { Screen } from 'src/screens'

export default function ConfirmEmail() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const confirmEmailAddress = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    try {
      const response = (await dispatch(confirmEmail(params.registrationRequestId))) as any

      if (response.payload.hasNoAccountYet) {
        return navigate(`${Screen.Register}?registrationRequestId=${params.registrationRequestId}`)
      }

      await window.showModal({
        title: `E-mail confirmat`,
        text: `Adresa ta de e-mail a fost confirmată cu succes.`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })
      navigate(Screen.Home)
    } catch (e) {
      navigate(Screen.Home)
    }
  }

  useEffect(() => {
    confirmEmailAddress()
  }, [])

  return (
    <Wrapper>
      <div className="page">
        <div className="panel">
          <div className="icon">
            <Lottie
              {...{
                loop: true,
                autoplay: true,
                animationData: emailAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={100}
              width={100}
            />
          </div>
          <span>{'Se confirmă adresa...'}</span>
        </div>
      </div>
    </Wrapper>
  )
}
