import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .panel {
    padding: 40px;
    font-size: 20px;

    .icon {
      margin-bottom: 24px;
      color: ${props => props.theme.accent};
    }
  }

  form {
    overflow: hidden;
  }

  .social-logo {
    width: 16px;
    margin-bottom: -2px;
    display: inline-block;
    margin-right: 4px;
    margin-top: 4px;
  }

  button {
    align-self: center;
  }

  .error {
    max-width: 340px;
    font-size: 14px;
    margin-top: 8px;
  }

  .email {
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 600;
  }

  .url-explainer {
    max-width: 340px;
    font-size: 14px;
    font-style: italic;
  }
  
  input {
    padding-right: 8px;
  }

  .url-preview {
    margin-top: 8px;
    font-size: 14px;

    .url {
      color: ${props => props.theme.accent};

      .slug {
        font-weight: 600;
      }
    }
  }

  .email-header { 
    margin-top: -24px;
    margin-left: -32px;

    @media (max-width: 768px) {
      margin-top: -16px;
      margin-left: -24px;
      margin-bottom: 8px;
      font-size: 16px;
    }

    padding: 16px;
    background-color: ${props => props.theme.accent};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: calc(100% + 64px);
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 16px;
    box-shadow: 0px 2px 12px ${props => props.theme.normalText}44;

    i {
      margin-right: 8px;
    }
  }

  .question {
    font-weight: 600;
    margin-top: 24px;
    font-size: 18px;
    color: ${props => props.theme.normalText};
    margin-bottom: 4px;
    display: inline-block;

    .smaller {
      font-size: 16px;
    }
  }
`
