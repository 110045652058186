import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Modal,
  Header,
  CloseButtonWrapper,
  ModalContent,
  ModalFooter,
  FieldWrapper,
  CloseButtonLineOne,
  CloseButtonLineTwo,
} from '../../../HomeScreen/ProductModal/styles'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from 'src/global/Button'
import { User, UserPatchType } from '@meniudigital/shared'
import useForm from 'src/helpers/useForm'
import { useDispatch } from 'react-redux'
import { patchUser } from 'src/state/users'

export default function UserModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { formValues, register, setValue } = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    window.openUserEditModal = open
  }, [])

  const open = (user: User) => {
    setIsOpen(true)
    ;['name', 'city', 'id'].forEach(key => setValue(key, (user as any)[key]))
    setValue('coordsString', [user.coords?.latitude, user.coords?.longitude].filter(Boolean).join(','))

    document.querySelector('body')!.style.overflowY = 'hidden'
  }

  const save = () => {
    if (!isUserValid()) {
      return
    }

    let coords = undefined

    const coordsString = formValues['coordsString']?.value
    if (coordsString?.length) {
      const [latitudeString = '', longitudeString = ''] = coordsString.split(',')

      const latitude = +latitudeString.trim()
      const longitude = +longitudeString.trim()

      if (!latitude || !longitude) {
        return window.showModal({
          title: `Coordonate invalide`,
          text: `Coordonatele introduse nu sunt valide. Verifică că formatul coordonatelor e corect (latitudine,longitudine) și încearcă din nou.`,
          confirmButtonText: 'Ok',
          isConfirm: true,
        })
      } else {
        coords = {
          latitude,
          longitude,
        }
      }
    }

    dispatch(
      patchUser({
        type: UserPatchType.FromAdminInterface,
        data: {
          name: formValues['name'].value,
          city: formValues['city'].value,
          coords,
        },
        userId: formValues['id'].value,
      })
    )

    close()
  }

  const isUserValid = () => {
    if (!formValues['name']?.value.length || !formValues['city']?.value.length) {
      return false
    }

    return true
  }

  const close = () => {
    setIsOpen(false)
    document.querySelector('body')!.style.overflowY = 'scroll'
  }

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <input type="hidden" {...register('id')} />
      <Modal style={{ width: '400px' }} className={isOpen ? 'open' : 'closed'}>
        <Header>
          Modifică date client
          <CloseButtonWrapper onClick={close} title="Închide">
            <CloseButtonLineOne />
            <CloseButtonLineTwo />
          </CloseButtonWrapper>
        </Header>
        <ModalContent>
          <FieldWrapper>
            <label>Numele restaurantului / localului</label>
            <input placeholder="Numele restaurantului!!" maxLength={200} {...register('name')} />
          </FieldWrapper>
          <FieldWrapper>
            <label>Localitate, județ</label>
            <input placeholder="Oraș, jud. Județ" maxLength={200} {...register('city')} />
          </FieldWrapper>
          <FieldWrapper>
            <label>Coordonate</label>
            <input
              placeholder="latitudine,longitudine"
              style={{ paddingRight: 0 }}
              maxLength={200}
              {...register('coordsString')}
            />
          </FieldWrapper>
        </ModalContent>
        <ModalFooter>
          <Button variant="as-text" onClick={close} text="Anulează" />
          <Button onClick={save} text="Salvează" disabled={!isUserValid()} icon={faCheck} />
        </ModalFooter>
      </Modal>
    </>
  )
}
