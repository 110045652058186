import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;

  .right-section {
    margin-left: 16px;
    padding: 16px;
    padding-top: 24px;
    width: 80%;
    border-radius: 16px;
    background-color: ${props => props.theme.activeBackground};
    border: 1px solid ${props => props.theme.border};
    position: relative;

    .section-title {
      position: absolute;
      background: ${props => props.theme.white};
      text-transform: uppercase;
      padding: 4px 4px;
      left: 0;
      right: 0;
      margin: auto;
      top: -8px;
      font-size: 11px;
      font-weight: 700;
      width: 144px;
      text-align: center;
      border-radius: 16px;
      border: 1px solid ${props => props.theme.border};
    }
  }

  .explainer {
    font-size: 14px;
  }

  .suceeding-category {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 4px 12px ${props => props.theme.shadow};
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 10px 12px;
    padding-right: 0;
    margin-top: 8px;
    border: 1px solid ${props => props.theme.border};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    font-weight: 500;
    color: ${props => props.theme.normalText};
    cursor: pointer;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 16px;

    .right-section {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }
  }
`
