import Compressor from 'compressorjs'

const compressImage = (image: File) =>
  new Promise<File>(
    resolve =>
      new Compressor(image, {
        quality: 0.7,
        maxWidth: 1600,
        maxHeight: 1600,
        resize: 'contain',
        success: file => resolve(file as File),
      })
  )

export default compressImage
