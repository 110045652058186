import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  cursor: pointer;
  margin-top: -12px;
  position: absolute;
  right: 17px;
  top: 25px;
  transform: rotate(45deg);

  width: 50px;
  height: 50px;

  margin-right: -11px;

  :hover {
    opacity: 0.6;
  }

  .line-one {
    width: 22px;
    height: 2px;
    display: inline-block;
    position: relative;
    top: 0px;
    left: 11px;
  }

  .line-two {
    height: 22px;
    width: 2px;
    display: inline-block;
    margin-left: -22.5px;
    position: relative;
    top: 10px;
    left: 21px;
  }
`

export default function CloseButton(props: any) {
  return (
    <Wrapper className="close-button" {...props}>
      <div className="line-one" style={{ background: props.color || '#bbc3c8' }}></div>
      <div className="line-two" style={{ background: props.color || '#bbc3c8' }}></div>
    </Wrapper>
  )
}
