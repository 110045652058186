import React from 'react'
import CloseButton from 'src/global/CloseButton'
import { CurrencyCode, LanguageCode, MenuProduct, User } from '@meniudigital/shared'
import {
  SmallDescription,
  Wrapper,
  ProductImageWrapper,
  ProductImage,
  MatchedString,
  OutOfStockIndicator,
} from '../../../Product/styles'

type Props = {
  product: MenuProduct
  query: string
  languageCode: LanguageCode
  inPartyMode: boolean
  user: User
  onClick?: () => void
  onRemoveClick?: () => void
}

export default function Product({ product, query: rawQuery, languageCode, inPartyMode, user, onClick, onRemoveClick }: Props) {
  const query = rawQuery
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^(\w|\s)]/g, '')
  const productName = product.names[languageCode]
  const normalizedProductName = productName
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^(\w|\s)]/g, '')

  const indexOfMatch = normalizedProductName.indexOf(query)

  const beforeMatchedString = productName.substring(0, indexOfMatch)
  const matchedString = productName.substring(indexOfMatch, indexOfMatch + query.length)
  const afterMatchedString = productName.substring(indexOfMatch + query.length)

  return (
    <Wrapper
      onClick={onClick}
      className="hoverable-product"
      style={{ margin: 0, width: '100%', marginTop: '8px', position: 'relative' }}
    >
      {onRemoveClick && <CloseButton onClick={onRemoveClick} title="Închide" style={{ right: '12px', top: '17px' }} />}
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <div style={{ display: 'flex', flex: 1 }}>
          {product.imageUrl && (
            <ProductImageWrapper className="product-image-wrapper">
              <ProductImage src={product.thumbnailUrl} style={{ height: '100%' }} />
            </ProductImageWrapper>
          )}
          <div style={{ maxWidth: onRemoveClick ? 'calc(100% - 80px)' : undefined }}>
            <div className="bolder">
              <span>{beforeMatchedString}</span>
              <MatchedString>{matchedString}</MatchedString>
              <span style={{ marginRight: '8px' }}>{afterMatchedString}</span>
              {!product.isAvailable && <OutOfStockIndicator>Fără stoc</OutOfStockIndicator>}
            </div>
            {Boolean(product.price) && (
              <SmallDescription>
                {product.isDiscounted ? 'Preț original: ' : 'Preț: '}
                <span style={{ fontWeight: '400' }}>
                  {product
                    .price!.toString()
                    .replace('.', ',')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                  {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
                </span>
                {product.isDiscounted && product.discountedPrice && (
                  <span>
                    {' '}
                    / Preț redus:{' '}
                    <span style={{ fontWeight: '400' }}>
                      {product.discountedPrice
                        .toString()
                        .replace('.', ',')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                      {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
                    </span>
                  </span>
                )}
              </SmallDescription>
            )}
            {(product.priceDuringEvent || 0) > 0 && (
              <SmallDescription>
                {inPartyMode && <div className="bullet"></div>} Preț eveniment:{' '}
                <span style={{ fontWeight: '400' }}>
                  {(product.priceDuringEvent || '')
                    .toString()
                    .replace('.', ',')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                  {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
                </span>
              </SmallDescription>
            )}
            {Boolean(product.quantities) && (
              <SmallDescription>
                Gramaj{product.quantities!.includes('/') || product.quantities!.includes(',') ? 'e' : ''}:{' '}
                <span style={{ fontWeight: '400' }}>{product.quantities}</span>
              </SmallDescription>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
