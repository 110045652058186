import { logError } from 'src/helpers/logError'

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api`

const API_VERSION_THAT_I_UNDERSTAND = '1.0.0'

const methods = {
  get: async function (endpoint: string) {
    return executeEndpoint(endpoint, 'GET')
  },
  post: async function (endpoint: string, body: Object) {
    return executeEndpoint(endpoint, 'POST', body)
  },
  put: async function (endpoint: string, body: Object) {
    return executeEndpoint(endpoint, 'PUT', body)
  },
  patch: async function (endpoint: string, body: Object) {
    return executeEndpoint(endpoint, 'PATCH', body)
  },
  delete: async function (endpoint: string) {
    return executeEndpoint(endpoint, 'DELETE')
  },
}

async function executeEndpoint(endpoint: string, verb: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', body?: Object) {
  const headers = new Headers({
    clientVersion: API_VERSION_THAT_I_UNDERSTAND,
  })

  const token = localStorage.token || null
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  const isFormData = body instanceof FormData
  if (!isFormData) {
    headers.set('Content-Type', 'application/json')
  }

  // Set the device id to web-browser (only mobile apps have a different device id)
  headers.set('x-device-id', 'web-browser')

  const response = await fetch(`${baseUrl}/${endpoint}`, {
    method: verb,
    headers,
    body: isFormData ? body : JSON.stringify(body),
  })

  if (response.status.toString() === '401') {
    hardLogOut()
    throw new Error('Not authenticated')
  }

  if (response.status.toString() === '404') {
    await window.showConfirm({ title: `Link invalid`, text: `Linkul accesat nu este valid.` })
    throw new Error('Not found')
  }

  if (response.status.toString() === '500') {
    await window.showConfirm({ title: `Eroare`, text: 'A apărut o eroare. Te rog reîncarcă pagina și încearcă din nou.' })
    throw new Error('Not found')
  }

  const json = await response.json?.()

  if (!response.ok) {
    const statusCode = (response.status || '').toString()
    logError(json || response, `${statusCode} @ /${endpoint}`)
    const message =
      json?.message || json?.error || json?.errors || 'A apărut o eroare. Te rog reîncarcă pagina și încearcă din nou.'
    window.showConfirm({ title: 'Eroare', text: message })
    throw new Error(`Status code: ${statusCode}`)
  }

  return json
}

export function hardLogOut() {
  setTimeout(() => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = '/login'
  }, 1000)
}

export default methods
