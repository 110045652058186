import { useState } from 'react'

type FormElement = {
  value: string
  isOptional?: boolean
}

const useForm = () => {
  const [formValues, setFormValues] = useState<{ [fieldName: string]: FormElement }>({})

  const setValue = (fieldName: string, value: string) => {
    if (formValues[fieldName]) {
      const newFormValues = { ...formValues, [fieldName]: { ...formValues[fieldName], value } }
      formValues[fieldName] = { ...formValues[fieldName], value }

      setFormValues(newFormValues)
    }
  }

  const register = (fieldName: string, isOptional?: boolean, onChange?: Function, transform?: Function) => {
    if (!formValues[fieldName]) {
      const newFormValues = { ...formValues, [fieldName]: { isOptional, value: '' } }
      setFormValues(newFormValues)
    }

    return {
      name: fieldName,
      value: formValues[fieldName]?.value || '',
      required: !isOptional,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(fieldName, transform ? transform(e.target.value) : e.target.value)

        onChange && onChange()
      },
    }
  }

  return {
    formValues,
    isValid: Object.keys(formValues).every(x => (formValues[x]?.value || '').length > 0 || formValues[x].isOptional),
    setValue,
    register,
  }
}

export default useForm
 