import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'

import { actionLogEndpoints } from 'src/api'
import { ActionLog, ActionType, MenuProduct, User } from '@meniudigital/shared'
import { CountTag, SearchInput } from '../../HomeScreen/styles'
import { useSelector } from 'react-redux'
import { State } from 'src/state'
import moment, { Moment } from 'moment'

type LogUiProps = {
  _id: string
  repetitionCount: number
  subActions: (ActionLog & LogUiProps)[]
  user: User
  minDate: Moment
  maxDate: Moment
  userCounted: boolean
  actionCounted: boolean
}

const ActionIcons = {
  [ActionType.ChangePassword]: {
    name: 'fa fa-key',
    color: '#11b7f3',
  },
  [ActionType.GetCurrentUser]: {
    name: 'fa fa-user',
    color: '#11b7f3',
  },
  [ActionType.Login]: {
    name: 'fa fa-arrow-right',
    color: '#11b7f3',
  },
  [ActionType.CreateProduct]: {
    name: 'fa fa-pizza-slice',
    color: '#7ac943',
  },
  [ActionType.EditProduct]: {
    name: 'fa fa-pizza-slice',
    color: '#ffc107',
  },
  [ActionType.RemoveProduct]: {
    name: 'fa fa-pizza-slice',
    color: '#f44336',
  },
  [ActionType.CreateCategory]: {
    name: 'fa fa-clipboard-list',
    color: '#7ac943',
  },
  [ActionType.EditCategory]: {
    name: 'fa fa-clipboard-list',
    color: '#ffc107',
  },
  [ActionType.RemoveCategory]: {
    name: 'fa fa-clipboard-list',
    color: '#f44336',
  },
  [ActionType.GetRegistrationRequest]: {
    name: 'fa fa-gmail',
    color: '#11b7f3',
  },
  [ActionType.ConfirmEmailAddress]: {
    name: 'fa fa-gmail',
    color: '#11b7f3',
  },
  [ActionType.ResendActivationEmail]: {
    name: 'fa fa-gmail',
    color: '#11b7f3',
  },
  [ActionType.CompletePasswordReset]: {
    name: 'fa fa-key',
    color: '#11b7f3',
  },
  [ActionType.ClickOnResetLink]: {
    name: 'fa fa-gmail',
    color: '#11b7f3',
  },
  [ActionType.SendPasswordResetEmail]: {
    name: 'fa fa-key',
    color: '#11b7f3',
  },
  [ActionType.CreateRegistrationRequest]: {
    name: 'fa fa-gmail',
    color: '#7ac943',
  },
  [ActionType.RequestSetLanguages]: {
    name: 'fa fa-globe',
    color: '#9e9e9e',
  },
  [ActionType.RequestNewLanguages]: {
    name: 'fa fa-globe',
    color: '#9e9e9e',
  },
  [ActionType.SucceedSetLanguages]: {
    name: 'fa fa-globe',
    color: '#3f51b5',
  },
  [ActionType.SetBusinessMedium]: {
    name: 'fa fa-instagram',
    color: '#9c27b0',
  },
  [ActionType.SetLogo]: {
    name: 'fa fa-image',
    color: '#c2185b',
  },
  [ActionType.SetLogoScale]: {
    name: 'fa fa-image',
    color: '#c2185b',
  },
  [ActionType.SetCoverPhoto]: {
    name: 'fa fa-image',
    color: '#3f51b5',
  },
  [ActionType.SetCoverPhotoScale]: {
    name: 'fa fa-image',
    color: '#3f51b5',
  },
  [ActionType.CreateBusinessOrder]: {
    name: 'fa fa-money',
    color: '#9e9e9e',
  },
  [ActionType.CreateConsumerOrder]: {
    name: 'fa fa-money',
    color: '#11b7f3',
  },
  [ActionType.CallWaiter]: {
    name: 'fa fa-bell',
    color: '#ff8324',
  },
  [ActionType.ConfirmCallWaiter]: {
    name: 'fa fa-bell',
    color: '#ff8324',
  },
  [ActionType.PayOrderViaOnlineCard]: {
    name: 'fa fa-money',
    color: '#11b7f3',
  },
  [ActionType.TogglePartyMode]: {
    name: 'fa fa-wine-glass',
    color: '#9e9e9e',
  },
  [ActionType.ToggleIsUsingPartyMode]: {
    name: 'fa fa-wine-glass',
    color: '#9e9e9e',
  },
  ClickOnExceededNutritionalDeclaration: {
    name: 'fa fa-magic',
    color: '#ff0000',
  },
  GenerateNutritionalDeclaration: {
    name: 'fa fa-magic',
    color: '#bb00bb',
  },
  GenerateDeclaration: {
    name: 'fa fa-magic',
    color: '#bb00bb',
  },
}

export default function UserActivity() {
  const [query, setQuery] = useState('')
  const [logs, setLogs] = useState<ActionLog[]>([])
  const { list: users } = useSelector((state: State) => state.users)

  useEffect(() => {
    if (!query || query.length > 3) {
      getLogs()
    }
  }, [query])

  const getLogs = async () => {
    const actionLogs = await actionLogEndpoints.getAll(query)
    setLogs(actionLogs.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)))
  }

  const filteredLogs = logs.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp)) as (ActionLog & LogUiProps)[]

  return (
    <>
      <Wrapper>
        <div className={'top-bar'}>
          <SearchInput
            placeholder="Caută client"
            value={query}
            onChange={e => {
              setQuery(e.target.value)
            }}
          />
        </div>

        {getGroupedData(filteredLogs, users).map(userLog => (
          <div className="panel log-group" key={userLog.id}>
            <div className="field">
              <div
                className="user-info"
                title={`Vezi acțiunile lui ${userLog.username}`}
                onClick={() => setQuery(userLog.username)}
              >
                {!userLog.isVisitor && (
                  <div className="logo-wrapper">
                    <img
                      src={userLog.user?.logoUrl || 'logo.png'}
                      loading="lazy"
                      style={{ transform: `scale(${userLog.user?.logoUrl ? userLog.user?.styling?.logoScale || 1 : 0.8})` }}
                    />
                  </div>
                )}
                <div className="username">
                  {userLog.username}{userLog.user ? ` (${userLog.user?.city})` : ''}
                </div>
              </div>
              <div className="actions">
                {userLog.actions
                  .filter(x => x.repetitionCount)
                  .map(action => (
                    <div className="action" key={action.timestamp + ''}>
                      <div className="action-date-type">
                        {action.repetitionCount > 1 && action.minDate.fromNow(true) !== action.maxDate.fromNow(true) ? (
                          <span className="duration">
                            <span></span>
                            <b>{action.minDate.format('HH:mm')}</b>
                          </span>
                        ) : (
                          <span className="duration">
                            <b>{action.maxDate.format(query ? 'D/M - HH:mm' : 'HH:mm')}</b>
                            <b className="seconds">{action.maxDate.format(':ss')}</b>
                          </span>
                        )}
                        {action.repetitionCount > 1 && <CountTag>{action.repetitionCount}x</CountTag>}
                        {action.type in ActionIcons && (
                          <i
                            style={{ color: (ActionIcons as any)[action.type].color }}
                            className={(ActionIcons as any)[action.type].name}
                          ></i>
                        )}
                      </div>
                      <div className="action-info">
                        <div>
                          {action.type === ActionType.CreateAccount && <span style={{ marginRight: 6 }}>🎉</span>}
                          <div className="action-type">
                            {action.type} {action.subActions.length < 2 && formatActionData(action)}
                          </div>
                        </div>
                        {action.subActions.length >= 2 && (
                          <div className="sub-actions">{action.subActions.map(formatActionData)}</div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </Wrapper>
    </>
  )
}

function formatActionData(action: ActionLog) {
  if (action.type === ActionType.CreateProduct || action.type === ActionType.EditProduct) {
    const data = action.actionData as MenuProduct & { imageSize: number }
    const imageSizeInMb = (data.imageSize / 1000 / 1000).toFixed(2) + 'MB'

    return (
      <div className="action-data">
        <div className="small-bullet" style={data.imageSize ? { background: '#ff5723' } : {}}></div>
        {data.imageSize && <img loading="lazy" src={data.imageUrl} />}
        {`${data.imageSize ? `[${imageSizeInMb}] ` : ''}"${data.names['ro']}"`}
      </div>
    )
  }
  //@ts-ignore
  if (action.type === 'GenerateDeclaration') {
    const data = action.actionData as { id: string; name: string; description: string }

    return (
      <div className="action-data">
        <div className="small-bullet"></div>
        {`[${data.name}] - ${data.description.slice(0, 255)}`}
      </div>
    )
  }

  return null
}

function getGroupedData(rawLogs: (ActionLog & LogUiProps)[], users: User[]) {
  const groupedLogs: { id: string; user: User; username: string; isVisitor: boolean; actions: (ActionLog & LogUiProps)[] }[] = []

  const logs = [...JSON.parse(JSON.stringify(rawLogs))] as (ActionLog & LogUiProps)[]

  logs.forEach((userLog, i) => {
    if (userLog.userCounted || userLog.actionCounted) {
      return
    }
    userLog.userCounted = true
    const firstNonUserActionIndex = logs.slice(i).findIndex(x => x.username !== userLog.username)
    const allContiguousUserLogs = logs.slice(i, firstNonUserActionIndex === -1 ? undefined : i + firstNonUserActionIndex)

    allContiguousUserLogs.forEach((actionLog, j) => {
      if (actionLog.actionCounted) {
        return
      }

      actionLog.actionCounted = true

      const firstDifferentIndex = allContiguousUserLogs.slice(j).findIndex(x => x.type !== actionLog.type)
      const allContiguousActionLogs = allContiguousUserLogs.slice(
        j,
        firstDifferentIndex === -1 ? undefined : j + firstDifferentIndex
      )

      allContiguousActionLogs.forEach(q => {
        q.actionCounted = true
      })

      actionLog.repetitionCount = allContiguousActionLogs.length
      actionLog.subActions = allContiguousActionLogs

      actionLog.maxDate = moment(actionLog.timestamp)
      if (allContiguousActionLogs.length > 1) {
        actionLog.minDate = moment(allContiguousActionLogs[allContiguousActionLogs.length - 1].timestamp)
      }
    })

    groupedLogs.push({
      id: (userLog as any)._id,
      username: userLog.username,
      isVisitor: userLog.isVisitor || false,
      user: users.find(x => x._id === userLog.userId)!,
      actions: allContiguousUserLogs,
    })
  })

  return groupedLogs
}
