import { css, keyframes } from 'styled-components'

export const smallFont = css`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`

export const wideFont = css`
  ${smallFont};
  font-weight: 700;
  letter-spacing: 0.05em;
`

const fadeKeyframes = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const fade = css`
  animation: ${fadeKeyframes} 0.25s;
`

export const transition = (...props: any) => {
  let str = 'transition: '
  props.forEach((item: any, index: any) => {
    str = str.concat(`${item} 0.1s ease${index === props.length - 1 ? ';' : ', '}`)
  })
  return str
}

export const headerItem = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 16px;
`
