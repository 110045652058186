import React, { useEffect } from 'react'
import Button from 'src/global/Button'
import { Wrapper } from './styles'
import { businessOrderEndpoints } from 'src/api'
import useForm from 'src/helpers/useForm'
import { useDispatch, useSelector } from 'react-redux'
import { addToOrderIntent } from 'src/state/business-orders'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { State } from 'src/state'
import { ShippingInfo } from '@meniudigital/shared'

export default function EnterShippingInfo() {
  const dispatch = useDispatch()
  const { register, setValue, formValues, isValid } = useForm()
  const navigate = useNavigate()

  const { orderIntent } = useSelector((state: State) => state.businessOrders)

  const submitForm = async () => {
    const shippingInfo = {
      address: formValues['address'].value,
      city: formValues['city'].value,
      county: formValues['county'].value,
      country: 'România',
      name: formValues['name'].value,
      phoneNumber: formValues['phoneNumber'].value,
      zipCode: formValues['zipCode'].value,
      extraInfo: formValues['extraInfo'].value,
    }

    dispatch(addToOrderIntent({ shippingInfo }))
    navigate(Screen.ChooseBillingType)
  }

  useEffect(() => {
    if (orderIntent?.shippingInfo) {
      autoFillWith(orderIntent.shippingInfo)
    } else {
      autoFillFromLastOrderIfExists()
    }
  }, [])

  const autoFillWith = async (shippingInfo: ShippingInfo) => {
    Object.keys(shippingInfo).forEach(key => setValue(key, (shippingInfo as any)[key]))
  }

  const autoFillFromLastOrderIfExists = async () => {
    const mostRecentOrder = await businessOrderEndpoints.getMostRecent()
    if (mostRecentOrder?.shippingInfo) {
      autoFillWith(mostRecentOrder.shippingInfo)
    }
  }

  return (
    <Wrapper>
      <div className="page">
        <h3>Unde dorești să livrăm produsele?</h3>

        <form>
          <label aria-required={true}>Adresă</label>
          <input placeholder="Stradă, număr/bloc, apartament, etc." {...register('address')} />

          <label aria-required={true}>Oraș, sector</label>
          <input placeholder="Oraș" {...register('city')} />

          <label aria-required={true}>Județ</label>
          <input placeholder="Județ" {...register('county')} />

          <label aria-required={true}>Cod poștal</label>
          <input placeholder="Cod poștal" {...register('zipCode')} />

          <label aria-required={true}>Nume persoană de contact</label>
          <input placeholder="Persoană de contact" {...register('name')} />

          <label aria-required={true}>Număr de telefon</label>
          <input placeholder="Număr de telefon" {...register('phoneNumber')} />

          <label>
            Extra informații <span style={{ fontWeight: 500 }}>(opțional)</span>
          </label>
          <input placeholder="e.g. Restaurant Flamingo" {...register('extraInfo', true)} />
        </form>
        <Button onClick={submitForm} className="continue-button" text="Continuă" icon={faArrowRight} disabled={!isValid} />
      </div>
    </Wrapper>
  )
}
