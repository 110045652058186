import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }

  .quantity {
    font-weight: 600;
    color: ${props => props.theme.accent};
  }

  .delay-warning {
    color: ${props => props.theme.orange};
    font-weight: 500;
    justify-content: flex-start;
    padding-top: 0;

    i {
      margin-right: 8px;
    }
  }

  &.compact {
    .delay-warning {
      display: none;
    }
  }

  .product-edit-button {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.accent};
    }
  }
`
