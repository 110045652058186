import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Wrapper } from './styles'

type Props = {
  icon?: IconProp
  iconInline?: IconProp
  iconName?: string
  iconNameInline?: string
  responsive?: boolean
  text: string
  imageSrc?: string
  downloadUrl?: string
  downloadName?: string
  disabled?: boolean
  isLoading?: boolean
  variant?: 'primary' | 'secondary' | 'as-text' | 'as-text-danger'
} & any

export default function Button({
  icon,
  iconInline,
  text,
  imageSrc,
  downloadUrl,
  variant,
  downloadName,
  iconName,
  iconNameInline,
  responsive,
  circular,
  ...props
}: Props) {
  return (
    <Wrapper
      title={text}
      type="button"
      {...props}
      disabled={props.disabled || props.isLoading}
      data-responsive={responsive}
      data-circular={circular}
      data-variant={variant}
    >
      {imageSrc && <img alt="Button icon" className="image-icon" src={imageSrc} />}
      {icon && (
        <div className="icon-wrapper">
          <FontAwesomeIcon className="icon" icon={icon} />
        </div>
      )}
      {iconName && (
        <div className="icon-wrapper" style={{ fontSize: '14px', paddingTop: '1px' }}>
          <i className={`fa fa-${iconName}`} />
        </div>
      )}
      {iconInline && (
        <div className="icon-wrapper-inline">
          <FontAwesomeIcon className="icon" icon={iconInline} />
        </div>
      )}
      {iconNameInline && (
        <div className="icon-wrapper-inline">
          <i className={`fa fa-${iconNameInline}`} />
        </div>
      )}
      {props.isLoading && <div className="loader"></div>}
      {downloadUrl ? (
        <a
          href={downloadUrl}
          download={downloadName}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: '-15px -25px',
            padding: '15px 25px',
            marginLeft: '-35px',
            paddingLeft: '42px',
            display: 'inline-block',
            textDecoration: 'none',
          }}
        >
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </Wrapper>
  )
}
