import React, { useEffect, useState } from 'react'
import { Backdrop, Modal, Header, ModalContent, ModalText, ModalFooter } from './styles'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from 'src/global/Button'
import CloseButton from 'src/global/CloseButton'

export const defaultOptions = {
  isOpen: false,
  title: '',
  text: '',
  textComponent: null,
  confirmButtonText: '',
  cancelButtonText: '',
  isConfirm: false,
  width: 400,
  noFooter: false,
}

let resolveHandler: (value?: any) => void
let rejectHandler: (reason?: any) => void

export default function ConfirmationModal() {
  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    window.showConfirm = (openOptions: typeof defaultOptions) =>
      //@ts-ignore
      open({ title: 'Info', confirmButtonText: 'Ok', text: options.text, isConfirm: true, ...openOptions })
    window.showModal = open
    window.hideModal = close
  }, [])

  const open = ({
    text,
    textComponent,
    title,
    confirmButtonText = 'Șterge',
    cancelButtonText = 'Anulează',
    isConfirm = false,
    width = 400,
    noFooter = false,
  }: typeof defaultOptions) =>
    new Promise<void>((resolve, reject) => {
      resolveHandler = resolve
      rejectHandler = reject
      setOptions({ isOpen: true, text, textComponent, title, confirmButtonText, cancelButtonText, isConfirm, width, noFooter })
      document.querySelector('body')!.style.overflowY = 'hidden'
    })

  const confirm = () => {
    //@ts-ignore
    setOptions({ ...options, isOpen: false })
    document.querySelector('body')!.style.overflowY = 'scroll'
    setTimeout(() => {
      resolveHandler()
    }, 0)
  }

  const close = () => {
    //@ts-ignore
    setOptions({ ...options, isOpen: false })
    document.querySelector('body')!.style.overflowY = 'scroll'
    if (!options.isConfirm) {
      setTimeout(() => {
        rejectHandler('confirmationModalCancelled')
      }, 0)
    }
  }

  const { isOpen, title, text, textComponent, cancelButtonText, confirmButtonText, isConfirm, width, noFooter } = options

  return (
    <>
      <Backdrop className={isOpen ? 'open' : 'closed'} />
      <Modal style={{ width: `${width || 400}px` }} className={isOpen ? 'open' : 'closed'}>
        <Header>
          {title}
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <ModalText>
            {textComponent || <span dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br/>') }}></span>}
          </ModalText>
        </ModalContent>
        {!noFooter && (
          <ModalFooter>
            {!isConfirm && <Button variant="as-text" onClick={close} text={cancelButtonText} />}
            <Button onClick={confirm} text={confirmButtonText} icon={faCheck} />
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}
