import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px 0 34px 0;
    width: 720px;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    @media (max-width: 768px) {
      width: min(1024px, calc(100vw - 32px)) !important;
      margin-right: 0;
    }
  }

  .hovering {
    background: #fffc;
    border-radius: 16px;
    padding: 16px;
    position: relative;
    z-index: 2;
    box-shadow: 4px 4px 12px #0004;
  }

  .log-group {
    justify-content: flex-start;
    flex-direction: row;
    padding: 8px;
    width: min(720px, calc(100vw - (16px * 2)));
    margin-bottom: 16px;
    align-items: stretch;
    position: relative;
    box-shadow: 4px 0 12px ${props => props.theme.shadow};
    overflow: hidden;
    max-width: 1400px;

    .user-info {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background: ${props => props.theme.accent}44;
      }

      .logo-wrapper {
        width: 40px;
        height: 40px;
        overflow: hidden;
        z-index: 10;
        padding: 4px;
        border: 1px solid #fff;
        border-radius: 100%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fffb;

        img {
          width: 100%;
        }
      }

      .username {
        font-weight: 600;
        margin-left: 8px;
      }
    }

    .actions {
      padding: 8px;
      padding-left: 16px;
      font-size: 14px;

      .action {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;

        .action-date-type {
          display: flex;
          align-items: center;
        }

        .action-info {
          padding-top: 3px;
          border: 1px soid red;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        i {
          font-size: 16px;
          width: 18px;
          text-align: center;
          margin-right: 4px;
        }
      }

      .duration {
        margin-right: 6px;
        border-radius: 20px;
        padding: 2px 8px;
        background: ${props => props.theme.mutedText}bb;
        color: #fff;
      }
    }

    .seconds {
      display: none;
    }

    .action-type {
      display: flex;
      font-weight: 600;
      /* color: ${props => props.theme.mutedText}; */
    }

    .sub-actions {
      display: block;
      padding-left: 16px;
      font-weight: 600;
      margin-top: 4px;
      display: block;
    }

    .action-data {
      display: flex;
      align-items: center;
      margin-left: 8px;
      font-weight: 700;

      img {
        height: 20px;
        margin-right: 4px;
      }

      .small-bullet {
        background: ${props => props.theme.accent};
        margin-right: 4px;
        border-radius: 100;
        width: 12px;
        height: 3px;
        border-radius: 4px;
      }
    }

    &:hover {
      .seconds {
        display: inline;
      }
    }
  }
`
