import styled from 'styled-components/macro'
//@ts-ignore

export const Wrapper = styled.div`
  position: relative;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  height: 38px;
  cursor: pointer;
  position: relative;
  width: min(350px, 100vw - 64px);
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
  background: ${props => props.theme.pageBackground};
  z-index: 1;
  overflow: hidden;
  background: ${props => props.theme.iosDarkGray};

  .active-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.white};
    height: calc(100% - 4px);
    width: calc(50% - 2px);
    border-radius: 6px;
    transition: transform 0.5s cubic-bezier(0.25, 1, 0.32, 1), border-radius 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    box-shadow: 0px 0 12px ${props => props.theme.middleShadow};

    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;
  }

  .option {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    color: ${props => props.theme.disabledOnGrey};
    font-weight: 600;

    svg {
      margin-right: 4px;
      color: ${props => props.theme.green};
      margin-bottom: -1px;
      font-size: 16px;
      overflow: hidden;
      width: 0px;
      transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    }

    &.active {
      color: ${props => props.theme.normalText};

      svg {
        width: 20px;
      }
    }
  }
`
