import React from 'react'
import { Wrapper } from './styles'
import Button from 'src/global/Button'
import * as fuzzball from 'fuzzball'
import getGenericImageUrl from 'src/helpers/getGenericImageUrl'

type Props = {
  query: string
  onSelectPress: (imageName: string) => void
}

export default function ImageSuggestionBox(props: Props) {
  const { query, onSelectPress } = props

  if (query.length < 5) {
    return null
  }

  const imageMatch = findImageMatch(query)

  if (!imageMatch || imageMatch.score < 70) {
    return null
  }

  return (
    <Wrapper>
      <span>Imagine sugerată</span>
      <img src={getGenericImageUrl(imageMatch.imageName)} alt="Suggest product thumbnail" />
      <Button variant="as-text" text="Folosește" onClick={() => onSelectPress(imageMatch.imageName)} />
    </Wrapper>
  )
}

function findImageMatch(query: string): { score: number; imageName: string } | undefined {
  const normalizedInput = query
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^(\w|\s)]/g, '')
  let bestMatch = undefined
  let highestScore = 0

  genericImages.forEach(imageName => {
    const normalizedName = imageName
      .replace(/md-generic/g, '')
      .replace(/-/g, ' ')
      .replace(/.jpeg/g, '')
      .toLowerCase()
    const score = fuzzball.token_set_ratio(normalizedInput, normalizedName)

    if (score > highestScore) {
      highestScore = score
      bestMatch = { score, imageName }
    }
  })

  return bestMatch
}

const genericImages = [
  'md-generic-7up-250ml.jpeg',
  'md-generic-acqua-panna-1l.jpeg',
  'md-generic-aloe-vera-500.jpeg',
  'md-generic-aqua-carpatica-330.jpeg',
  'md-generic-aqua-carpatica-500.jpeg',
  'md-generic-bira-moretti-033.jpeg',
  'md-generic-birra-moretti-zero-330.jpeg',
  'md-generic-bundaberg-375.jpeg',
  'md-generic-cappy-250.jpeg',
  'md-generic-cappy-nectar-330ml.jpeg',
  'md-generic-cappy-pere-250ml.jpeg',
  'md-generic-cappy-portocala-250ml.jpeg',
  'md-generic-cappy-visine-250ml.jpeg',
  'md-generic-carlsberg-330.jpeg',
  'md-generic-ciuc-330.jpeg',
  'md-generic-ciuc-radler-330ml.jpeg',
  'md-generic-coca-cola-330ml.jpeg',
  'md-generic-coca-cola-zero-330ml.jpeg',
  'md-generic-corona-355.jpeg',
  'md-generic-desperados-330ml.jpeg',
  'md-generic-dorna-minerala-500ml.jpeg',
  'md-generic-dorna-plata-500ml.jpeg',
  'md-generic-edelweiss-500ml.jpeg',
  'md-generic-fanta-250ml.jpeg',
  'md-generic-fritz-kola-330.jpeg',
  'md-generic-fuze-tea-500.jpeg',
  'md-generic-fuzetea-fructe-500ml.jpeg',
  'md-generic-heineken-330ml.jpeg',
  'md-generic-heineken-fara-alcool-330.jpeg',
  'md-generic-kozel-dark-330.jpeg',
  'md-generic-kozel-premium-500.jpeg',
  'md-generic-maioneza.jpeg',
  'md-generic-mellow-orange-330.jpeg',
  'md-generic-mellow-pineapple-330.jpeg',
  'md-generic-mirinda-250ml.jpeg',
  'md-generic-mustar.jpeg',
  'md-generic-paulaner-500ml.jpeg',
  'md-generic-pepsi-max-250ml.jpeg',
  'md-generic-pepsi-twist-250ml.jpeg',
  'md-generic-peroni-330.jpeg',
  'md-generic-peroni-fara-alcool-330.jpeg',
  'md-generic-prigat-250ml.jpeg',
  'md-generic-red-bull.jpeg',
  'md-generic-san-pellegrino-1l.jpeg',
  'md-generic-scheppes-tonic-250ml.jpeg',
  'md-generic-schweppes-250.jpeg',
  'md-generic-schweppes-mandarin-250ml.jpeg',
  'md-generic-schweppes-pink-250ml.jpeg',
  'md-generic-silva-blonda-330.jpeg',
  'md-generic-silva-neagra-330.jpeg',
  'md-generic-smantana.jpeg',
  'md-generic-somersby-afine-330.jpeg',
  'md-generic-sos-bbq.jpeg',
  'md-generic-sos-caesar.jpeg',
  'md-generic-sos-de-rosii-dulce.jpeg',
  'md-generic-sos-rosii-picant.jpeg',
  'md-generic-sos-sweet-chilli.jpeg',
  'md-generic-sos-usturoi.jpeg',
  'md-generic-sprite-250ml.jpeg',
  'md-generic-strongbow-330.jpeg',
  'md-generic-strongbow-apple-330.jpeg',
  'md-generic-tuborg-330.jpeg',
  'md-generic-tuborg-fara-alcool-330.jpeg',
  'md-generic-ursus-fara-alcool-330.jpeg',
  'md-generic-ursus-premium-330.jpeg',
  'md-generic-ursus-retro-330.jpeg',
  'md-generic-ursus-cooler-lamaie-330.jpeg',
  'md-generic-apa-tonica-evervess.jpeg',
  'md-generic-aperol-spritz.jpeg',
  'md-generic-caffe-latte.jpeg',
  'md-generic-cappuccino.jpeg',
  'md-generic-caramel.jpeg',
  'md-generic-ceai-musetel.jpeg',
  'md-generic-ceai-verde.jpeg',
  'md-generic-ceai.jpeg',
  'md-generic-chifla-susan.jpeg',
  'md-generic-chifla.jpeg',
  'md-generic-ciocolata-calda.jpeg',
  'md-generic-cosmopolitan.jpeg',
  'md-generic-frappe-cu-caramel-si-frisca.jpeg',
  'md-generic-fresh-portocale.jpeg',
  'md-generic-gin-and-tonic.jpeg',
  'md-generic-ice-coffee.jpeg',
  'md-generic-latte-macchiato.jpeg',
  'md-generic-limonada.jpeg',
  'md-generic-lipton-green.jpeg',
  'md-generic-lipton-ice-tea-piersica.jpeg',
  'md-generic-lipton-ice-tea.jpeg',
  'md-generic-lipton-piersici-250ml.jpeg',
  'md-generic-matcha-latte.jpeg',
  'md-generic-miere.jpeg',
  'md-generic-milkshake-strawberry-smoothie.jpeg',
  'md-generic-mojito.jpeg',
  'md-generic-monster-energy-500ml.jpeg',
  'md-generic-mountain-dew-250ml.jpeg',
  'md-generic-mountain-dew-500ml.jpeg',
  'md-generic-negroni.jpeg',
  'md-generic-paine-alba.jpeg',
  'md-generic-paine-integrala.jpeg',
  'md-generic-pepsi-vintage-250ml.jpeg',
  'md-generic-pina-colada.jpeg',
  'md-generic-prigat-capsuni-banane-250ml.jpeg',
  'md-generic-prigat-kiwi-250ml.jpeg',
  'md-generic-prigat-pere-250ml.jpeg',
  'md-generic-prigat-piersici-250ml.jpeg',
  'md-generic-prosecco.jpeg',
  'md-generic-red-bull-pepene-rosu.jpeg',
  'md-generic-red-bull-sugar-free.jpeg',
  'md-generic-socata.jpeg',
  'md-generic-tequila-cocktail-sunrise.jpeg',
]
