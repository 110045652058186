import { faEnvelope, faLock, faLockOpen, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import { MessengerChat } from 'react-messenger-chat-plugin'
import Button from 'src/global/Button'
import { isBusiness } from '@meniudigital/shared'
import { usersSelector } from 'src/state/selectors'
import { useSelector } from 'react-redux'

export default function Contact() {
  const [showsChat, setShowsChat] = useState(false)
  const [isOpeningChat, setIsOpeningChat] = useState(false)
  const { user } = useSelector(usersSelector)

  useEffect(() => {
    try {
      const { fbq } = window as any
      fbq?.('track', 'Contact')
    } catch (e) {}
  }, [])

  const openChat = () => {
    setShowsChat(true)
    setIsOpeningChat(true)
    setTimeout(() => {
      setIsOpeningChat(false)
    }, 5000)
  }

  return (
    <Wrapper>
      <div className="page">
        <img src="/logo.svg" alt="MeniuDigital logo" width="40px" style={{ marginBottom: '32px' }} />
        <h1>Contact</h1>
        <h2>Dorești să discuți cu noi?</h2>
        <span>Alege una dintre modalitățile de contact:</span>
        <br />
        <Button
          text="Facebook Messenger Chat"
          isLoading={isOpeningChat}
          className={isOpeningChat ? 'loading' : ''}
          iconName="facebook-messenger"
          onClick={openChat}
        />
        <br />
        <br />
        <span>
          <FontAwesomeIcon className="icon" color="#11b7f3" icon={faEnvelope} /> E-mail (recomandat):
        </span>
        <b style={{ marginTop: 8 }}>contact@meniudigital.ro</b>
        <br />
        <br />
        <span>
          <FontAwesomeIcon className="icon" color="#11b7f3" icon={faPhone} /> Telefon:
        </span>
        <div className="business-button" onClick={() => !isBusiness(user) && window.openBusinessModal?.('phoneNumber')}>
          <img src="/logo-white.svg" />
          {isBusiness(user) ? (
            <a href="tel:+40774566954">+40774566954</a>
          ) : (
            <div className="brand-texts">
              <div className="brand-name">MeniuDigital</div>
              Business
            </div>
          )}

          <div className="icon-wrapper">
            <FontAwesomeIcon className="icon" icon={isBusiness(user) ? faLockOpen : faLock} />
          </div>
        </div>
        <span>
          <i>Orar: L-V 10:00-18:00</i>
        </span>
        <br />
        <br />
        <br />
        <a href="https://www.meniudigital.ro">https://www.meniudigital.ro</a>
        <br />
      </div>

      {showsChat && (
        <MessengerChat
          pageId="109730878277243"
          language="ro-RO"
          loggedInGreeting="Bună, cu ce te putem ajuta?"
          loggedOutGreeting="Bună, cu ce te putem ajuta?"
          greetingDialogDisplay="show"
          greetingDialogDelay={1}
          themeColor={'#11b7f3'}
          debugMode={process.env.REACT_APP_ENVIRONMENT !== 'production'}
        />
      )}
    </Wrapper>
  )
}
