import React, { useRef } from 'react'

import { Bar, getElementAtEvent } from 'react-chartjs-2'
import { NavigationStatsObject } from 'src/api'

export default function VerticalBarVisitsByUser({ data }: { data: NavigationStatsObject['topMenuVisitsByUserLast7Days'] }) {
  const chartRef = useRef()

  return (
    <div className="long-container">
      <Bar
        ref={chartRef}
        width="3000px"
        height={window.innerHeight / 3}
        onClick={e => {
          const { index } = getElementAtEvent(chartRef.current!, e)[0]
          const username = data[index].label
          window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${username}`)
        }}
        data={{
          labels: data.map(x => x.label),
          datasets: [
            {
              label: 'Unique Users',
              data: data.map(x => x.uniqueUserCount),
              backgroundColor: '#003c89',
            },
            {
              label: 'Visits',
              data: data.map(x => x.visitCount),
              backgroundColor: '#11b7f3',
            },
          ],
        }}
        options={{
          responsive: false,
          plugins: {
            legend: {
              position: 'top' as const,
              align: 'start',
            },
            title: {
              position: 'top',
              align: 'start',
              display: true,
              text: `Top restaurant visits - last 7 days`,
            },
          },
        }}
      />
    </div>
  )
}
