import styled from 'styled-components/macro'
//@ts-ignore

export const Wrapper = styled.div`
  position: relative;

  .rail {
    border-radius: 8px;
    display: flex;
    height: 45px;
    cursor: pointer;
    position: relative;
    width: 96px;
    align-items: center;
    justify-content: space-around;
    transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
    border: 2px solid ${props => props.theme.white};
    background: ${props => props.theme.pageBackground};
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 0px 8px ${props => props.theme.border};
    max-width: 52vw;

    :hover {
      box-shadow: 0 0px 8px ${props => props.theme.darkerShadow};
    }
  }

  .active-indicator {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.white};
    height: 100%;
    border-radius: 4px;
    transition: transform 0.5s cubic-bezier(0.25, 1, 0.32, 1), border-radius 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    position: absolute;
    top: 0;
    right: -1px;
    z-index: -1;
  }

  .currency-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.theme.mutedText};
    flex: 1;
    height: 100%;

    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    &.active {
      font-weight: 700;
      color: ${props => props.theme.normalText};
    }

    :hover {
      background: ${props => props.theme.white}88;
    }
  }
`
