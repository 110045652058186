import styled from 'styled-components/macro'
//@ts-ignore

export const Wrapper = styled.div`
  padding-bottom: 16px;

  .product-wrapper {
    padding: 4px 0;

    &.last-in-category {
      padding-bottom: 8px;
    }

    > div:hover {
      box-shadow: 0 2px 12px #0002;
    }
  }

  .expanding-div {
    display: flex;
    overflow: hidden;
    transition: width 0.35s ${props => props.theme.easing};
    margin-right: -8px;
  }
`
