import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js'
import { featureVotesEndpoints, FeatureVoteStat, navigationLogsEndpoints, NavigationStatsObject } from 'src/api'
import VerticalBarVisitsByUser from './VerticalBarVisitsByUser'
import TotalVisitsPerDay from './TotalVisitsPerDay'
import UserSummary from './UserSummary'
import VerticalBarFeatureVotes from './VerticalBarFeatureVotes'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

export default function StatsScreen() {
  const [navigationStats, setNavigationStats] = useState<NavigationStatsObject>()
  const [featureVoteStats, setFeatureVoteStats] = useState<FeatureVoteStat[]>([])

  useEffect(() => {
    getNavigationStats()
    getFeatureVotes()
  }, [])

  const getNavigationStats = async () => {
    const navStats = await navigationLogsEndpoints.getAll()
    setNavigationStats(navStats)
  }

  const getFeatureVotes = async () => {
    const featureVoteStatsResponse = await featureVotesEndpoints.getFeatureVoteStats()
    setFeatureVoteStats(featureVoteStatsResponse)
  }

  if (!navigationStats) {
    return null
  }

  return (
    <Wrapper>
      <div className="chart-wrapper">
        <UserSummary data={navigationStats?.userSummary} />
      </div>
      <div className="chart-wrapper">
        <VerticalBarVisitsByUser data={navigationStats?.topMenuVisitsByUserLast7Days} />
      </div>
      <div className="chart-wrapper">
        <TotalVisitsPerDay data={navigationStats?.totalVisitsPerDay} />
      </div>
      <div className="chart-wrapper">
        <VerticalBarFeatureVotes title="Feature Votes" logs={featureVoteStats} />
      </div>
    </Wrapper>
  )
}
