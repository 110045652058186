import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import { categoriesSelector, usersSelector } from 'src/state/selectors'
import { useSelector } from 'react-redux'
import { isBusiness } from '@meniudigital/shared'
import { IS_DESKTOP_OR_TABLET } from '..'

type Option = { value: string; label: string }

type Props = {
  value: string
  options: Option[]
  onChange: (newValue: string) => void
  stacked?: boolean
}

export default function SectionPicker(props: Props) {
  const { options, onChange } = props
  const [localValue, setLocalValue] = useState<string>(props.value)

  const { user } = useSelector(usersSelector)

  const { list: categories } = useSelector(categoriesSelector)

  const isInline = !props.stacked && (IS_DESKTOP_OR_TABLET || options.length <= 3)

  const entireToggleWidth = Math.min(window.innerWidth - (options.length === 3 ? 0 : 28), Math.min(174 * options.length, 768))

  const indicatorWidth = (entireToggleWidth - 4) / (isInline ? options.length : 2)

  const selectedValue = props.value || localValue

  const selectValue = (value: string) => {
    if (value === selectedValue && options.length === 2) {
      toggle()
      return
    }
    setLocalValue(value)
    onChange?.(value)
  }

  const toggle = () => {
    const otherValue = options.find(x => x.value !== selectedValue)!
    setLocalValue(otherValue.value)
    onChange?.(otherValue.value)
  }

  useEffect(() => {
    const doesSelectedValueStillExist = categories.find(x => x.type === selectedValue)
    if (!doesSelectedValueStillExist && props.options[0]) {
      selectValue(props.options[0].value)
    }
  }, [categories.map(x => x.type).toString()])

  const activeIndex = options.findIndex(x => x.value === selectedValue)

  const translateXPercentage = 100 * (activeIndex % (isInline ? options.length : 2))
  const currentRow = Math.floor(activeIndex / 2)

  const translateY = isInline ? '' : `, calc(${100 * currentRow}%)`

  const shouldCompress = !IS_DESKTOP_OR_TABLET && options.length === 3

  return (
    <Wrapper
      className={`section-toggle ${isInline ? 'inline' : ''} ${options.length > 3 ? 'with-four-items' : ''} ${
        isBusiness(user) ? 'for-business' : ''
      }`}
      style={shouldCompress ? { borderRadius: 0 } : {}}
    >
      <div
        className="active-indicator"
        style={{
          transform: `translate(${translateXPercentage}%${translateY})`,
          width: isInline ? indicatorWidth : 'calc(50% - 2px)',
        }}
      />

      {options.map(x => (
        <div
          onClick={() => selectValue(x.value)}
          key={x.value + ''}
          className={'option' + (selectedValue === x.value ? ' active' : '')}
          style={{ width: isInline ? indicatorWidth : '50%' }}
        >
          <FontAwesomeIcon icon={faCheckCircle} />
          {shouldCompress ? x.label.replace('Meniu ', '') : x.label}
        </div>
      ))}

      <div onClick={() => window.openBusinessModal('extraMenus')} className="business-area">
        <img src="/logo-white.svg" />
      </div>
    </Wrapper>
  )
}
