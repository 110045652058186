import React from 'react'
import { Screen } from 'src/screens'
import { Wrapper } from './styles'
import { useSelector } from 'react-redux'
import { categoriesSelector, productsSelector, themeSelector, usersSelector } from 'src/state/selectors'
import Button from 'src/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { MenuSection, isBusiness } from '@meniudigital/shared'
import { useNavigate } from 'react-router-dom'
import { SECTION_OPTIONS } from '../HomeScreen/CategoryModal'
import SubscriptionInfo from '../HomeScreen/BusinessModal/SubscriptionDuration'
import { State } from 'src/state'

const barLength = Math.min(850 - (64 + 16), window.innerWidth - 64)

export default function MySubscriptionScreen() {
  const navigate = useNavigate()

  const { list: categories } = useSelector(categoriesSelector)
  const { list: products } = useSelector(productsSelector)
  const { user } = useSelector(usersSelector)
  const { isDark } = useSelector(themeSelector)

  const numberOfMenus = SECTION_OPTIONS.filter(x => categories.find(y => (y.type || MenuSection.Restaurant) === x.value)).length
  const numberOfLanguages = user?.menuLanguages?.length || 0
  const imageSize = isBusiness(user) ? '4K' : '600x600'
  const menuSpeed = isBusiness(user) ? '~300ms' : 'standard'
  const backupPeriod = isBusiness(user) ? 'zilnic' : 'lunar'

  const { list: businessOrderList } = useSelector((state: State) => state.businessOrders)

  console.log('user.quotas?.nutritionalGenerations ', user?.quotas?.nutritionalGenerations)

  if (!products || !categories || !user) {
    return null
  }

  return (
    <Wrapper>
      <div className={'page' + (isBusiness(user) ? ' is-business' : '')}>
        <div className="section-wrapper">
          <label>Abonamentul meu</label>

          <div className="header">
            <div onClick={() => window.openBusinessModal()} className="subscription-info">
              <img
                src={isDark || isBusiness(user) ? '/logo-white.svg' : '/logo.svg'}
                alt="MeniuDigital Logo"
                className="logo-img"
              />
              <div>
                <div className="brand-name">MeniuDigital</div>
                <div className="subscription-name">{isBusiness(user) ? 'Business' : 'Gratuit'} </div>
              </div>
            </div>
            <div className="price-and-button">
              <div className="subscription-price">{isBusiness(user) ? '100' : '0'} RON / lună</div>
              {isBusiness(user) ? <SubscriptionInfo /> : <Button onClick={() => window.openBusinessModal()} text="Schimbă..." />}
            </div>
          </div>
        </div>

        {businessOrderList?.length > 0 && (
          <div className="orders-button-wrapper">
            <Button variant="as-text" text="Vezi istoricul plăților..." onClick={() => navigate(Screen.BusinessOrders)} />
          </div>
        )}

        <div className="section-wrapper">
          <label>Beneficiile tale</label>
          <div className="stats">
            <div className="stat comparison">
              <div className="stat-progress-bar-track">
                <div className="free-gradient">
                  <img src={isDark ? '/logo-white.svg' : '/logo.svg'} />
                  <span className="subscription-brand">
                    <div className="subscription-meniudigital">MeniuDigital</div>
                    <span className="subscription-name-text">
                      Gratuit {!isBusiness(user) && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 14 }} />}
                    </span>
                  </span>
                </div>
                <div className="vs-text">vs</div>
                <div className="business" onClick={() => window.openBusinessModal()}>
                  <img src="/logo-white.svg" />
                  <span className="subscription-brand">
                    <div className="subscription-meniudigital">MeniuDigital</div>
                    <span className="subscription-name-text">Business</span>
                  </span>
                  {isBusiness(user) && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 14 }} />}
                </div>
              </div>
            </div>

            <Stat
              label="✨ Generări nutriționale"
              value={user.quotas?.nutritionalGenerations || 0}
              freeLimit={7}
              businessLimit={<span className="infinity-symbol">∞</span>}
            />

            <Stat
              label="🍔 Produse"
              value={products.length}
              freeLimit={300}
              businessLimit={<span className="infinity-symbol">∞</span>}
            />

            <Stat label="📚 Meniuri" value={numberOfMenus} freeLimit={2} businessLimit="4" />

            <Stat label="🌎 Limbi" value={numberOfLanguages} freeLimit={2} businessLimit="5" />

            <Stat label="🖼️ Mărime imagini" value={imageSize} freeLimit="" businessLimit="4K" />

            <Stat label="⚡ Viteză meniu" value={menuSpeed} freeLimit="" businessLimit="~300ms" noMax />

            <Stat label="🛡️ Backup meniu" value={backupPeriod} freeLimit="" businessLimit="zilnic" noMax />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

type StatProps = {
  label: string
  value: number | string
  freeLimit: number | string
  businessLimit: string | React.ReactElement
  noMax?: boolean
}

const LIMIT_WIDTH_PERCENTAGE = 35

const Stat = ({ label, value, freeLimit, businessLimit, noMax }: StatProps) => {
  const limitWidth = (barLength / 100) * LIMIT_WIDTH_PERCENTAGE

  let valueWidth =
    typeof freeLimit === 'string' || typeof value === 'string'
      ? limitWidth
      : (barLength / 100) * LIMIT_WIDTH_PERCENTAGE * (value / freeLimit)

  if (value.toString() === businessLimit) {
    valueWidth = barLength
  }

  return (
    <div className="stat">
      <span className="stat-name">{label}</span>
      <div className="stat-progress-bar-track">
        <div className="stat-progress-bar-fill" style={{ width: valueWidth }}>
          <div className="value">
            {value.toString() === businessLimit ? 'Max: ' : ''}
            {value}
          </div>
        </div>
        <div className="stat-progress-bar-limit" style={{ width: limitWidth }}>
          <div className="limit-dotted-line">
            <span>
              Max{freeLimit ? ': ' : ''}
              {freeLimit}
            </span>
          </div>
        </div>
        <div className="business" onClick={() => window.openBusinessModal()}>
          <span className="limit-text">
            {!noMax && 'Max: '}
            <span className="value">{businessLimit}</span>
          </span>
        </div>
      </div>
    </div>
  )
}
