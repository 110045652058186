import styled from 'styled-components/macro'
import Textarea from 'src/global/form/Textarea'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;
  width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);

  &.small {
    width: 108px !important;
  }

  &.full-width {
    width: 100%;

    textarea {
      width: 100%;
      height: 74px;
    }
  }

  @media (max-width: 768px) {
    /* width: 100% !important; */

    /* &.small {
      margin-right: 16px !important;
    } */
    /* margin-left: 0px !important; */
  }

  .input-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;

    input {
      width: 100%;
    }
  }

  input {
    padding-right: 8px;
  }

  &.with-suffix input {
    padding-right: 40px;
  }

  &.with-right-button input,
  &.with-right-button textarea {
    padding-right: 140px;

    @media (max-width: 768px) {
      padding-right: 60px;
    }
  }

  &.disabled {
    pointer-events: none;

    input {
      opacity: 0.7;
      background: ${props => props.theme.accent}44;
      border-color: ${props => props.theme.accent};
    }
  }

  &.required input {
    border-color: ${props => props.theme.accent};
    border-width: 2px;
  }

  &.invalid input {
    border-color: ${props => props.theme.red};
    border-width: 2px;
  }

  .suffix {
    position: absolute;
    right: 12px;
    top: 14px;
    z-index: 15;
    font-size: 14px;
    pointer-events: none;
    color: ${props => props.theme.placeholder};
    height: 30px;
    display: flex;
    align-items: center;

    &.for-category {
      top: 44px;
    }
  }
`

export const FormTextarea = styled(Textarea)`
  width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);
  max-width: calc(100vw - (32px * 2));
  height: 41px;
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 8px;
  padding-right: 40px;

  resize: none;
  height: 160px;
`
