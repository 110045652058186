import styled from 'styled-components/macro'
//@ts-ignore

export const Wrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 8px;
  z-index: 100;
  overflow: hidden;
  --input-width: 400px;

  @media (max-width: 720px) {
    --input-width: 50vw;
  }

  :hover {
    input {
      border-color: ${props => props.theme.accent};
    }
  }

  .close-button {
    position: absolute;
    top: 15px;
    left: calc(var(--input-width) - 50px);
  }

  .search-input {
    margin-top: 0;
    border-radius: 8px;
    background-color: ${props => props.theme.white};
    transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1), border-radius 1s cubic-bezier(0.25, 1, 0.32, 1) 0.3s;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    padding: 0;
    border-color: ${props => props.theme.white};
    cursor: pointer;
    box-shadow: none!important;

    ::placeholder {
      color: ${props => props.theme.white};
      opacity: 0;
    }

    &:not([value='']),
    &:focus {
      transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1) 0.05s, border-radius 0.1s cubic-bezier(0.25, 1, 0.32, 1);
      width: var(--input-width);
      border-radius: 8px;
      padding-left: 40px;
      cursor: text;

      ::placeholder {
        color: ${props => props.theme.placeholder};
        opacity: 1;
      }
    }
  }

  svg {
    pointer-events: none;
    position: absolute;
    top: 13.5px;
    left: 13.5px;
  }
`
