import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useSelector } from 'react-redux'
import { Backdrop } from '../../ProductModal/styles'
import { LanguageCode, MenuProduct } from '@meniudigital/shared'
import { productsSelector } from 'src/state/selectors'
import Product from './Product'
import { State } from 'src/state'

type Props = {
  onProductPick: (product: MenuProduct) => void
}

export default function ProductPicker({ onProductPick }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState<string>('')

  const { list: products } = useSelector(productsSelector)
  const { user } = useSelector((state: State) => state.users)

  useEffect(() => {
    window['openProductPicker'] = open
  }, [])

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const pickProduct = (product: MenuProduct) => {
    close()
    onProductPick(product)
  }

  const sortProducts = (a: MenuProduct, b: MenuProduct) => a.index - b.index
  const filterProducts = (x: MenuProduct) =>
    x.names[LanguageCode.Romanian]!.toLowerCase()
      .normalize('NFKD')
      .replace(/[^\w]/g, '')
      .includes(query.toLowerCase().normalize('NFKD').replace(/[^\w]/g, ''))

  const getProducts = () => products.filter(filterProducts).sort(sortProducts).slice(0, 5)

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Alege produs
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div className="question" style={{ marginTop: 0 }}>
            Ce produs dorești să evidențiezi la deschiderea meniului?
          </div>

          <input
            title="Caută produs..."
            className="search-input"
            placeholder="Caută produs..."
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ width: '100%' }}
          />

          {getProducts().map(product => (
            <Product
              key={product.id}
              query={query}
              product={product}
              user={user!}
              languageCode={LanguageCode.Romanian}
              inPartyMode={Boolean(user?.inPartyMode)}
              // @ts-ignore
              onClick={() => pickProduct(product)}
            />
          ))}
        </ModalContent>
      </Modal>
    </>
  )
}
