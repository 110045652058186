import { Bar } from 'react-chartjs-2'
import { FeatureVoteStat } from 'src/api'

type Props = {
  logs: FeatureVoteStat[]
  title: string
}

export default function VerticalBarFeatureVotes({ logs, title }: Props) {
  const data = logs.sort((a, b) => b.value - a.value)

  return (
    <Bar
      width={window.innerWidth - 120}
      height="300px"
      data={{
        labels: data.map(stat => stat.label),
        datasets: [
          {
            label: '# of votes',
            data: data.map(stat => stat.value),
            backgroundColor: '#32bd98',
          },
        ],
      }}
      options={{
        responsive: false,
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'start',
          },
          title: {
            position: 'top',
            align: 'start',
            display: true,
            text: title,
          },
        },
      }}
    />
  )
}
