import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px 0 24px 0;
    width: 720px;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    @media (max-width: 768px) {
      width: min(1024px, calc(100vw - 32px)) !important;
      margin-right: 0;
    }
  }

  .action-list {
    padding: 16px;
    display: flex;
    column-gap: 24px;
    row-gap: 50px;
    flex-wrap: wrap;
  }
`
