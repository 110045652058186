import React from 'react'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './state/index'
import { createRoot } from 'react-dom/client'
import './styles.css'

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://fcfd4785ce9e4e8287582a40a5475aaf@o717444.ingest.sentry.io/5779914',
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new CaptureConsole({ levels: ['error'] })],
  })
}

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>,
//   document.getElementById('root')
// )

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

serviceWorker.unregister()
