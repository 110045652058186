import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .panel {
    margin-top: 16px;
    padding: 0px;
  }

  .day {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid ${props => props.theme.border};

    &:last-of-type {
      border-bottom: none;
    }

    &:nth-of-type(even) {
      background-color: ${props => props.theme.activeBackground};
    }
  }

  h3 {
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0 24px;

    @media (max-width: 768px) {
      padding: 0;
      max-width: calc(100vw - 32px);
    }
  }

  .week-day {
    text-transform: capitalize;
    font-weight: 600;
    width: 100px;
  }

  .label-text {
    text-transform: capitalize;
    font-weight: 600;
  }

  
  .slider {
    width: 100%;
    height: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #cdd9ed;
  }

  .slider .thumb {
    width: 23px;
    height: 23px;
    cursor: pointer;
    background: ${props => props.theme.blue};
    border-radius: 50%;
    top: -10px;
  }
  .slider .active {
    border: none;
    outline: none;
  }

  button {
    margin: 0 16px;
    margin-bottom: 24px;
    padding: 0 16px;
    width: 160px;

    &[data-variant='as-text'] {
      width: auto;
    }
  }

  button.continue-button {
    margin-top: 24px;

    @media (max-height: 1100px) {
      position: fixed;
      bottom: 16px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 250;
      width: 150px;
      justify-content: center;
      box-shadow: 0 0px 4px #454f5b44;
    }
  }
`
