import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { faArrowRight, faCalendarAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
import { AVAILABLE_SUBSCRIPTIONS, Subscription, SubscriptionCode } from '@meniudigital/shared'
import smoothscroll from 'smoothscroll-polyfill'
import { Screen } from 'src/screens'
import Button from 'src/global/Button'
import { addToOrderIntent, clearOrderIntent } from 'src/state/business-orders'
import { userEndpoints } from 'src/api'
smoothscroll.polyfill()
SwiperCore.use([Autoplay, Navigation])

export default function ChooseSubscription() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedSubscriptionCode, setSelectedSubscriptionCode] = useState<SubscriptionCode | undefined>(undefined)

  useEffect(() => {
    dispatch(clearOrderIntent())

    try {
      const { fbq } = window as any
      fbq?.('track', 'AddToCart')
    } catch (e) {}
  }, [])

  const chooseSubscription = async (code: SubscriptionCode) => {
    setSelectedSubscriptionCode(code)
    setTimeout(() => {
      window.scrollTo({ top: window.outerHeight + 100, behavior: 'smooth' })
    }, 300)
  }

  const goToNextPage = () => {
    setTimeout(() => {
      userEndpoints.registerUserAction({ actionType: 'HasChosenSubscription', data: { selectedSubscriptionCode } })
    }, 0)

    try {
      const selectedPrice = AVAILABLE_SUBSCRIPTIONS.find(x => x.code === selectedSubscriptionCode)!.price

      const { fbq } = window as any
      fbq?.('track', 'InitiateCheckout', {
        currency: 'RON',
        value: selectedPrice,
        content_ids: [selectedSubscriptionCode],
        content_name: selectedSubscriptionCode,
        content_type: 'product',
        contents: [{ id: selectedSubscriptionCode, quantity: 1 }],
        num_items: 1,
      })
    } catch (e) {}

    dispatch(addToOrderIntent({ subscriptionCode: selectedSubscriptionCode }))
    navigate(Screen.ChooseBillingType)
  }

  const availableSubscriptions = AVAILABLE_SUBSCRIPTIONS.filter(x => !x.isLegacy) as Subscription[]

  return (
    <Wrapper>
      <div className={'page ' + (selectedSubscriptionCode ? 'any-selected' : '')}>
        <h3>Ce perioadă de facturare preferi?</h3>

        {availableSubscriptions.map(({ code, price, priceReducedFrom, priceReductionPercentage, name, durationInDays }) => (
          <div
            key={code}
            onClick={() => chooseSubscription(code)}
            className={'subscription' + (selectedSubscriptionCode === code ? ' selected' : '')}
          >
            <div className="price">
              <div className="price-inner">
                <div style={{ marginBottom: -3 }}>
                  <span
                    style={{
                      fontSize: code === SubscriptionCode.MDBUSINESS12 ? 16 : 15,
                    }}
                    className="old-price"
                  >
                    {priceReducedFrom!.toLocaleString('ro-RO')}
                  </span>
                </div>
                <div style={{ fontSize: code === SubscriptionCode.MDBUSINESS12 ? 21 : 22 }}>{price.toLocaleString('ro-RO')}</div>
                <div className="currency">RON</div>
              </div>
            </div>
            <div>
              <div className="name">{name}</div>
              <div className="code">{code}</div>
              <div className="duration">
                <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
                {durationInDays} de zile
              </div>
            </div>

            <div className="selected-indicator">
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <div className="discount-tag">{`-${priceReductionPercentage}%`}</div>
          </div>
        ))}
        <Button
          disabled={!selectedSubscriptionCode}
          className="continue-button"
          text="Continuă"
          onClick={goToNextPage}
          icon={faArrowRight}
        />
      </div>
    </Wrapper>
  )
}
