type Environment = 'dev' | 'staging' | 'production'

export default function getGenericImageUrl(imageName: string | undefined) {
  if (!imageName) {
    return ''
  }

  const s3BucketPrefix = {
    dev: 'https://touchfreemenu-staging.s3.eu-central-1.amazonaws.com',
    staging: 'https://touchfreemenu-staging.s3.eu-central-1.amazonaws.com',
    production: 'https://touchfreemenu.s3.eu-central-1.amazonaws.com',
  } as Record<Environment, string>

  const prefix = s3BucketPrefix[(process.env.REACT_APP_ENVIRONMENT || 'production') as Environment]

  return `${prefix}/generic-product-images/${imageName}`
}
