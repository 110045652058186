import { MenuProduct } from '@meniudigital/shared'
import { EmptyProductProps } from './types'

export const generateEmptyProduct = (props?: EmptyProductProps) => {
  const emptyProduct: MenuProduct & { genericImageName?: string } = {
    id: Math.random() * 100 + 'randomIdBeforeCreation',
    userId: '',
    index: props?.index || 0,
    names: { ro: '' },
    descriptions: { ro: '' },
    imageUrl: '',
    imageKey: '',
    quantities: props?.quantities || '',
    kcalories: props?.kcalories || '',
    // @ts-ignore
    price: props?.price || '',
    isAvailable: true,
    isDiscounted: false,
    categoryId: props?.categoryId || '',
    allergens: [],
    nutritionalDeclaration: '',
    childProducts: [],
    parentProductId: '',
    genericImageName: '',
  }

  return emptyProduct
}

export function isWhitePixel(x: number, y: number, data: Uint8ClampedArray, imageWidth: number) {
  const red = data[(imageWidth * y + x) * 4]
  const green = data[(imageWidth * y + x) * 4 + 1]
  const blue = data[(imageWidth * y + x) * 4 + 2]
  const alpha = data[(imageWidth * y + x) * 4 + 3]

  if (alpha < 5) {
    return true
  }

  const minValueForWhite = 245
  const currentWhiteValue = (red + green + blue) / 3

  if (currentWhiteValue >= minValueForWhite) {
    return true
  }

  return false
}

export function hasWhiteBackground(image: HTMLImageElement): Promise<boolean> {
  return new Promise(async (resolve, _) => {
    const imageObj = new Image()
    imageObj.onload = function () {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')!
      const imageWidth = imageObj.width
      const imageHeight = imageObj.height
      canvas.width = imageWidth
      canvas.height = imageHeight

      context.drawImage(imageObj, 0, 0)

      const imageData = context.getImageData(0, 0, imageWidth, imageHeight)

      // To ignore white border
      const cornerOffset = 13

      const whitePoints = [
        // Corners
        isWhitePixel(cornerOffset, cornerOffset, imageData.data, imageWidth),
        isWhitePixel(imageWidth - cornerOffset - 1, cornerOffset, imageData.data, imageWidth),
        isWhitePixel(cornerOffset, imageHeight - 1, imageData.data, imageWidth),
        isWhitePixel(imageWidth - cornerOffset - 1, imageHeight - cornerOffset - 1, imageData.data, imageWidth),

        // Middle points
        // isBasicallyWhite(imageWidth / 2, 0, imageData.data, imageWidth),
        // isBasicallyWhite(0, imageHeight / 2, imageData.data, imageWidth),
        isWhitePixel(imageWidth - 1, imageHeight / 2, imageData.data, imageWidth),
        isWhitePixel(imageWidth / 2, imageHeight - 1, imageData.data, imageWidth),
      ]

      const hasWhitePadding = whitePoints.filter(x => !x).length <= 2

      resolve(hasWhitePadding)
    }
    imageObj.crossOrigin = 'Anonymous'
    imageObj.src = image.src
  })
}
