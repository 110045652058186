import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { consumerOrderEndpoints } from 'src/api'

type ApiResponseList = Awaited<ReturnType<typeof consumerOrderEndpoints.getAll>>

export type State = {
  isLoading: boolean
  list: ApiResponseList
}

export const initialState: State = {
  list: [] as ApiResponseList,
  isLoading: false,
}

export const getConsumerOrders = createAsyncThunk('consumerOorders/getOrders', consumerOrderEndpoints.getAll)

const slice = createSlice({
  name: 'consumerOrders',
  initialState,
  reducers: {},
  extraReducers: actions => {
    actions.addCase(getConsumerOrders.pending, state => {
      state.isLoading = true
    })
    // Fulfilled
    actions.addCase(getConsumerOrders.fulfilled, (state, { payload }: PayloadAction<ApiResponseList>) => {
      state.isLoading = false
      state.list = [...payload].sort((a, b) => (+new Date(b.createdAt || '') > +new Date(a.createdAt || '') ? 1 : -1))
    })
  },
})

export default slice.reducer
