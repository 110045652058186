import React from 'react'
import styled from 'styled-components/macro'
import { LanguageCode, LocalLanguageName } from '@meniudigital/shared'

const Wrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  display: flex;

  img {
    width: 20px;
  }
`

export default function Flag({ of }: { of: LanguageCode }) {
  return (
    <Wrapper className="wrapper">
      <img title={LocalLanguageName[of]} src={`flags/${of}.png`} alt={`Flag of ${of}`} />
    </Wrapper>
  )
}
