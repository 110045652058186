import React from 'react'
import styled from 'styled-components/macro'
import { headerItem } from 'src/global/helpers'
import { useSelector } from 'react-redux'
import { State } from 'src/state'
import { Screen } from '../../screens'

const Wrapper = styled.a`
  ${headerItem};

  margin-right: auto;
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.theme.normalText};
  text-decoration: none;

  @media (max-width: 768px) {
    padding: 0 8px 0 24px;
    font-size: 19px;
  }

  .brand-name {
    margin-left: 12px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .logo-img {
    height: 28px;
    margin-left: -8px;
  }
`

export default function Logo(props: { title: string }) {
  const { isDark } = useSelector((state: State) => state.theme)

  const isNotLive = window.location.host !== 'admin.meniudigital.ro'
  const isNotStaging = window.location.host !== 'staging-admin.meniudigital.ro'
  const isOnLogin = window.location.pathname === Screen.Login

  return (
    <Wrapper href={isOnLogin ? (process.env.REACT_APP_MENU_BASE_URL as string) : '/'}>
      <img
        src={isNotLive ? (isNotStaging ? '/logo-green.svg': '/logo-orange.svg') : (isDark ? '/logo-white.svg' : '/logo.svg')}
        alt="MeniuDigital Logo"
        className="logo-img"
      />
      <div className="brand-name">
        <span className="hidden-phone" style={{ fontWeight: '400' }}>
          {' '}
          MeniuDigital /{' '}
        </span>{' '}
        {props.title || 'Admin'}
      </div>
    </Wrapper>
  )
}
