import styled from 'styled-components/macro'
//@ts-ignore

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
  z-index: 1;
  overflow: hidden;
  background: ${props => props.theme.inputBackground};
  border: 1px solid ${props => props.theme.border};
  justify-content: flex-start;
  padding: 2px;

  &.inline {
    flex-wrap: nowrap;
    background: ${props => props.theme.white};

    &.with-four-items {
      margin-left: -54px; // break through the padding to be full width
    }

    .option {
      height: 36px;

      @media (hover: hover) {
        :hover:not(.active) {
          background: ${props => props.theme.disabledButton}33;
          box-shadow: none;
        }
      }
    }

    .active-indicator {
      height: 36px;
    }
  }

  &.for-business {
    .active-indicator {
      background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
    }
  }

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.disabledOnGrey};
    font-weight: 600;
    height: 44px;
    border-radius: 6px;
    white-space: nowrap;
    transition: all 0.1s cubic-bezier(0.25, 1, 0.32, 1);

    /* border: 1px solid red; */

    @media (hover: hover) {
      :hover:not(.active) {
        background: ${props => props.theme.white};
        box-shadow: 0px 0 12px ${props => props.theme.shadow};
      }
    }

    svg {
      margin-right: 4px;
      color: ${props => props.theme.white};
      margin-bottom: -1px;
      font-size: 16px;
      overflow: hidden;
      width: 0px;
      transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    }

    &.active {
      color: ${props => props.theme.white};
      font-weight: 700;

      svg {
        width: 20px;
      }
    }
  }

  .active-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.accent};
    height: 44px;
    border-radius: 6px;
    transition: transform 0.5s cubic-bezier(0.25, 1, 0.32, 1), border-radius 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    box-shadow: 0px 0 12px ${props => props.theme.middleShadow};

    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;
  }

  .business-area {
    position: absolute;
    top: 48px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 48px - 2px);
    border-radius: 6px;
    background: linear-gradient(73deg, hsla(210deg, 30%, 30%, 1), hsla(210deg, 30%, 45%, 1));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: opacity 0.2s ease-out;
    opacity: 0.1;

    img {
      width: 24px;
    }

    &:hover {
      opacity: 0.3;
    }
  }

  &.inline,
  &.for-business {
    .business-area {
      display: none;
    }
  }
`
