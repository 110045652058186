import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux'

import { Wrapper } from './styles'
import { usersSelector } from 'src/state/selectors'
import { isBusiness, UserPatchType } from '@meniudigital/shared'
import { patchCurrentUser } from 'src/state/users'
import Checkbox from 'src/global/Checkbox'
import { Screen } from '../../screens'
import { Link } from 'react-router-dom'
import { userEndpoints } from 'src/api'
import ContactSetting from './ContactSetting'
import { IS_DESKTOP_OR_TABLET } from '../HomeScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons'

export default function ReservationSettingsScreen() {
  const usersState = useSelector(usersSelector)
  const user = usersState.user!
  const dispatch = useDispatch()

  const { reservations, schedule } = user
  const { isEnabled } = reservations || {}
  const { shouldContactByEmail, shouldContactByPhoneNumber, contactEmail, contactPhoneNumber } = reservations || {}

  const isLive = Boolean(isEnabled)
  const [checklistValues, setChecklistValues] = useState([false, false, false, false])
  const [isShowingInfos, setIsShowingInfos] = useState(true)

  const menuUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user?.username}`
  const reservationsUrl = `${menuUrl}/rezervare`

  useEffect(() => {
    userEndpoints.registerUserAction({ actionType: 'ViewConsumerReservationSettings', data: {} })
  }, [])

  useEffect(() => {
    const isCurrentlyLive = Boolean(isEnabled)
    setChecklistValues([false, false, false, false].fill(isCurrentlyLive))
    setIsShowingInfos(!isCurrentlyLive)
  }, [user])

  if (!user) {
    return null
  }

  const copyReservationsLink = async () => {
    await navigator.clipboard?.writeText(reservationsUrl)
    window.showConfirm({
      text: ` Linkul către pagina ta de rezervări a fost copiat în clipboard.\n\n ${reservationsUrl}`,
    })
  }

  const isEverythingChecked = checklistValues.every(Boolean)

  const isDisabled = !isEverythingChecked

  const toggleOnlineReservations = async () => {
    if (!reservations?.isEnabled) {
      await window.showModal({
        title: `Activare funcționalitate`,
        text: `Ești sigur că dorești să activezi funcționalitatea de rezervări online?`,
        confirmButtonText: 'Da, activează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.ReservationSettings, data: { ...reservations, isEnabled: !isEnabled } }))
    } else {
      await window.showModal({
        title: `Dezactivare funcționalitate`,
        text: `Ești sigur că dorești să dezactivezi funcționalitatea de rezervări online?`,
        confirmButtonText: 'Da, dezactivează',
        cancelButtonText: 'Nu',
      })
      dispatch(patchCurrentUser({ type: UserPatchType.ReservationSettings, data: { ...reservations, isEnabled: !isEnabled } }))
    }
  }
  const toggleNotifyByEmail = async () => {
    dispatch(
      patchCurrentUser({
        type: UserPatchType.ReservationSettings,
        data: { ...reservations, shouldContactByEmail: !shouldContactByEmail },
      })
    )
  }

  const toggleNotifyByPhoneNumber = async () => {
    dispatch(
      patchCurrentUser({
        type: UserPatchType.ReservationSettings,
        data: { ...reservations, shouldContactByPhoneNumber: !shouldContactByPhoneNumber },
      })
    )
  }

  const setContactEmail = async (input: string) => {
    dispatch(
      patchCurrentUser({
        type: UserPatchType.ReservationSettings,
        data: { ...reservations, contactEmail: input },
      })
    )
  }

  const setContactPhoneNumber = async (input: string) => {
    dispatch(
      patchCurrentUser({
        type: UserPatchType.ReservationSettings,
        data: { ...reservations, contactPhoneNumber: input },
      })
    )
  }

  const checkList = [
    'Doresc să folosesc funcționalitatea',
    <span>
      {/* @ts-ignore */}
      Am achiziționat <Link onClick={() => window.openBusinessModal('reservations')}>Pachetul Business</Link>
    </span>,
    <span>
      <Link to={Screen.SetSchedule}>Programul de lucru</Link> este corect
    </span>,
    <span>
      <a href="#bottom">Metodele de contact</a> sunt corecte
    </span>,
  ]

  const toggleCheckbox = (index: number) => {
    if (index === 1 && !isBusiness(user)) {
      return
    }
    if (index === 2 && !schedule?.schedule.length) {
      return
    }
    const newCheckboxValues = [...checklistValues]
    const newValue = !newCheckboxValues[index]
    newCheckboxValues[index] = newValue

    if (!newValue) {
      newCheckboxValues.slice(index).forEach((x, i) => (newCheckboxValues[i + index] = false))
    }
    setChecklistValues(newCheckboxValues)
  }

  const toggleInfos = () => {
    setIsShowingInfos(!isShowingInfos)
  }

  const canContactInAnyWay = (shouldContactByEmail && contactEmail) || (shouldContactByPhoneNumber && contactPhoneNumber)

  return (
    <Wrapper>
      <div className="page">
        <div className="banner">
          <span>Rezervări Online prin Meniul Digital</span>
          {!isBusiness(user) && <div className="business-only-tag">Disponibil exclusiv clienților Business.</div>}
        </div>
        <div className={`settings `}>
          <div className={`module-overview ${isShowingInfos ? '' : 'collapsed'}`}>
            <div className="header" onClick={toggleInfos}>
              <h2 className="title">Cum funcționează?</h2>
            </div>
            <div className="collapsable">
              <div style={{ display: 'flex' }}>
                <div>
                  <ul className="info-blocks">
                    <li className="info-block">După deschiderea meniului, clienții tăi vor avea opțiunea de "Rezervă o masă" </li>
                    <li className="info-block">
                      Clientul va introduce numărul de persoane, data și ora la care dorește să facă rezervarea, iar apoi o va
                      trimite
                    </li>
                    <li className="info-block">Vei primi o notificare cu detaliile exacte ale rezervării pe e-mail și/sau SMS</li>
                    <li className="info-block">
                      După ce te decizi, vei putea aproba sau refuza rezervarea. Clientul va fi notificat prin mail și SMS.
                    </li>
                  </ul>

                  <div style={{ marginTop: 24 }}>
                    {checkList.map((checklistItem, index) => (
                      <Checkbox
                        key={index}
                        text={checklistItem}
                        isChecked={checklistValues[index]}
                        onChange={() => toggleCheckbox(index)}
                        disabled={(index > 0 && !checklistValues.slice(0, index).every(Boolean)) || isLive}
                      />
                    ))}
                  </div>
                </div>
                {IS_DESKTOP_OR_TABLET && (
                  <div className="images">
                    <img src="phone-reservation-small.png" alt="Reservation functionality on web menu interface" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`shadow-container ${isDisabled ? 'disabled' : ''}`}>
            <div className={`setting grouped ${isDisabled ? 'disabled' : ''}`}>
              <div className="setting-name">
                <img src="/icons/fork-clock-knife.png" alt="Reservation icon" />
                <span>Modul de Rezervări Online</span>
                {reservations?.isEnabled && !isDisabled && <div className="bullet" />}
              </div>
              <div className="right-side">
                <div className="react-toggle-wrapper">
                  <span className="only-desktop">
                    {isDisabled ? 'Indisponibil' : reservations?.isEnabled ? 'Activ' : 'Inactiv'}
                  </span>
                  <Toggle
                    className="toggle"
                    checked={!isDisabled && reservations?.isEnabled}
                    icons={false}
                    onChange={toggleOnlineReservations}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {reservations?.isEnabled ? (
          !canContactInAnyWay ? (
            <span className="warning">
              Butonul de rezervări online nu este afișat momentan pe meniu deoarece nu ai specificat o metodă de contact.
            </span>
          ) : !schedule?.schedule.length ? (
            <span className="warning">
              Butonul de rezervări online nu este afișat momentan pe meniu deoarece nu specificat{' '}
              <Link to={Screen.SetSchedule}>Programul tău de lucru</Link> .
            </span>
          ) : (
            <div className="active-summary">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} />
                Opțiunea de "Rezervă o masă" este acum vizibilă clienților pe meniu.
                <a href="#" onClick={() => window.open(reservationsUrl)}>
                  <FontAwesomeIcon icon={faExternalLink} />
                  Vezi pagina de rezervări
                </a>
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} />
                Poți copia linkul direct către pagina ta de rezervări pentru a-l distribui clienților.
                <a href="#" onClick={copyReservationsLink}>
                  <FontAwesomeIcon icon={faCopy} />
                  Copiază link
                </a>
              </div>
            </div>
          )
        ) : null}

        <div className="panel info" style={{ marginTop: '24px', padding: '24px 24px', textAlign: 'center' }}>
          <div className="contacts-wrapper">
            <h2 className="title" style={{ fontSize: '18px' }}>
              Cum dorești să fii înștiințat de noile rezervări?
            </h2>

            <ContactSetting
              regex={/\S+@\S+\.\S+/}
              hasInput={true}
              placeholder="Adresa ta de email"
              title="Email"
              onToggle={toggleNotifyByEmail}
              onBlur={setContactEmail}
              checked={shouldContactByEmail}
              initialValue={contactEmail}
            />
            <ContactSetting
              regex={/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g}
              hasInput={true}
              placeholder="Numărul tău de telefon"
              title="Telefon"
              onToggle={toggleNotifyByPhoneNumber}
              onBlur={setContactPhoneNumber}
              checked={shouldContactByPhoneNumber}
              initialValue={contactPhoneNumber}
            />
            <ContactSetting
              checked={false}
              onToggle={() => {}}
              onBlur={() => {}}
              hasInput={false}
              title="Notificări push (În curând)"
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <div id="bottom"></div>
    </Wrapper>
  )
}
