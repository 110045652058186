import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import Toggle from 'react-toggle'
import { LanguageCode, MenuSection, UserPatchType } from '@meniudigital/shared'
import { SECTION_OPTIONS } from '../CategoryModal'
import { categoriesSelector } from 'src/state/selectors'
import wait from 'src/helpers/wait'

export default function OfflineSetting() {
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)
  const { list: categories } = useSelector(categoriesSelector)
  const [isLoading, setIsLoading] = useState(false)

  if (!user) {
    return null
  }

  const cacheAllMenuVariations = async () => {
    const userSections = SECTION_OPTIONS.filter(x => categories.find(y => (y.type || MenuSection.Restaurant) === x.value))
    const userLanguages = user?.menuLanguages?.length ? user.menuLanguages : [LanguageCode.English, LanguageCode.Romanian]

    for (const section of userSections) {
      for (const languageCode of userLanguages) {
        const menuUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user.username}?s=${section.value}&l=${languageCode}`
        await fetch(menuUrl)
        await wait(200)
      }
    }
  }

  const toggleOfflineMode = async () => {
    try {
      if (!user.inOfflineMode) {
        await window.showModal({
          title: `Dezactivare actualizări automate`,
          text: `Ești sigur că dorești oprirea actualizărilor în timp real?\n\nAceastă acțiune va crea un snapshot care va fi servit către clienți până când actualizările automate vor fi repornite.`,
          confirmButtonText: 'Da, oprește',
          cancelButtonText: 'Nu',
        })
        setIsLoading(true)
        await cacheAllMenuVariations()
        setIsLoading(false)
        const payload = {
          type: UserPatchType.InOfflineMode,
          data: true,
        }
        await dispatch(patchCurrentUser(payload as any))
        await window.showConfirm({
          text: `Modificările în timp real au fost oprite. Un snapshot al meniului tău a fost salvat cu succes.`,
        })
      } else {
        await window.showModal({
          title: `Activare actualizări în timp real`,
          text: `Ești sigur că dorești repornirea actualizărilor în timp real?\n\nOdată repornite, nu vei putea reveni la snapshotul curent.`,
          confirmButtonText: 'Da, repornește',
          cancelButtonText: 'Nu',
        })
        const payload = {
          type: UserPatchType.InOfflineMode,
          data: false,
        }
        await dispatch(patchCurrentUser(payload as any))
        await window.showConfirm({
          text: `Modificările în timp real au fost repornite. `,
        })
      }
    } catch {}
  }

  return (
    <div className="grouped">
      <div className="setting-explainer" style={{ marginTop: 0 }}>
        Modul "Offline" îți permite să efectuezi modificări multiple meniului tău fără ca acestea să fie propagate către clienți.
        <br />
      </div>
      <div className="setting-explainer" style={{ marginTop: 0 }}>
        {user.inOfflineMode
          ? 'Dorești să repornești actualizările în timp real?'
          : 'Dorești să oprești actualizările în timp real și să activezi modul "Offline"?'}
      </div>
      <div className="setting">
        <div className="setting-name">
          <div className="setting-icon" style={user.inOfflineMode ? { filter: 'saturate(0)' } : {}}>
            {' '}
            <img src="/icons/realtime-updates.png" alt="Realtime updates icon" />
          </div>
          <span>Actualizări în timp real</span>
          {!user?.inOfflineMode && <div className="bullet" />}
        </div>
        {isLoading ? (
          <span>Se dezactivează...</span>
        ) : (
          <div className="right-side">
            <span className="only-desktop">{!user?.inOfflineMode ? 'Active' : 'Oprite'}</span>
            <Toggle className="toggle" checked={!user?.inOfflineMode} icons={false} onChange={toggleOfflineMode} />
          </div>
        )}
      </div>
    </div>
  )
}
