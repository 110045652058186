import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import ReactSlider from 'react-slider'

const Wrapper = styled(ReactSlider)`
  width: calc(100% - 64px);
  height: 25px;
  margin-left: 32px;
`

const StyledThumbWrapper = styled.div`
  padding: 15px;
  margin: -15px;
  cursor: ${props => (props.disabled ? `default` : 'grab')};
`

const StyledThumb = styled.div`
  height: 18px;
  width: 18px;
  line-height: 50px;
  background: ${props => props.theme.white};
  border-radius: 50%;
  border: 2px solid ${props => (props.disabled ? props.theme.disabledButton : `#11b7f3`)};
`

const StyledTrack = styled.div`
  top: 8px;
  bottom: 0;
  height: 2px;
  background: ${props => (props.disabled ? props.theme.disabledButton : `#11b7f3`)};
  border-radius: 999px;
`

export default function Slider(props: any) {
  return (
    <Wrapper
      {...props}
      renderTrack={(componentProps: any, state: any) => (
        <StyledTrack {...componentProps} disabled={props.disabled} {...state} index={state.index} />
      )}
      renderThumb={(componentProps: any) => (
        <StyledThumbWrapper {...componentProps} disabled={props.disabled}>
          <StyledThumb />
        </StyledThumbWrapper>
      )}
    />
  )
}
