import { createSlice } from '@reduxjs/toolkit'

export type State = {
  isDark: boolean
}

export const initialState = { isDark: false }

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleDarkTheme: state => {
      state.isDark = !state.isDark
    },
  },
})

export default slice.reducer
export const { toggleDarkTheme } = slice.actions
