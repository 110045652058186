import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);

  .label-text:before,
  a {
    color: ${props => props.theme.darkRed}!important;
  }

  .react-toggle.react-toggle--checked {
    .react-toggle-thumb {
      border-color: ${props => props.theme.darkRed}!important;
    }
    .react-toggle-track {
      background-color: ${props => props.theme.darkRed}!important;
    }
  }

  .business-check-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    font-weight: 500;

    svg {
      margin-right: 8px;
      font-size: 20px;
      color: ${props => props.theme.darkRed};
    }
  }

  .page {
    padding: 24px 16px;
    padding-bottom: 40px;
  }

  .active-summary {
    display: flex;
    gap: 8px;
    padding: 16px 8px;
    flex-direction: column;

    div {
      display: flex;

      svg {
        margin-right: 8px;
        color: ${props => props.theme.green};
      }

      a {
        padding-left: 4px;
        display: inline-block;
        margin-left: 4px;

        svg {
          color: ${props => props.theme.darkRed};
        }
      }
    }
  }

  .warning {
    color: ${props => props.theme.red};
    padding: 24px;
    padding-bottom: 0;
    font-weight: 500;
  }

  .contacts-wrapper {
    width: 100%;

    .toggle {
      margin-left: 8px;
    }
  }

  .banner {
    width: calc(100% - 2px);
    background: linear-gradient(
      115deg,
      ${props => props.theme.orange},
      ${props => props.theme.orange},
      ${props => props.theme.blue},
      ${props => props.theme.blue}
    );
    color: ${props => props.theme.white};
    background: linear-gradient(-90deg, rgb(135, 8, 36), rgb(167, 0, 38));
    padding: 50px 8px;
    font-weight: 700;
    font-size: 22px;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: -32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .business-only-tag {
      font-size: 12px;
      font-weight: 600;
      margin-top: 12px;
      background: ${props => props.theme.activeBackground};
      color: ${props => props.theme.darkRed};
      border-radius: 16px;
      padding: 2px 8px;
    }

    @media (max-width: 768px) {
      font-size: 18px;

      .business-only-tag {
        font-size: 11px;
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    text-align: left;
  }

  .panel {
    width: 100%;
  }

  p {
    padding: 8px 0;
    line-height: 22px;
    text-align: center;
  }

  .module-overview {
    background: ${props => props.theme.white};
    flex-direction: column;
    padding: 24px;
    padding-bottom: 24px;
    margin: 0 1px;

    .header {
      margin: -24px;
      padding: 24px;
      cursor: pointer;

      &:hover {
        background: ${props => props.theme.pageBackground}66;
      }
    }

    .collapsable {
      max-height: 1200px;
      transition: max-height 0.3s cubic-bezier(0.25, 1, 0.32, 1);
    }

    &.collapsed {
      cursor: pointer;

      .title {
        font-size: 16px;
      }

      .images {
        opacity: 0;
      }

      .collapsable {
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  .shadow-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 0 30px ${props => props.theme.middleShadow};
    background: ${props => props.theme.white};

    &.disabled {
      box-shadow: inset 0 4px 12px ${props => props.theme.shadow};
      background: ${props => props.theme.activeBackground};
    }
  }

  .setting {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0px 12px ${props => props.theme.mutedText}44;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1);
    box-shadow: none;
    /* padding: 16px 8px; */
    border: 1px solid ${props => props.theme.border};
    width: 100%;

    input {
      padding-right: 12px;
    }
    &.grouped {
      border-radius: 0;
      border-top-width: 0;

      &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    &.disabled {
      img {
        filter: saturate(0.6);
      }

      .right-side {
        cursor: not-allowed;
      }

      .react-toggle-wrapper {
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .setting-name {
      display: inline-flex;
      align-items: center;

      img {
        height: 22px;
        margin-right: 8px;
      }
    }
  }

  .background-image {
    width: 300px;
    height: 300px;
    position: absolute;
    opacity: 0.6;
    left: 0px;
    bottom: -50px;
    width: 200px;
    height: 200px;

    img {
      /* transform: rotate(-20deg); */
      width: 100%;
    }
  }

  .react-toggle-wrapper {
    margin-top: 0;
  }

  .images {
    width: 200px;
    flex-shrink: 0;
    margin-right: 0px;
    margin-bottom: -8px;
    margin-left: 16px;
    font-size: 0;
    margin-top: -30px;
    transition: all 0.3s ease-in-out;
    gap: 8px;
    display: flex;
    flex-direction: column;
    margin-left: -16px;
    margin-right: -24px;

    img {
      width: 100%;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
      border-radius: 8px;

      &:hover {
        position: relative;
        z-index: 100;
        transform: scale(2.5) translateX(-30px) translateY(12%);
        border-radius: 4px;

        &:nth-of-type(3) {
          transform: scale(5) translateX(-30px) translateY(-30%);
        }

        &:nth-of-type(4) {
          transform: scale(4) translateX(-30px) translateY(-30%);
        }
      }
    }
  }

  .info-blocks {
    margin-top: 8px;
    padding-left: 16px;

    .info-block {
      padding: 12px 0;
      font-size: 16px;
      line-height: 20px;

      &:last-of-type {
        padding-bottom: 0;
      }

      .qr-button {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        box-shadow: 0 2px 12px #0002;
        padding: 2px 12px;
        border-radius: 8px;
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;

        :hover {
          background-color: ${props => props.theme.activeBackground};
        }

        .icon {
          margin-left: 4px;
        }
      }
    }
  }

  .toggle,
  .bullet {
    margin-left: 8px;
  }

  .device {
    box-shadow: 0 4px 12px ${props => props.theme.shadow};
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 10px 16px;
    margin: 0 8px;
    border: 1px solid ${props => props.theme.border};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    font-weight: 500;
    color: ${props => props.theme.normalText};
    margin-top: 16px;
    display: flex;
    align-items: center;
    width: min(400px, 100vw - 70px);
    justify-content: space-between;
    position: relative;

    button {
      margin-top: 0;
      padding: 0px;
    }

    .notifications-badge {
      height: 21px;
      width: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      border-radius: 24px;
      position: absolute;
      left: -12px;
      margin: auto;
      top: 0;
      bottom: 0;

      i {
        color: ${props => props.theme.white};
        font-size: 12px;
      }

      &.red {
        background: ${props => props.theme.red};
      }

      &.green {
        background: ${props => props.theme.green};
      }
    }

    .device-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 24px;

      .waiter-name {
        font-weight: 700;
        text-align: left;

        .notifications-off {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          vertical-align: text-top;
          color: ${props => props.theme.red};
        }
      }

      .device-type {
        text-transform: capitalize;
        margin-top: 4px;
        text-align: left;

        img {
          height: 16px;
          margin-right: 5px;
          float: left;
        }
      }
    }
  }
`
