import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 6px 10px;
  box-shadow: 0 0px 12px ${props => props.theme.darkerShadow};
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    color: ${props => props.theme.accent};
  }

  img {
    width: 155px;
    border-radius: 10px;
    margin-top: 4px;
    padding: 4px;
  }

  button {
    margin-top: 4px;
    height: 24px;
    padding: 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;

    img {
      width: 110px;
    }
  }
`
