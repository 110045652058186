import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { categoryEndpoints } from 'src/api'
import { MenuCategory } from '@meniudigital/shared'

export type State = {
  list: MenuCategory[]
  isLoading: boolean
}

export const initialState: State = {
  list: [],
  isLoading: false,
}

export const createCategory = createAsyncThunk('categories/createCategory', categoryEndpoints.create)
export const updateCategory = createAsyncThunk('categories/updateCategory', categoryEndpoints.update)
export const getCategories = createAsyncThunk('categories/getCategories', categoryEndpoints.getAll)
export const moveCategory = createAsyncThunk('categories/moveCategory', categoryEndpoints.move)
export const removeCategory = createAsyncThunk('categories/removeCategory', categoryEndpoints.remove)

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: actions => {
    // PENDINGS & REJECTEDS
    ;[createCategory, updateCategory, getCategories, removeCategory].forEach(reducerAction => {
      actions.addCase(reducerAction.pending, (state: State) => {
        state.isLoading = true
      })
      actions.addCase(reducerAction.rejected, (state: State) => {
        state.isLoading = false
      })
    })

    actions.addCase(moveCategory.pending, (state, action: any) => {
      const list = [...state.list.sort((a, b) => a.index - b.index)]
      const { categoryId, destinationIndex } = action.meta.arg

      const draggedItem = list.find(x => x.id === categoryId)!
      const listWithoutItem = list.filter(x => x.id !== categoryId)

      const newList = [
        ...listWithoutItem.slice(0, destinationIndex - 1),
        draggedItem,
        ...listWithoutItem.slice(destinationIndex - 1),
      ]

      newList.forEach((x, i) => {
        x.index = i + 1
      })

      state.list = newList
    })
    actions.addCase(createCategory.fulfilled, (state: State, { payload }: PayloadAction<MenuCategory>) => {
      state.isLoading = false
      state.list = [...state.list, payload]
    })
    actions.addCase(updateCategory.fulfilled, (state, { payload }: PayloadAction<MenuCategory>) => {
      state.isLoading = false
      state.list = [...state.list.filter(x => x.id !== payload.id), payload]
    })
    actions.addCase(getCategories.fulfilled, (state, { payload }: PayloadAction<MenuCategory[]>) => {
      state.isLoading = false
      state.list = payload
    })
    actions.addCase(moveCategory.fulfilled, (state, { payload }: PayloadAction<MenuCategory[]>) => {
      state.isLoading = false
      const newCategoriesFromCategory = payload
      const updatedList = [...state.list].map(category => {
        const newCategory = newCategoriesFromCategory.find(x => x.id === category.id)
        if (newCategory) {
          return {
            ...category,
            index: newCategory.index,
          }
        } else {
          return { ...category }
        }
      })

      state.list = updatedList
    })
    actions.addCase(removeCategory.fulfilled, (state, { payload }: PayloadAction<MenuCategory>) => {
      state.isLoading = false
      state.list = [...state.list.filter(x => x.id !== payload.id)]
    })
  },
})

export default slice.reducer
