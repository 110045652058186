type Environment = 'dev' | 'staging' | 'production'

export default function setEnvironmentLogo() {
  const logoPaths = {
    dev: '/logo-green.svg',
    staging: '/logo-orange.svg',
    production: '/logo.svg',
  } as Record<Environment, string>

  document
    .querySelector('#faviconSvg')
    ?.setAttribute('href', logoPaths[(process.env.REACT_APP_ENVIRONMENT || 'production') as Environment])
}
