import React from 'react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import HTMLAttributes from '../../../../../index' // eslint-disable-line @typescript-eslint/no-unused-vars

import { Swiper as SwiperWithNoRef, SwiperSlide } from 'swiper/react'

import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { AVAILABLE_HOLDERS, HolderCode } from '@meniudigital/shared'
import { Wrapper } from './styles'

SwiperCore.use([Autoplay, Navigation])

const Swiper: React.FunctionComponent<any> = SwiperWithNoRef

SwiperCore.use([Autoplay, Navigation])
type ProductImage = {
  url: string
  isLandscape?: boolean
  marginLeft?: number
}

type ProductProps = {
  images: ProductImage[]
  code: HolderCode
  stock: number
  count: number
  setCount: (count: number) => void
}

export default function Product({ code, count, images, stock, setCount }: ProductProps) {
  const { name, price, priceIncreaseSegment: packSize = 1 } = AVAILABLE_HOLDERS.find(x => x.code === code)!

  return (
    <Wrapper>
      <div className="header">
        <Swiper className="swiper" speed={3500} centeredSlides={true} autoplay={{ delay: 6000, disableOnInteraction: false }}>
          {images.map(({ url, isLandscape, marginLeft = 0 }, i) => (
            <SwiperSlide key={url} className={isLandscape ? 'to-right' : ''}>
              {marginLeft ? (
                <div style={{ overflow: 'hidden' }}>
                  <img alt={'Sticker Holder ' + i} src={url} style={{ position: 'relative', left: marginLeft }} />
                </div>
              ) : (
                <img src={url} alt={'Sticker Holder' + i} style={{ height: '380px' }} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="body">
        <div className="title">{name}</div>
        <div className="price">
          {price * packSize} RON
          {packSize > 1 ? <span className="um"> / pachet {packSize}</span> : <span className="um"> / bucată</span>}
        </div>
      </div>

      <div className="quantity-picker">
        <div className="quantity-button minus" disabled={count <= 0} onClick={() => setCount(count - packSize)}>
          <span>-</span>
        </div>
        {count > 0 ? (
          <div className="quantity">
            {count}
            {count > 19 ? ' de' : ''} {count !== 1 ? 'bucăți' : 'bucată'}
          </div>
        ) : (
          <div className="quantity">Alege nr. bucăți</div>
        )}
        <div disabled={count + packSize > stock} className="quantity-button plus" onClick={() => setCount(count + packSize)}>
          <span>+</span>
        </div>
      </div>
      {!stock && <div className="no-stock-tag">Fără stoc</div>}
    </Wrapper>
  )
}
