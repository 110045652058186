import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import HeaderLogo from './LogoAndBrand'
import HeaderDarkButton from './DarkModeToggler'
import TopRightDropdown from './TopRightDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'src/state/users'
import { State } from 'src/state'
import { useLocation } from 'react-router-dom'
import { PAGE_TITLES } from 'src/screens'

const Wrapper = styled.header`
  position: sticky;
  z-index: 101;
  top: 0;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  box-shadow: 0 4px 12px ${props => props.theme.shadow};
  height: 58px;
  padding: 0 10vw;
  background-color: ${props => props.theme.white};
  user-select: none;

  @media (max-width: 768px) {
    padding: 0;
  }

  header.right-section {
    display: flex;
    align-items: center;

    span {
      max-width: calc(100vw - 211px);
      margin-right: 8px;
      
      .username {
        display: inline-block;
        line-height: 10px;
      }

    }
  }
`

const Header = () => {
  const dispatch = useDispatch()
  const [pageTitle, setPageTitle] = useState<string>((PAGE_TITLES as any)[window.location.pathname])

  const { token } = useSelector((state: State) => state.users)
  const { user } = useSelector((state: State) => state.users)

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser())
    }
  }, [])

  const { pathname } = useLocation()

  useEffect(() => {
    const currentPageTitle = (PAGE_TITLES as any)[window.location.pathname]
    setPageTitle(currentPageTitle)
  }, [pathname])

  return (
    <Wrapper>
      <HeaderLogo title={pageTitle} />
      <header className="right-section">
        <HeaderDarkButton />
        {user?.username && <TopRightDropdown />}
      </header>
    </Wrapper>
  )
}

export default Header
