import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ALLERGENS, ALL_PRODUCT_PROPERTIES, CurrencyCode, LanguageCode, MenuProduct, User } from '@meniudigital/shared'
import { Wrapper, ProductImageWrapper, ProductImage, ButtonsWrapper, Button, MatchedString, OutOfStockIndicator } from './styles'
import { IS_DESKTOP_OR_TABLET } from '..'

type Props = {
  isFirst: boolean
  product: MenuProduct
  query: string
  languageCode: LanguageCode
  user: User
  removeProduct: (product: MenuProduct) => void
  openEditModal: (isClone?: boolean) => void
}

export default function Product({ product, openEditModal, removeProduct, query: rawQuery, languageCode, user, isFirst }: Props) {
  const query = rawQuery
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^(\w|\s)]/g, '')
  const productName = product.names[languageCode]
  const normalizedProductName = productName
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^(\w|\s)]/g, '')

  const indexOfMatch = normalizedProductName.indexOf(query)

  const beforeMatchedString = productName.substring(0, indexOfMatch)
  const matchedString = productName.substring(indexOfMatch, indexOfMatch + query.length)
  const afterMatchedString = productName.substring(indexOfMatch + query.length)

  const localizedDescription = product.descriptions[languageCode]

  const hasVerticalButtons = product.imageKey || product.childProducts?.length || isFirst

  const renderPriceFor = (targetProduct: MenuProduct, inline?: boolean) => (
    <div className="info-line" style={inline ? { display: 'inline' } : {}}>
      <span className="info-line-name">{!inline && 'Preț:'}</span>
      <span
        className={product.isDiscounted || (user.inPartyMode && product.priceDuringEvent) ? 'obsolete' : ''}
        style={inline ? { fontWeight: '600' } : {}}
      >
        {targetProduct.price &&
          targetProduct
            .price!.toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
        {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
      </span>
      {product.isDiscounted && targetProduct.discountedPrice && (
        <span className={'discounted' + (user.inPartyMode && targetProduct.priceDuringEvent ? ' obsolete' : '')}>
          {targetProduct.discountedPrice
            .toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
          {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
        </span>
      )}
      {targetProduct.priceDuringEvent && user.isUsingPartyMode && (
        <span className="during-event">
          /{' '}
          {targetProduct
            .priceDuringEvent!.toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
          {(user?.defaultCurrency || CurrencyCode.Ron).toUpperCase()}
        </span>
      )}
      {user.inPartyMode && <div className="bullet" style={{ marginLeft: 4 }}></div>}
    </div>
  )

  const renderName = () => (
    <div className="bolder" style={{ lineHeight: '18px' }}>
      <span>{beforeMatchedString}</span>
      <MatchedString>{matchedString}</MatchedString>
      <span style={{ marginRight: '8px' }}>{afterMatchedString}</span>
      {!product.isAvailable && <OutOfStockIndicator>Fără stoc</OutOfStockIndicator>}
    </div>
  )

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>
        <div className="product-info">
          {product.imageUrl && (
            <ProductImageWrapper>
              <ProductImage src={product.thumbnailUrl} loading="lazy" />
              {!IS_DESKTOP_OR_TABLET && renderName()}
            </ProductImageWrapper>
          )}
          <div>
            {(IS_DESKTOP_OR_TABLET || !product.imageUrl) && renderName()}
            {!(product.childProducts || []).length && renderPriceFor(product)}

            {Boolean(localizedDescription) && (
              <div className="info-line">
                <span className="info-line-name">Descriere:</span>
                <span>{localizedDescription}</span>
              </div>
            )}

            {Boolean(product.quantities) && !(product.childProducts || []).length && (
              <div className="info-line">
                <span className="info-line-name">
                  Gramaj{product.quantities!.includes('/') || product.quantities!.includes(',') ? 'e' : ''}:
                </span>
                {product.quantities}
              </div>
            )}

            {Boolean(product.kcalories) && !(product.childProducts || []).length && (
              <div className="info-line">
                <span className="info-line-name">Kcal per 100g:</span>
                {product.kcalories}
              </div>
            )}

            {Boolean(product.nutritionalDeclaration?.length) && (
              <div className="info-line">
                <span className="info-line-name">Declarație nutrițională per 100g:</span>
                <span style={{ fontWeight: '400' }}>{product.nutritionalDeclaration}</span>
              </div>
            )}

            {Boolean(product.allergens?.length) && (
              <div className="info-line">
                <span className="info-line-name">Alergeni:</span>
                <span style={{ fontWeight: '400' }}>
                  {product.allergens!.map(x => ALLERGENS.find(y => y.id === x)?.shortNames[languageCode] || '').join(', ')}
                </span>
              </div>
            )}

            {Boolean(product.properties?.length) && (
              <div className="info-line">
                <span className="info-line-name">Alte proprietăți:</span>
                <span style={{ fontWeight: '400' }}>
                  {ALL_PRODUCT_PROPERTIES.filter(x => (product.properties || []).find(y => y === x.type))
                    .map(x => x.name)
                    .join(', ')}
                </span>
              </div>
            )}

            {(product.childProducts || []).length > 0 && (
              <div className="info-line">
                <div className="info-line-name">Variante de produs:</div>

                {[...product.childProducts!]
                  .sort((a, b) => (b.index > a.index ? -1 : 1))
                  .map(childProduct => (
                    <div key={childProduct.id} className="product-variant">
                      <strong>{childProduct.names[languageCode]}</strong>
                      {Boolean(childProduct.quantities) && <span> - {childProduct.quantities}</span>}
                      {Boolean(childProduct.kcalories) && <span> - {childProduct.kcalories}kcal</span>}
                      {Boolean(childProduct.price) && <strong> - {renderPriceFor(childProduct, true)}</strong>}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <ButtonsWrapper
        className={isFirst ? 'with-text-buttons' : ''}
        style={{ flexDirection: hasVerticalButtons ? 'column' : 'row' }}
      >
        <Button title="Editează produsul" onClick={() => openEditModal()}>
          <FontAwesomeIcon style={{ margin: '0 -1px' }} icon={faPencilAlt} />
          <span>Editează</span>
        </Button>
        <Button title="Duplică produsul" className="clone-button" onClick={() => openEditModal(true)}>
          <FontAwesomeIcon style={{ marginLeft: '-1px' }} icon={faClone} />
          <span>Duplică</span>
        </Button>
        <Button title="Șterge produsul" className="destructive" onClick={() => removeProduct(product)}>
          <FontAwesomeIcon icon={faTrash} />
          <span>Șterge</span>
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}
