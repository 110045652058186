import React from 'react'
import { Wrapper } from './styles'

export default class TermsAndConditions extends React.Component {
  render() {
    return (
      <Wrapper>
        <div className="page">
          <img src="/logo.svg" alt="MeniuDigital logo" width="40px" style={{ marginBottom: '32px' }} />
          <h1>Termeni și condiții</h1>
          Prezentele condiții de utilizare („Condițiile”) reglementează accesarea și utilizarea de către dumneavoastră,
          persoană fizică sau juridică, a website-ului, produselor și serviciilor (denumite în mod colectiv
          „Serviciile”) puse la dispoziție de MeniuDigital („MeniuDigital”), o societate cu răspundere limitată,
          TOUCHFREEMENU S.R.L., înființată în România, cu sediul în Sighetu Marmației, strada Șugău, nr. 46A, Județul
          Maramureș, înregistrată la Registrul Comerțului cu numărul J24/1306/05.08.2021, Cod Unic de Înregistrare
          44695880, telefon de contact: (+40) 774 566 954, email: contact@meniudigital.ro. <br />
          <br />
          Condițiile prezentate în această pagină sunt valabile pentru website-ul www.meniudigital.ro și
          pentru orice alt website conex către care vei fi redirecționat în vedererea utilizării serviciului oferit de
          platforma MeniuDigital, cu excepția cazului în care sunt specificate în mod expres alte condiții de
          utilizare pe website-ul către care s-a făcut redirecționarea.
          <br />
          <b>Vă rugăm să citiți cu atenție aceste condiții înainte de a accesa sau utiliza serviciile.</b>
          <br />
          <br />
          Accesarea și utilizarea de către dumneavoastră a Serviciilor constituie acordul dumneavoastră de a respecta
          aceste Condiții, fapt ce dă naștere unei relații contractuale între dumneavoastră și MeniuDigital. Dacă nu
          sunteți de acord cu aceste Condiții, nu puteți accesa sau utiliza Serviciile. Prin acordul dumneavoastră de a
          respecta aceste Condiții, declarați și garantați că aveți cel puțin vârsta de 18 ani și capacitatea necesară
          pentru a încheia un astfel de acord. În același timp, declarați și garantați că aveți autoritatea de a acționa
          în numele persoanei juridice pe care o reprezentați. În cazul în care anumite Servicii determină existența
          unor Condiții suplimentare (de exemplu politici pentru un anumit serviciu, un anumit produs sau promoție),
          respectivele condiții suplimentare vă vor fi prezentate, adăugate în aceste Condiții și vor fi considerate
          parte integrantă din acestea. Condițiile suplimentare vor prevala față de aceste Condiții în cazul unui
          conflict cu privire la Serviciile aplicabile. MeniuDigital poate modifica periodic prezentele Condiții.
          Modificările vor intra în vigoare în momentul publicării respectivelor Condiții actualizate în acest loc.
          Accesarea sau utilizarea în continuare de către dumneavoastră a Serviciilor după respectiva publicare
          constituie acordul dumneavoastră de a respecta Condițiile modificate.
          <h3>Servicii</h3>
          MeniuDigital oferă servicii de digitalizare a meniului culinar oferit de
          restaurantul/hotelul/cafeneaua/barul dumneavoastră, prin intermediul website-ului{' '}
          <a href="https://admin.meniudigital.ro">https://admin.meniudigital.ro</a>. De asemenea, prin intermediul
          website-ului <a href="https://www.meniudigital.ro">https://www.meniudigital.ro</a> (denumite împreună „Platforma MeniuDigital”),
          MeniuDigital permite afișarea variantei digitale a meniului dumneavostră către potențialii clienți. <br />
          <br />
          Descrierea detaliată a serviciilor de digitalizare și accesare se regăsește pe website-ul de prezentare al
          Platformei MeniuDigital (https://www.meniudigital.ro)
          <h3>Utilizarea Serviciilor</h3>
          <br />
          <b>Începerea/prelungirea colaborării</b>
          <br />
          Pentru a beneficia de serviciile de digitalizare trebuie să vă creați un cont pe Platformă. Orice
          informație cu caracter personal furnizată în continuare va fi supusă condițiilor din{' '}
          <a href="/privacy-policy">Politica de confidențialitate</a>.
          <br />
          <br />
          <b>Contul de utilizator</b>
          <br />
          Pentru gestionarea meniului aveți acces la un cont personal activ de utilizator al Serviciilor, datele
          furnizate de dumneavoastră fiind astfel salvate, fără a mai fi necesară introducerea lor pentru următoarele
          comenzi. Vă asumați responsabilitatea pentru păstrarea confidențialității datelor de acces în Contul
          dumneavoastră. Sunteți singurul responsabil pentru toate activitățile desfășurate prin Contul dumneavoastră de
          utilizator cu excepția faptului că MeniuDigital poate, în anumite circumstanțe, să acceseze Contul pentru a
          efectua modificări pe care le solicitați. Dacă suspectați desfășurarea unei activități neautorizate prin
          Contul dumneavoastră sau orice altă încălcare a securității, trebuie să ne contactați imediat la (+40) 774 566
          954
          <br />
          <br />
          <b>Comunicarea cu MeniuDigital</b>
          <br />
          Prin începerea folosirii Platformei, sunteți de acord că MeniuDigital vă poate apela sau trimite email-uri
          pentru a facilita prestarea Serviciilor, în cadrul operațiunilor normale asociate cu utilizarea de către
          dumneavoastră a Serviciilor. Dumneavoastră puteți contacta MeniuDigital în legătură cu orice nemulțumire,
          sugestie sau orice alt aspect legat utilizarea Serviciilor la adresa de email sau la numărul de telefon care
          se regăsesc pe Platformă. Întregul conținut furnizat de către dumneavoastră ca utilizator va rămâne
          proprietatea dumneavoastră Totuși, prin furnizarea de conținut către MeniuDigital dumneavoastră ca
          utilizator, acordați MeniuDigital dreptul de a utiliza, a copia, a modifica, a crea opere derivate, a
          distribui, a afișa public, a reprezenta public și a exploata în orice alt mod respectivul conținut furnizat de
          utilizator în toate formatele și pe toate canalele de distribuție, fără a vă informa și fără a fi necesar
          acordul dumneavoastră, și fără a fi nevoie să vă plătească fie pe dumneavoastră, fie o altă persoană fizică
          sau juridică. Vă obligați să nu transmiteți un conținut cu caracter denigrator, calomnios, violent, obscen,
          pornografic, ilegal sau jignitor. MeniuDigital are posibilitatea, dar nu și obligația, de a examina, a
          monitoriza sau a șterge conținutul furnizat de utilizatori, la latitudinea exclusivă a MeniuDigital, în
          orice moment, indiferent de motiv, fără a vă anunța.
          <br />
          <br />
          <b>Accesul la rețea și dispozitivele.</b>
          <br />
          Vă revine responsabilitatea de a obține accesul la rețeaua de date necesar pentru a utiliza Serviciile. Se pot
          aplica tarifele și prețurile percepute de rețeaua dumneavoastră mobilă pentru transferul de date și mesaje,
          dacă accesați sau utilizați Serviciile de pe un dispozitiv care permite comunicarea wireless, și vă revine
          responsabilitatea de a achita aceste tarife și prețuri. Vă revine responsabilitatea de a procura și a
          actualiza echipamentele hardware sau dispozitivele necesare pentru a accesa și a utiliza Serviciile prin
          intermediul Platformei MeniuDigital precum și de a procura orice actualizări ale acestora. MeniuDigital
          nu garantează că Serviciile sau o porțiune a acestora vor funcționa pe un anumit echipament hardware sau un
          anumit dispozitiv. În plus, Serviciile pot fi afectate de defecțiuni și întârzieri inerente în utilizarea
          internetului și a comunicațiilor electronice.
          <h3>Plata</h3>
          Utilizarea Serviciilor determină costuri pe care trebuie să le achitați. Prețurile sunt cele afișate pe
          Platformă, iar acestea pot fi modificate în orice moment, fără ca modificarea să aibă efect asupra comenzilor
          deja plasate. Prestarea serviciilor sunt condiționate de efectuarea plății. Plata se va efectua exclusiv
          online, prin redirecționarea către o platformă specializată{' '}
          <a href="https://netopia-payments.com/">Netopia MobilPay</a> folosind carduri de tip Visa sau MasterCard.
          MeniuDigital va depune diligențele necesare pentru a vă informa cu privire la prețurile aplicabile.
          <h3>Politica de livrare a comenzii</h3> Datele de
          facturare folosite vor fi cele comunnicate în momentul începerii colaborării. Dacă doriți modificarea datelor
          de facturare, vă rugăm să ne contactați prin una dintre metodele de contact disponibile.
          <h3>Politica de confidențialitate</h3> Pentru a afla cum folosim datele tale cu caracter personal pe care le
          colectăm atunci când folosești platforma MeniuDigital, te rugăm să consulți{' '}
          <a href="/privacy-policy">Politica de confidențialitate</a>.<br />
          <h3>Siguranța datelor tale</h3> Vom păstra anumite date pe care le transmiteți Platformei în scopul
          gestionării performanței site-ului, precum și date referitoare la utilizarea dvs. a Platformei. Deși efectuăm
          regulat copii de siguranță de rutină ale datelor, sunteți singurul responsabil pentru toate datele pe care le
          transmiteți sau care se referă la orice activitate pe care ați întreprins-o folosind Site-ul. Sunteți de acord
          că nu vom avea nicio răspundere față de dvs. pentru orice pierdere sau corupție a unor astfel de date și
          renunțați la orice drept de acțiune împotriva noastră care rezultă din orice astfel de pierdere sau corupție a
          acestor date.
          <h3>Răspunderea</h3>
          Ne pasă de confidențialitatea și securitatea datelor dumneavoastră. Prin utilizarea Platformei, sunteți de
          acord să fiți legat de politica noastră de confidențialitate postată pe site, care este încorporată în acești
          termeni de utilizare. Vă rugăm să fiți anunțați că site-ul și ofertele de pe piață sunt găzduite înRomania.
          Dacă accesați site-ul sau ofertele de pe piață din orice altă regiune a lumii, cu legi sau alte cerințe care
          reglementează colectarea, utilizarea sau divulgarea datelor cu caracter personal care diferă de legile
          aplicabile înRomania, apoi prin utilizarea continuă a site-ului, vă transferați datele cătreRomaniași sunteți
          de acord în mod expres ca datele dvs. să fie transferate și prelucrate înRomania.
          <h3>9. Dispoziții diverse</h3>
          <br />
          <br />
          <b>Proprietatea</b>
          <br /> Serviciile și toate drepturile asociate cu acestea sunt și vor rămâne proprietatea MeniuDigital.
          Conținutul acestui site nu poate fi utilizat, reprodus, distribuit, transmis, expus, in alte scopuri decât
          cele expres și legal permise. Extragerea oricăror informații urmată de orice utilizare în scop comercial care
          depășește sfera copiei private reglementate de lege sau pentru vânzare ori licențiere și fără a avea în
          prealabil un consimțământ scris al titularilor drepturilor de proprietate, constituie o încălcare a termenilor
          și condițiilor.
          <br />
          <br />
          <b>Restricții</b>
          <br />
          Vă sunt interzise următoarele: (i) să îndepărtați marcajele privind drepturile de autor, mărcile comerciale
          sau alte drepturi de proprietate de pe vreo porțiune a Serviciilor; (ii) să reproduceți, să modificați, să
          realizați opere derivate, să distribuiți, să licențiați, să închiriați, să vindeți, să revindeți, să
          transferați, să afișați public, să reprezentați public, să transmiteți, să faceți streaming, să difuzați sau
          să exploatați în orice alt mod Serviciile, cu excepțiile permise în mod expres de MeniuDigital; (iii) să
          decompilați, să refaceți prin inginerie inversă sau să dezasamblați Serviciile, cu eventualele excepții
          permise de legislația aplicabilă; (iv) să faceți frame-uri către vreo porțiune a Serviciilor; (v) să
          determinați sau să lansați programe sau scripturi care fac scraping, indexează, analizează sau efectuează
          orice alte operațiuni de extragere de date din vreo porțiune a Serviciilor, sau care îngreunează sau împiedică
          operarea și/sau funcționalitatea oricărui aspect al Serviciilor; sau (vi) să încercați să obțineți accesul
          neautorizat sau să afectați vreun aspect al Serviciilor sau al sistemelor sau rețelelor corelate cu acestea.
          <br />
          <br />
          <b>Legislația aplicabilă și jurisdicția</b>
          <br />
          Utilizarea serviciilor de către dumneavoastră este guvernată de legislația română. Orice litigii apărute între
          dumneavoastră și MeniuDigital vor fi soluționate pe cale amiabilă sau, în cazul în care acest lucru nu va
          fi posibil, litigiile vor fi soluționate de către instanțele judecătorești competente.
          <h3>10.Contact</h3>
          Pentru a rezolva o reclamație privind Platforma sau pentru a primi informații suplimentare cu privire la
          utilizarea Platformei, vă rugăm să ne contactați la:
          <br />
          <br />
          <br />
          <b>TOUCHFREEMENU SRL</b>
          <br />
          Strada Șugău nr. 46A
          <br />
          Sighetu Marmației, Maramureș 435500
          <br />
          România
          <br />
          <b>
            E-mail: contact@meniudigital.ro
            <br />
          </b>
          C.U.I.: 44695880
          <br />
          Reg.Com.: J24/1306/05.08.2021
          <br />
        </div>
      </Wrapper>
    )
  }
}
