import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { businessOrderEndpoints } from 'src/api'
import { BusinessOrder, BusinessOrderIntent, User } from '@meniudigital/shared'

export type State = {
  orderIntent?: BusinessOrderIntent
  isLoading: boolean
  list: (BusinessOrder & { user: User })[]
}

export const initialState: State = {
  orderIntent: undefined,
  list: [],
  isLoading: true,
}

export const getBusinessOrders = createAsyncThunk('holderOorders/getOrders', businessOrderEndpoints.getAll)
export const getBusinessOrder = createAsyncThunk('holderOorders/getOrder', businessOrderEndpoints.getById)
export const confirmBankPaymentFor = createAsyncThunk(
  'holderOorders/confirmBankPaymentFor',
  businessOrderEndpoints.confirmBankPaymentFor
)
export const confirmDeliveryFor = createAsyncThunk('holderOorders/confirmDeliveryFor', businessOrderEndpoints.confirmDeliveryFor)
export const cancelBusinessOrder = createAsyncThunk('holderOorders/cancelOrder', businessOrderEndpoints.cancelOrder)

const slice = createSlice({
  name: 'businessOrders',
  initialState,
  reducers: {
    addToOrderIntent: (state, action: PayloadAction<Partial<BusinessOrderIntent>>) => {
      if (!state.orderIntent) {
        state.orderIntent = {}
      }

      Object.assign(state.orderIntent, action.payload)
    },
    clearOrderIntent: state => {
      state.orderIntent = undefined
    },
  },
  extraReducers: actions => {
    actions.addCase(getBusinessOrders.pending, state => {
      state.isLoading = true
    })
    actions.addCase(confirmBankPaymentFor.pending, state => {
      state.isLoading = true
    })
    actions.addCase(confirmDeliveryFor.pending, state => {
      state.isLoading = true
    })
    actions.addCase(cancelBusinessOrder.pending, state => {
      state.isLoading = true
    })

    // Fulfilled
    actions.addCase(getBusinessOrder.fulfilled, (state, action: PayloadAction<BusinessOrder>) => {
      state.orderIntent = action.payload
    })
    actions.addCase(getBusinessOrders.fulfilled, (state, { payload }: PayloadAction<(BusinessOrder & { user: User })[]>) => {
      state.isLoading = false
      state.list = [...payload].sort((a, b) => (+new Date(b.createdAt || '') > +new Date(a.createdAt || '') ? 1 : -1))
    })
    actions.addCase(confirmBankPaymentFor.fulfilled, (state, _) => {
      state.isLoading = false
    })
    actions.addCase(confirmDeliveryFor.fulfilled, (state, _) => {
      state.isLoading = false
    })
    actions.addCase(cancelBusinessOrder.fulfilled, (state, _) => {
      state.isLoading = false
    })

    // Rejected
    actions.addCase(confirmBankPaymentFor.rejected, (state, _) => {
      state.isLoading = false
      alert('Payment confirmation failed')
      throw new Error('Payment confirmation failed')
    })
    actions.addCase(confirmDeliveryFor.rejected, (state, _) => {
      state.isLoading = false
      alert('Delivery confirmation failed')
      throw new Error('Delivery confirmation failed')
    })
    actions.addCase(cancelBusinessOrder.rejected, (state, _) => {
      state.isLoading = false
      alert('Order cancellation failed')
      throw new Error('Order cancellation failed')
    })
  },
})

export default slice.reducer
export const { addToOrderIntent, clearOrderIntent } = slice.actions

export const isCuiValid = (cui: string) => {
  if (typeof cui !== 'string') {
    return false
  }
  let cif = cui.toUpperCase()
  // remove RO from cif:
  const indexOfRo = cif.indexOf('RO')
  if (indexOfRo > -1) {
    cif = cif.substr(0, indexOfRo) + cif.substr(indexOfRo + 2)
  }
  // remove spaces:
  cif = cif.replace(' ', '')
  // validate character length:
  if (cif.length < 2 || cif.length > 10) {
    return false
  }
  // validate that so far the resulting cif looks like an integer value:
  if (Number.isNaN(parseInt(cif))) {
    return false
  }
  // begin testing:
  const testKey = '753217532'
  const controlNumber = parseInt(cif.substr(cif.length - 1))
  // remove control number:
  cif = cif.substr(0, cif.length - 1)
  // pad left with zeros to reach testKey length:
  while (cif.length !== testKey.length) {
    cif = '0' + cif
  }
  let sum = 0
  let i = cif.length
  while (i--) {
    sum = sum + +cif.charAt(i) * +testKey.charAt(i)
  }
  let calculatedControlNumber = (sum * 10) % 11
  if (calculatedControlNumber === 10) {
    calculatedControlNumber = 0
  }
  return controlNumber === calculatedControlNumber
}
