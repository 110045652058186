import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { SearchInput } from '../../HomeScreen/styles'
import moment from 'moment'
import { getWaiterCalls } from 'src/state/waiter-calls'
import OrderOrWaiterCall from '../ConsumerOrdersSuperadmin/OrderOrWaiterCall'

export default function WaiterCalls() {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()

  const waiterCalls = useSelector((state: State) => state.waiterCalls.list)

  useEffect(() => {
    dispatch(getWaiterCalls())
  }, [])

  const filteredCalls = waiterCalls
    .filter(
      x =>
        (x.tableNumber || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.targetUsername || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.name || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.city || '').toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => (+moment(b.createdAt) > +moment(a.createdAt!) ? 1 : -1))
    .slice(0, 50)

  return (
    <>
      <Wrapper>
        <div className="top-bar">
          <SearchInput
            placeholder="Caută chemare ospătar..."
            value={query}
            onChange={e => {
              setQuery(e.target.value)
            }}
          />
        </div>

        <div className="action-list">
          {filteredCalls.map(call => (
            <OrderOrWaiterCall action={call} />
          ))}
        </div>
      </Wrapper>
    </>
  )
}
