import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  margin-bottom: 40px;
  background: ${props => props.theme.white};
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: 0 4px 12px ${props => props.theme.shadow};
  margin: 0 20px;
  margin-bottom: 30px;

  .header {
    height: 350px;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  .body {
    padding: 16px;

    .title {
      font-weight: 700;
    }

    .price {
      margin-top: 8px;
      font-weight: 700;
      font-size: 18px;

      .um {
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  .quantity-picker {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -4px 12px ${props => props.theme.shadow};
    padding: 16px;
    position: relative;
    z-index: 10;
    user-select: none;

    .quantity {
      text-align: center;
      font-weight: 600;
    }

    .quantity-button {
      height: 27px;
      width: 27px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 500;
      cursor: pointer;
      color: ${props => props.theme.white};
      background: ${props => props.theme.accent};

      &[disabled] {
        opacity: 0.4;
        pointer-events: none;
      }

      :hover,
      :focus,
      :active {
        box-shadow: -1px 1px 8px #0003;
      }

      &.minus {
        font-size: 50px;
        font-weight: 400;
        padding-bottom: 8px;
      }

      &.plus {
        padding-bottom: 1px;
      }
    }
  }

  .swiper {
    height: 100%;
    width: 275px;

    .swiper-slide {
      display: flex;

      position: relative;

      img,
      div {
        height: 370px;
      }
    }

    .swiper-slide,
    .swiper-slide img {
      transition: transform 10s linear;
      transition-delay: 0s;
    }

    .swiper-slide-active {
      transform: scale(1.3) translateY(-10px);

      &.to-bottom-left {
        transform: none;

        img {
          transform: scale(1.7) translateY(-50px) translateX(45px);
        }
      }

      &.to-right {
        transform: none;

        img {
          transform: translateX(-180px);
          transition-delay: 1s;
        }
      }

      &.to-right-short {
        transform: none;

        img {
          transform: translateX(-50px);
        }
      }
    }
  }

  @keyframes zoom-in {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1.5);
    }
  }
`
