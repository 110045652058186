import React, { useEffect } from 'react'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'
import { BillingType } from '@meniudigital/shared'
import { Screen } from 'src/screens'
import { useDispatch } from 'react-redux'
import { addToOrderIntent } from 'src/state/business-orders'
SwiperCore.use([Autoplay, Navigation])

export default function ChooseBillingType() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addToOrderIntent({ billingInfo: undefined }))
  }, [])

  const selectBillingType = (billingType: BillingType) => {
    navigate(`${Screen.EnterBillingInfo}?type=${billingType}`)
  }

  return (
    <Wrapper>
      <div className="page">
        <h3>Cum dorești să facturezi?</h3>

        <div onClick={() => selectBillingType(BillingType.NaturalPerson)} className="billing-type">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faUser} className="icon" />
          </div>
          <div className="name">Pe persoană fizică</div>
        </div>
        <div onClick={() => selectBillingType(BillingType.Company)} className="billing-type">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faBuilding} className="icon" />
          </div>
          <div className="name">Pe persoană juridică</div>
        </div>
      </div>
    </Wrapper>
  )
}
