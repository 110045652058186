import { createStore, applyMiddleware, compose, AnyAction, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'

import users, { State as UsersState } from './users'
import theme, { State as AppThemeState } from './theme'
import categories, { State as CategoriesState } from './categories'
import products, { State as ProductsState } from './products'
import businessOrders, { State as BusinessOrdersState } from './business-orders'
import consumerOrders, { State as ConsumerOrdersState } from './consumer-orders'
import waiterCalls, { State as WaiterCallsState } from './waiter-calls'
import featureVotes, { State as FeatureVoteState } from './feature-votes'
import reservations, { State as ReservationsState } from './consumer-reservations'
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
  categories: persistReducer({ key: 'categories', storage }, categories),
  products: persistReducer({ key: 'products', storage }, products),
  businessOrders: persistReducer({ key: 'businessOrders', storage }, businessOrders),
  consumerOrders: persistReducer({ key: 'consumerOrders', storage }, consumerOrders),
  waiterCalls: persistReducer({ key: 'waiterCalls', storage }, waiterCalls),
  users: persistReducer({ key: 'users', storage, blacklist: ['list', 'isLoading'] }, users),
  theme: persistReducer({ key: 'theme', storage }, theme),
  featureVotes: persistReducer({ key: 'featureVotes', storage }, featureVotes),
  reservations: persistReducer({ key: 'reservations', storage }, reservations),
})

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'users/logOut') {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:categories')
    storage.removeItem('persist:products')
    storage.removeItem('persist:orders')
    storage.removeItem('persist:users')
    storage.removeItem('persist:theme')
    storage.removeItem('persist:featureVotes')
    storage.removeItem('persist:businessOrders')
    storage.removeItem('persist:consumerOrders')

    state = {} as RootState
  }
  return appReducer(state, action)
}

export const store = createStore(rootReducer as any, compose(applyMiddleware(thunk)))
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof appReducer>
export type State = {
  categories: CategoriesState
  products: ProductsState
  users: UsersState
  businessOrders: BusinessOrdersState
  consumerOrders: ConsumerOrdersState
  waiterCalls: WaiterCallsState
  theme: AppThemeState
  featureVotes: FeatureVoteState
  reservations: ReservationsState
}
