import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { Screen } from 'src/screens'
import Button from 'src/global/Button'
import { SearchInput } from '../../HomeScreen/styles'
import moment from 'moment'
import { getConsumerOrders } from 'src/state/consumer-orders'
import OrderOrWaiterCall from './OrderOrWaiterCall'

export default function ConsumerOrders() {
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()

  const { list: orders, isLoading } = useSelector((state: State) => state.consumerOrders)

  useEffect(() => {
    dispatch(getConsumerOrders())
  }, [])

  const filteredOrders = orders
    .filter(
      x =>
        (x.orderNumber || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.targetUsername || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.name || '').toLowerCase().includes(query.toLowerCase()) ||
        (x.user.city || '').toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => (+moment(b.createdAt) > +moment(a.createdAt!) ? 1 : -1))
    .slice(0, 50)

  return (
    <>
      <Wrapper>
        {orders.length ? (
          <div className="top-bar">
            <SearchInput
              placeholder="Caută comandă la masă..."
              value={query}
              onChange={e => {
                setQuery(e.target.value)
              }}
            />
          </div>
        ) : isLoading ? null : (
          <div className="panel info" style={{ padding: '40px 16px' }}>
            <div className="background-waves">
              <img src="/misc/waves.png" />
            </div>
            <p>Momentan nu ai nicio comandă.</p>
            <p>Poți începe prima comandă făcând click aici: </p>
            <Button text="Comandă" onClick={() => navigate(Screen.ChooseHolders)} />
          </div>
        )}

        <div className="action-list">
          {filteredOrders.map(order => (
            <OrderOrWaiterCall action={order} />
          ))}
        </div>
      </Wrapper>
    </>
  )
}
