import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .page {
    @media (max-width: 768px) {
      padding: 16px;
    }

    &.is-business {
      .stat.comparison {
        display: none;
      }

      .subscription-info {
        background: linear-gradient(20deg, hsl(208deg 18% 30%), hsl(208deg 18% 48%));
        color: #fff;
      }

      .limit-dotted-line {
        display: none;
      }

      .stat-progress-bar-track {
        overflow: hidden;

        .stat-progress-bar-fill {
          background: linear-gradient(20deg, hsl(208deg 18% 30%), hsl(208deg 18% 48%));
          max-width: 100%;
        }
      }
    }
  }

  .stats {
    width: 100%;
    background: ${({ theme }) => theme.white};
    padding: 16px;
    border-radius: 16px;
    gap: 50px;
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 8px;
    display: block;
  }

  .section-wrapper {
    width: 100%;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      label {
        margin-top: 0;
      }
    }
  }

  .orders-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .header {
    background: ${({ theme }) => theme.white};
    padding: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    margin-bottom: 16px;

    .subscription-info {
      padding: 16px;
      border-radius: 16px;
      box-shadow: 0 0px 12px ${props => props.theme.middleShadow};
      cursor: pointer;

      &:hover {
        box-shadow: 0 0px 12px ${props => props.theme.darkerShadow};
      }
      /* background-color: ${({ theme }) => theme.iosDarkGray}; */
      /* border: 1px solid ${({ theme }) => theme.border}; */

      img {
        width: 32px;
        margin-bottom: 4px;
      }

      .brand-name {
        font-weight: 500;
        font-size: 14px;
      }

      .subscription-name {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: -4px;
      }
    }

    .price-and-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      gap: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding-top: 14px;
        gap: 8px;
      }

      .subscription-price {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .subscription-duration {
      flex-direction: column;
      align-items: flex-end;

      button {
        margin-bottom: -12px;
        padding: 0 20px;
      }
    }

    button {
      margin: 0;
      margin-right: 8px;
    }
  }

  .stat {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 768px) {
      align-items: flex-end;
    }

    .stat-name {
      margin-bottom: 12px;
      font-weight: 500;
    }

    &.comparison {
      margin-top: 0;
      margin-bottom: 50px;

      .business {
        background: linear-gradient(
          110deg,
          hsla(208deg, 18%, 48%, 0),
          hsla(208deg, 18%, 48%, 0),
          hsla(208deg, 18%, 48%, 0.5),
          hsla(208deg, 18%, 48%, 1),
          hsla(208deg, 18%, 40%, 1),
          hsla(208deg, 18%, 40%, 1)
        );
        color: #fff !important;
      }
    }

    .stat-progress-bar-track {
      width: 100%;
      background: ${({ theme }) => theme.pageBackground};
      position: relative;
      border-radius: 8px;

      .stat-progress-bar-fill {
        min-width: 45px;
        max-width: calc(100% - 120px);
        height: 40px;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: ${({ theme }) => theme.accent};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 2;

        .value {
          color: ${({ theme }) => theme.white};
          font-weight: 600;
          font-size: 18px;
          margin-right: 10px;
        }
      }

      .stat-progress-bar-limit {
        position: relative;

        .limit-dotted-line {
          border-right: 2px dashed ${({ theme }) => theme.accent};
          height: 92px;
          position: absolute;
          right: -2px;
          bottom: 0;
          /* width: 2px; */

          span {
            white-space: nowrap;
            font-weight: 500;
            background: ${({ theme }) => theme.white};
            border: 1px solid ${({ theme }) => theme.accent};
            padding: 6px 10px;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            margin-right: -2px;
            font-weight: 600;
            color: ${({ theme }) => theme.accent};
          }
        }
      }

      .business {
        color: hsl(207 18% 35%);
        transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        padding: 0 12px;
        align-items: center;
        border-radius: 8px;
        width: 600px;
        z-index: 0;
        cursor: pointer;

        @media (max-width: 768px) {
          width: 80%;
        }

        &:hover {
          opacity: 0.9;
        }

        img {
          width: 24px;
        }

        .limit-text {
          font-weight: 500;

          .value {
            font-weight: 700;
            margin-left: 4px;
          }
        }
      }

      .infinity-symbol {
        font-size: 26px;
        font-weight: 600;
        vertical-align: middle;
      }

      .subscription-meniudigital {
        font-size: 13px;
        font-weight: 500;
        margin-top: 1px;
        margin-bottom: -4px;
      }

      .subscription-name-text {
        font-size: 18px;
        font-weight: 600;
      }

      .vs-text {
        position: absolute;
        left: 48%;
        top: 10px;
        font-weight: 500;
        z-index: 1;
      }

      .free-gradient {
        background: linear-gradient(-110deg, #11b7f300, #11b7f300, #11b7f344, #11b7f3, #11b7f3, #11b7f3);
        color: #fff;
        transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 0 12px;
        align-items: center;
        border-radius: 8px;
        width: 700px;
        z-index: 0;

        @media (max-width: 768px) {
          width: 80%;
        }

        &:hover {
          opacity: 0.9;
        }

        img {
          width: 24px;
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));
        }
      }
    }
  }
`
