export enum Screen {
  Login = '/login',
  ChangePassword = '/change-password',
  ForgotPassword = '/forgot-password',
  SetNewPassword = '/set-new-password',
  RenewSubscription = '/renew-subscription',
  ConfirmEmail = '/confirm-email',
  Register = '/register',
  Personalize = '/personalize',
  PersonalizeInFlow = '/personalize?inFlow=true',
  SetLocation = '/set-location',
  SetSchedule = '/set-schedule',
  MySubscription = '/my-subscription',
  ChooseSubscription = '/choose-subscription',
  ChooseHolders = '/choose-holders',
  ChooseBillingType = '/choose-billing-type',
  EnterBillingInfo = '/enter-billing-info',
  EnterShippingInfo = '/enter-shipping-info',
  FinalizeOrder = '/finalize-order',
  CompletedOrder = '/completed-order/:id',
  FailedOrder = '/failed-order/:id',
  TermsAndConditions = '/terms-and-conditions',
  BusinessOrders = '/business-orders',
  ConsumerOrders = '/consumer-orders',
  Reservations = '/reservations',
  ReservationSettings = '/reservation-settings',
  ConsumerOrdersSuperadmin = '/consumer-orders-superadmin',
  WaiterCallsSuperadmin = '/waiter-calls-superadmin',
  Users = '/users',
  Stats = '/stats',
  UserActivity = '/activity',
  PrivacyPolicy = '/privacy-policy',
  Contact = '/contact',
  Home = '/',
}

export const PAGE_TITLES: { [key in Screen]: string } = {
  [Screen.Home]: 'Admin',
  [Screen.Login]: 'Login',
  [Screen.Personalize]: 'Personalizare logo & cover',
  [Screen.PersonalizeInFlow]: 'Personalizare logo & cover',
  [Screen.SetLocation]: 'Setează Locația',
  [Screen.SetSchedule]: 'Setează Programul de Lucru',
  [Screen.MySubscription]: 'Abonamentul meu',
  [Screen.ChooseSubscription]: 'Alege Perioada',
  [Screen.ChooseHolders]: 'Alege Holdere',
  [Screen.EnterShippingInfo]: 'Livrare',
  [Screen.EnterBillingInfo]: 'Facturare',
  [Screen.ChooseBillingType]: 'Facturare',
  [Screen.ChangePassword]: 'Schimb parola',
  [Screen.ConfirmEmail]: 'Confirmă email',
  [Screen.RenewSubscription]: 'Reînnoiește abonamentul',
  [Screen.Register]: 'Înregistrare',
  [Screen.FinalizeOrder]: 'Finalizează comanda',
  [Screen.CompletedOrder]: 'Comandă finalizată',
  [Screen.FailedOrder]: 'Comandă eșuată',
  [Screen.ForgotPassword]: 'Resetează parola',
  [Screen.SetNewPassword]: 'Resetează parola',
  [Screen.Contact]: 'Contact',
  [Screen.BusinessOrders]: 'Plăți efectuate',
  [Screen.ConsumerOrders]: 'Comenzi și "Cheamă ospătarul"',
  [Screen.Reservations]: 'Rezervări',
  [Screen.ReservationSettings]: 'Rezervări Online - Setări',
  [Screen.Users]: 'Useri',
  [Screen.UserActivity]: 'Activitate',
  [Screen.Stats]: 'Statistici',
  [Screen.ConsumerOrdersSuperadmin]: 'Comenzi la masă',
  [Screen.WaiterCallsSuperadmin]: 'Chemări ospătar',
  [Screen.TermsAndConditions]: 'Termeni și condiții',
  [Screen.PrivacyPolicy]: 'Politica de confidențialitate',
}
