import React, { useEffect, useRef, useState } from 'react'
import {
  Backdrop,
  Modal,
  Header,
  CloseButtonWrapper,
  ModalContent,
  ModalFooter,
  ErrorText,
  FieldWrapper,
  CloseButtonLineOne,
  CloseButtonLineTwo,
} from '../ProductModal/styles'
import { Button as TranslucentButton, Label, Suffix } from '../styles'
import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
// import Button from 'src/global/Button'
import Flag from 'src/global/Flag'
import { MenuSection, LanguageCode, MenuCategory, RomanianLanguageName } from '@meniudigital/shared'
import { useDispatch, useSelector } from 'react-redux'
import { updateCategory, createCategory } from 'src/state/categories'
import { categoriesSelector } from 'src/state/selectors'
import Toggle from 'react-toggle'
import { State } from 'src/state'
import SectionPicker from '../SectionPicker'
import CategoryPickerModal from '../CategoryPickerModal'
import { Wrapper } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'src/global/Button'
import { IS_DESKTOP_OR_TABLET } from '..'

type Props = {
  languageCode: LanguageCode
  onRemovePress: (category: MenuCategory) => void
}

export const SECTION_OPTIONS = [
  { value: MenuSection.Bar, label: 'Meniu Bar' },
  { value: MenuSection.Restaurant, label: 'Meniu Restaurant' },
  { value: MenuSection.Wine, label: 'Meniu Vinuri' },
  { value: MenuSection.Deserts, label: 'Deserturi' },
  { value: MenuSection.AsianFood, label: 'Bucătărie Asiatică' },
  { value: MenuSection.Sushi, label: 'Meniu Sushi' },
  { value: MenuSection.Terrace, label: 'Meniu Terasă' },
  { value: MenuSection.Extra, label: 'Extra' },
]

export default function CategoryModal({ languageCode, onRemovePress }: Props) {
  const { user } = useSelector((state: State) => state.users)
  const { list: categories = [] } = useSelector(categoriesSelector)
  const dispatch = useDispatch()

  const generateEmptyCategory = (section?: MenuSection): MenuCategory => ({
    id: '',
    index: 0,
    userId: '',
    names: { ro: '' },
    descriptions: { ro: '' },
    isHidden: false,
    hasSideDishCategoryIds: [],
    type: user?.isUsingMenuSections ? section || MenuSection.Restaurant : undefined,
  })

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inEditMode, setInEditMode] = useState(false)
  const [category, setCategory] = useState<MenuCategory>({ ...generateEmptyCategory() })
  const firstInputRef = useRef<HTMLInputElement>(null)

  const targetLanguageCode = (
    inEditMode && languageCode !== LanguageCode.Romanian ? languageCode : LanguageCode.Romanian
  ) as LanguageCode

  useEffect(() => {
    window.openCategoryModal = open
  }, [])

  const open = (categoryToEdit?: MenuCategory, preselectedSection?: MenuSection) => {
    if (categoryToEdit) {
      setIsOpen(true)
      setInEditMode(true)
      setCategory({ ...categoryToEdit })
    } else {
      setIsOpen(true)
      setInEditMode(false)
      setCategory({ ...generateEmptyCategory(preselectedSection) })
    }
    if (IS_DESKTOP_OR_TABLET) {
      firstInputRef.current?.focus()
    }
    document.querySelector('body')!.style.overflowY = 'hidden'
  }

  const save = () => {
    if (!isCategoryValid()) {
      return
    }

    if (inEditMode) {
      dispatch(updateCategory(category))
    } else {
      dispatch(createCategory(category))
    }
    close()
  }

  const close = () => {
    setIsOpen(false)
    document.querySelector('body')!.style.overflowY = 'scroll'
  }

  const isCategoryValid = () => {
    if (!(category.names[targetLanguageCode] || '').trim().length) {
      return false
    }
    if (!isNameUnique()) {
      return false
    }

    if (!canSelectThisSection()) {
      return false
    }

    return true
  }

  const canSelectThisSection = () => {
    const otherSections = [
      ...new Set(categories.filter(x => (category.id ? x.id !== category.id : true)).map(x => x.type || MenuSection.Restaurant)),
    ]

    if (otherSections.length >= 4 && !otherSections.find(x => (category.type || MenuSection.Restaurant) === x)) {
      return false
    }

    return true
  }

  const isNameUnique = () =>
    !categories.find(
      x =>
        x.names[targetLanguageCode]?.toLowerCase() === category.names[targetLanguageCode]?.toLowerCase() && x.id !== category.id
    )

  const onKeyDown = (e: React.KeyboardEvent) => {
    if ((e.target as HTMLTextAreaElement).tagName === 'TEXTAREA') {
      return
    }

    if (e.keyCode === 13) {
      save()
    }
    if (e.keyCode === 27) {
      close()
    }
  }

  const addSideDishCategory = (addedCategory: MenuCategory) => {
    if (category.hasSideDishCategoryIds?.some(x => x === addedCategory.id)) {
      return
    }

    if ((category.hasSideDishCategoryIds || []).length >= 3) {
      return
    }

    setCategory({ ...category, hasSideDishCategoryIds: [...(category.hasSideDishCategoryIds || []), addedCategory.id] })
  }

  const { names, descriptions } = category

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal onKeyDown={onKeyDown} style={{ width: '800px' }} className={isOpen ? 'open' : 'closed'}>
        <Header>
          {inEditMode ? 'Editează categoria' : 'Adaugă categorie'}
          <CloseButtonWrapper onClick={close} title="Închide">
            <CloseButtonLineOne />
            <CloseButtonLineTwo />
          </CloseButtonWrapper>
        </Header>
        <ModalContent>
          <Wrapper>
            <div className="left-section">
              {user?.isUsingMenuSections && (
                <>
                  <Label>Secțiune categorie</Label>
                  <div className="explainer">Din ce meniu dorești să facă parte această categorie?</div>
                  <SectionPicker
                    value={category.type || MenuSection.Restaurant}
                    options={SECTION_OPTIONS}
                    stacked
                    onChange={newValue => setCategory({ ...category, type: newValue as MenuSection })}
                  />
                  {!canSelectThisSection() && (
                    <ErrorText>
                      Acest meniu nu poate fi creat deoarece numărul maxim de meniuri diferite este 4. Te rog selectează unul
                      dintre meniurile existente.
                    </ErrorText>
                  )}
                </>
              )}
              <FieldWrapper style={{ width: '100%' }}>
                <Label>Nume categorie (în limba {RomanianLanguageName[targetLanguageCode as LanguageCode]})</Label>
                <input
                  ref={firstInputRef}
                  value={names[targetLanguageCode]}
                  onChange={e => setCategory({ ...category, names: { ...category.names, [targetLanguageCode]: e.target.value } })}
                  placeholder="e.g. Ciorbe, Paste, Cocktailuri"
                  className="data-hj-allow"
                  maxLength={200}
                  style={{ width: '100%' }}
                />
                <Suffix className="for-category">
                  <Flag of={targetLanguageCode} />
                </Suffix>
                {!isNameUnique() && <ErrorText>Ai deja o categorie cu acest nume.</ErrorText>}
              </FieldWrapper>
              <FieldWrapper style={{ width: '100%' }}>
                <Label>Descriere (în limba {RomanianLanguageName[targetLanguageCode as LanguageCode]}) (opțional)</Label>
                <textarea
                  value={descriptions[targetLanguageCode]}
                  onChange={e =>
                    setCategory({ ...category, descriptions: { ...category.descriptions, [targetLanguageCode]: e.target.value } })
                  }
                  placeholder="Descriere / informații categorie"
                  className="theme-textarea data-hj-allow"
                  style={{ height: 90, width: '100%' }}
                  maxLength={200}
                />
                <Suffix className="for-category">
                  <Flag of={targetLanguageCode} />
                </Suffix>
              </FieldWrapper>

              {inEditMode && (
                <>
                  <div className="react-toggle-wrapper" style={{ width: '100%' }}>
                    <Toggle
                      checked={category.isHidden}
                      title="Ascunde categoria"
                      icons={false}
                      onChange={() => setCategory({ ...category, isHidden: !category.isHidden })}
                    />
                    <span>Ascunde categoria</span>
                  </div>
                  <Button
                    onClick={() => {
                      close()
                      onRemovePress(category)
                    }}
                    text={'Șterge categoria'}
                    variant="as-text-danger"
                    style={{ paddingLeft: 8 }}
                  />
                </>
              )}
            </div>

            <div className="right-section">
              <Label className="section-title">Comanda la masă</Label>
              <Label>Categorii însoțitoare</Label>

              <div className="explainer">
                Ce preparate complementare pot fi adăugate ca și garnituri la produsele din această categorie? (e.g. "Alege o
                salată", "Alege sos", "Adaugă pâine")
              </div>

              {(category.hasSideDishCategoryIds || [])
                .map(id => categories.find(x => x.id === id)!)
                .map(sideDishCategory => (
                  <div className="suceeding-category">
                    {sideDishCategory.names[LanguageCode.Romanian]}
                    <TranslucentButton
                      title="Șterge categoria însoțitoare"
                      className="destructive"
                      onClick={() =>
                        setCategory({
                          ...category,
                          hasSideDishCategoryIds: category.hasSideDishCategoryIds?.filter(x => x !== sideDishCategory.id),
                        })
                      }
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </TranslucentButton>
                  </div>
                ))}

              <Button
                variant="as-text"
                text={(category.hasSideDishCategoryIds || []).length > 0 ? 'Adaugă...' : 'Alege...'}
                onClick={() => window.openCategoryPicker(category.names[LanguageCode.Romanian])}
                style={{ marginTop: '0', paddingLeft: 0 }}
              />
            </div>
          </Wrapper>
        </ModalContent>
        <ModalFooter>
          <Button variant="as-text" onClick={close} text="Anulează" />
          <Button
            onClick={save}
            text={inEditMode ? 'Salvează' : 'Adaugă'}
            disabled={!isCategoryValid()}
            icon={inEditMode ? faCheck : faPlus}
          />
        </ModalFooter>
      </Modal>
      <CategoryPickerModal onCategoryPick={addSideDishCategory} />
    </>
  )
}
