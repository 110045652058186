import React, { useEffect, useState } from 'react'
import {
  faCalendarAlt,
  faCaretDown,
  faChartLine,
  faChartSimple,
  faEuro,
  faExternalLinkAlt,
  faGear,
  faHome,
  faImage,
  faKey,
  faMobileAlt,
  faMoneyBill,
  faPhone,
  faQrcode,
  faSignOutAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DropdownButton, BoxShadowContainer, Dropdown, DropdownItem, Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { State } from 'src/state'
import { logOut } from 'src/state/users'
import { getBusinessOrders } from 'src/state/business-orders'
import LoadingProgressBar from 'src/global/LoadingProgressBar'
import * as tickAnimation from 'src/assets/animations/success-tick-circle.json'
import Lottie from 'lottie-react'
import wait from 'src/helpers/wait'
import { getDaysLeft, isBusiness } from '@meniudigital/shared'

export const DEFAULT_LOGO_URL = 'https://touchfreemenu.s3.eu-central-1.amazonaws.com/brand-logos/default-new.jpg'

export default function TopRightDropdown() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    users: userState,
    categories: categoryState,
    businessOrders: businessOrderState,
    consumerOrders: consumerOrderState,
    products: productState,
    waiterCalls: waiterCallsState,
    reservations: reservationsState,
  } = useSelector((state: State) => state)
  const [showsMenu, setShowsMenu] = useState(false)
  const [showsSuccessAnimation, setShowsSuccessAnimation] = useState(false)

  const isLoading =
    userState.isLoading ||
    categoryState.isLoading ||
    businessOrderState.isLoading ||
    consumerOrderState.isLoading ||
    productState.isLoading ||
    waiterCallsState.isLoading ||
    reservationsState.isLoading

  const user = userState.user!

  useEffect(() => {
    dispatch(getBusinessOrders())
  }, [])

  const toggleMenu = () => {
    if (!showsMenu) {
      showMenu()
    } else {
      setShowsMenu(false)
    }
  }

  const showMenuIfDesktop = () => {
    if (!('ontouchstart' in document.documentElement)) {
      showMenu()
    }
  }

  const showMenu = async () => {
    setShowsMenu(true)
    if (!localStorage['hasDismissed:personalize-info']) {
      localStorage['hasDismissed:personalize-info'] = true
      setTimeout(() => {
        dispatch(getBusinessOrders())
      }, 2000)
    }

    if (!sessionStorage.getItem('hasSeen:business-tick-animation') && isBusiness(user)) {
      await wait(300)
      setShowsSuccessAnimation(true)
      sessionStorage.setItem('hasSeen:business-tick-animation', 'true')
      await wait(3000)
      setShowsSuccessAnimation(false)
    }
  }

  const qrCodeUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user.username}/my-qr-code.svg`
  const menuUrl = `${process.env.REACT_APP_MENU_BASE_URL}/${user.username}`

  const { pathname } = useLocation()

  const logoUrl = user.logoUrl || DEFAULT_LOGO_URL

  const { logoBackgroundColor, logoScale } = user.styling || {}

  return (
    <Wrapper onMouseOver={showMenuIfDesktop} onMouseLeave={() => setShowsMenu(false)} onClick={toggleMenu}>
      <DropdownButton>
        <div className="restaurant-logo-wrapper" attr-style={`background-color: ${logoBackgroundColor || '#fff'};`}>
          <img
            src={logoUrl}
            alt="Restaurant Logo"
            className="restaurant-logo"
            style={{ transform: `scale(${logoScale || 1.2})` }}
          />
        </div>
        {isBusiness(user) && (
          <img
            className={'business-circle' + (getDaysLeft(user.subscriptionExpiresAt) < 30 ? ' expiring' : '')}
            src="/business-circle.svg"
          />
        )}
        <div>
          <span className="username">{user.name}</span>
          <FontAwesomeIcon className="icon" icon={faCaretDown} />
          {user.inPartyMode && (
            <div className="party-mode-indicator">
              <div className="bullet" style={{ marginRight: '6px' }} />
              În mod eveniment
            </div>
          )}
        </div>
      </DropdownButton>
      {showsMenu && (
        <Dropdown className="dropdown">
          <BoxShadowContainer>
            {user.isSuperAdmin && (
              <>
                <div className="dropdown-item-group">
                  <DropdownItem style={{ color: '#003c89' }} className="dropdown-item" onClick={() => navigate(Screen.Users)}>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Useri</span>
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    style={{ color: '#76d408' }}
                    onClick={() => navigate(Screen.BusinessOrders)}
                  >
                    <FontAwesomeIcon icon={faMoneyBill} />
                    <span>Business™</span>
                  </DropdownItem>
                </div>
                <div className="dropdown-item-group">
                  <DropdownItem
                    className="dropdown-item"
                    style={{ color: '#c52222' }}
                    onClick={() => navigate(Screen.UserActivity)}
                  >
                    <FontAwesomeIcon icon={faChartLine} />

                    <span>Activitate</span>
                  </DropdownItem>
                  <DropdownItem className="dropdown-item" style={{ color: '#11b7f3' }} onClick={() => navigate(Screen.Stats)}>
                    <FontAwesomeIcon icon={faChartSimple} />
                    <span>Statistici</span>
                  </DropdownItem>
                </div>
                <div className="dropdown-item-group" style={{ borderBottom: '5px solid rgb(237 239 241)' }}>
                  <DropdownItem
                    className="dropdown-item"
                    title="Toate Comenzile la masă"
                    onClick={() => navigate(Screen.ConsumerOrdersSuperadmin)}
                    style={{ color: '#11b7f3', position: 'relative', width: '100%' }}
                  >
                    <img src="/icons/bell-order.png" style={{ width: 22, marginLeft: 2, marginRight: '10px' }} alt="Order icon" />
                    <span style={{ maxWidth: 'none' }}>Comenzi la masă</span>
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    title="Toate Chemările de ospătar"
                    onClick={() => navigate(Screen.WaiterCallsSuperadmin)}
                    style={{ color: '#ffa622', position: 'relative', width: '100%' }}
                  >
                    <img
                      src="/icons/bell-order.png"
                      style={{ width: 22, marginLeft: 2, marginRight: '10px', filter: 'hue-rotate(186deg) saturate(1.7)' }}
                      alt="Waiter call icon"
                    />
                    <span style={{ maxWidth: 'none' }}>Chemări ospătar</span>
                  </DropdownItem>
                </div>
              </>
            )}

            {pathname !== Screen.Home && (
              <div className="dropdown-item-group home">
                <DropdownItem
                  onClick={() => navigate(Screen.Home)}
                  style={{ justifyContent: 'center', paddingRight: '16px', width: '100%', color: '#11b7f3' }}
                >
                  <FontAwesomeIcon icon={faHome} />
                  <span>Acasă</span>
                </DropdownItem>
              </div>
            )}

            <div className="dropdown-item-group" style={{ color: '#11b7f3' }}>
              <DropdownItem className="dropdown-item" title={qrCodeUrl} onClick={() => window.openMyQrCodeModal()}>
                <FontAwesomeIcon icon={faQrcode} />
                <span>Codul meu QR</span>
              </DropdownItem>

              <DropdownItem className="dropdown-item" title={menuUrl} onClick={() => window.open(menuUrl)}>
                <FontAwesomeIcon icon={faMobileAlt} />

                <span>Meniul meu</span>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="smaller" />
              </DropdownItem>
            </div>

            <div className="dropdown-item-group" style={{ color: '#7ac943' }}>
              <DropdownItem className="dropdown-item" onClick={() => window.openSettingsModal?.()}>
                <FontAwesomeIcon icon={faGear} />
                <span>Setări meniu</span>
              </DropdownItem>
              <DropdownItem
                className="dropdown-item"
                title="Personalizează logo și poza de copertă"
                style={{ color: '#7ac943' }}
                onClick={() => navigate(Screen.Personalize)}
              >
                <FontAwesomeIcon icon={faImage} />
                <span>Logo & cover</span>
              </DropdownItem>
            </div>

            <div className="dropdown-item-group">
              <DropdownItem
                className="dropdown-item"
                title={`Comenzi la masă și "Cheamă ospătarul"`}
                onClick={() => navigate(Screen.ConsumerOrders)}
                style={{ color: '#ff8922', position: 'relative' }}
              >
                <img
                  src="/icons/bell-order.png"
                  style={{ width: 22, marginLeft: 2, marginRight: '10px', filter: 'hue-rotate(180deg) saturate(1.2)' }}
                  alt="Order icon"
                />
                <span style={{ maxWidth: 'none' }}>Cheamă Ospătarul</span>
              </DropdownItem>
              {isBusiness(user) ? (
                <DropdownItem
                  className={`dropdown-item business ${showsSuccessAnimation ? 'animating' : ''}`}
                  title={`MeniuDigital Business™`}
                  onClick={() => navigate(Screen.MySubscription)}
                >
                  {showsSuccessAnimation && (
                    <div className="success-animation">
                      <Lottie
                        {...{
                          loop: false,
                          autoplay: true,
                          animationData: tickAnimation,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                      />
                    </div>
                  )}
                  <div className={`img-wrapper ${isBusiness(user) ? 'active' : ''}`}>
                    <img src="/logo-white.svg" />
                  </div>
                  <img
                    className={'business-circle small' + (getDaysLeft(user.subscriptionExpiresAt) < 30 ? ' expiring' : '')}
                    src="/business-circle.svg"
                  />
                  <span className="business-text">
                    <div style={{ fontSize: 12, marginBottom: -3, marginLeft: 1 }}>MeniuDigital</div>
                    Business
                  </span>
                </DropdownItem>
              ) : (
                <DropdownItem
                  className="dropdown-item"
                  title="Abonamentul meu"
                  onClick={() => navigate(Screen.MySubscription)}
                  style={{ color: '#ff8922', position: 'relative' }}
                >
                  <FontAwesomeIcon icon={faEuro} />

                  <span style={{ maxWidth: 'none' }}>Abonamentul meu</span>
                </DropdownItem>
              )}
            </div>

            <div className="dropdown-item-group" style={{ color: 'rgb(167, 0, 38)' }}>
              <DropdownItem
                className="dropdown-item"
                title="Vezi rezervările primite"
                onClick={() => navigate(Screen.Reservations)}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{
                    marginRight: '11px',
                    fontSize: '20px',
                    marginBottom: '1px',
                    width: '22px',
                    textAlign: 'center',
                  }}
                />
                <span>Rezervări Primite</span>
              </DropdownItem>
              <DropdownItem
                className="dropdown-item"
                title="Setări Rezervări"
                onClick={() => navigate(Screen.ReservationSettings)}
              >
                <FontAwesomeIcon icon={faGear} />
                <span>Setări Rezervări</span>
              </DropdownItem>
            </div>

            <div className="dropdown-item-group" style={{ color: '#708495' }}>
              <DropdownItem className="dropdown-item" title="Schimb parola" onClick={() => navigate(Screen.ChangePassword)}>
                <FontAwesomeIcon icon={faKey} />
                <span>Schimb parola</span>
              </DropdownItem>

              <DropdownItem className="dropdown-item" title="Contact" onClick={() => navigate(Screen.Contact)}>
                <FontAwesomeIcon icon={faPhone} />

                <span>Contact</span>
              </DropdownItem>
            </div>

            <div className="dropdown-item-group" style={{ justifyContent: 'space-between' }}>
              <DropdownItem
                title={menuUrl}
                onClick={() => window.open(menuUrl)}
                style={{
                  textTransform: 'lowercase',
                  color: '#11b7f3',
                  fontWeight: '500',
                  fontSize: '13px',
                  borderBottomWidth: 0,
                  display: 'block',
                }}
              >
                <div className="wrapper">
                  <div className="only-desktop">meniudigital.ro</div>/<b>{user.username}</b>
                </div>
                <div className="email-info">{user.email}</div>
              </DropdownItem>

              <DropdownItem
                onClick={async () => {
                  navigate(Screen.Login)
                  dispatch(logOut())
                  localStorage.clear()
                  sessionStorage.clear()
                  window.location.reload()
                }}
                style={{ justifyContent: 'flex-end', paddingRight: '16px', minWidth: 'auto' }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Delogare</span>
              </DropdownItem>
            </div>
          </BoxShadowContainer>
        </Dropdown>
      )}
      {isLoading && <LoadingProgressBar />}
    </Wrapper>
  )
}
