import React from 'react'
import Button from 'src/global/Button'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { getDaysLeft, isBusiness } from '@meniudigital/shared'
import { usersSelector } from 'src/state/selectors'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { userEndpoints } from 'src/api'
import { IS_DESKTOP_OR_TABLET } from '../..'
import { Wrapper } from './styles'

export default function SubscriptionInfo({ onRenewClick }: { onRenewClick?: () => void }) {
  const { user } = useSelector(usersSelector)
  const navigate = useNavigate()

  const goToRenewSubscription = () => {
    setTimeout(() => {
      setTimeout(() => {
        userEndpoints.registerUserAction({ actionType: 'HasClickedOnExtendBusiness', data: {} })
      }, 0)
      navigate(Screen.RenewSubscription)
    }, 0)
    onRenewClick?.()
  }

  if (!isBusiness(user)) {
    return null
  }

  return (
    <Wrapper className='subscription-duration'>
      {getDaysLeft(user?.subscriptionExpiresAt) < 30 ? (
        IS_DESKTOP_OR_TABLET ? (
          <span className="next-bill-date red">
            Expiră la {moment(user?.subscriptionExpiresAt).format('DD MMM')} (în {getDaysLeft(user?.subscriptionExpiresAt)} zile)
          </span>
        ) : (
          <span className="next-bill-date red">Expiră în {getDaysLeft(user?.subscriptionExpiresAt)} zile</span>
        )
      ) : (
        <span className="next-bill-date">
          Următoarea facturare:{' '}
          <span style={{ display: 'inline-block' }}>{moment(user?.subscriptionExpiresAt).format('DD MMM YYYY')}</span>
        </span>
      )}

      {getDaysLeft(user?.subscriptionExpiresAt) < 90 && (
        <Button
          onClick={goToRenewSubscription}
          variant="as-text"
          text={'Achită' + (IS_DESKTOP_OR_TABLET ? ' factura' : '')}
          icon={faArrowRight}
        />
      )}
    </Wrapper>
  )
}
