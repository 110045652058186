import { createGlobalStyle } from 'styled-components'
import { transition } from 'src/global/helpers'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.normalText};
  }

  
  h3 {
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
    max-width: 72vw;
  }

  h2 {
    font-weight: 600;
  }

  body,
  input,
  textarea,
  button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  body {
    background-color: ${props => props.theme.pageBackground};
  }

  ::placeholder {
    color: ${props => props.theme.placeholder};
    opacity: 1;
  }

  .only-mobile {
    display: none!important;
  }

  @media (max-width: 768px) {
    .hidden-phone {
      display: none;
    }

    .only-mobile {
      display: block!important;
    }

    .only-desktop {
      display: none!important;
    }
  }

  @media(max-width: 400px) {
    .hidden-small-phone {
      display: none!important;
    }
  }

  form {
    ${transition('filter')};
    background: ${props => props.theme.white};
    padding: 24px 32px;
    padding-bottom: 40px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
      margin-bottom: 8px;
    }

    button {
      margin-top: 32px!important;
      margin: auto;
    }

    ${props => props.loading && 'filter: grayscale(0.5) blur(5px) opacity(0.6); pointer-events: none'};

      @media(max-width: 768px) {
        width: 100%;
        padding: 16px 24px;
      }

  }

  input:not([type=checkbox]), textarea {
    ${transition('border', 'box-shadow')};

    --border: ${props => (props.error ? props.theme.error : props.theme.accent)};
    --shadow: ${props => (props.error ? props.theme.error + '4d' : props.theme.accent + '4d')};

    display: block;
    ${props =>
      props.error
        ? `
    border: 1px solid var(--border)
    `
        : `
    border: 1px solid ${props.theme.border}
  `};
    background-color: ${props => props.theme.inputBackground};
    font-size: 15px;
    color: ${props => props.theme.normalText};
    appearance: none;
    outline: none;
    resize: vertical;
    font-weight: 500;

    width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);
    height: 41px;
    padding: 8px 12px;
    border-radius: 8px;
    margin-top: 8px;
    padding-right: 40px;

    :hover,
    :focus {
      border: 1px solid var(--border);
    }

    :focus {
      box-shadow: 0 0 0 2px var(--shadow);
    }

    &[disabled] {
      cursor: default;
      box-shadow: none;
      border-color: ${props => props.theme.border};
      background-color: ${props => props.theme.pageBackground};
      opacity: 1;
    }

    @media(max-width: 768px) {
      width: 100%;
    }
  }

 
  .page {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: ${props => props.theme.normalText};
    width: 850px;
    max-width: 100vw;
    overflow: hidden;
    margin: auto;
    padding: 8px 24px;
    padding-bottom: 16px;
    animation: slide-in 0.8s cubic-bezier(0.25, 1, 0.320, 1) 0s;
    padding-top: 24px;
    transform: translate(100vw);
    animation-fill-mode: forwards;
    min-height: calc(100vh - 58px);

    @media (max-width: 768px) {
      box-shadow: -4px 0 12px ${props => props.theme.shadow};
    }

    @media (max-height: 1100px) {
      transform: none;
      animation: none;
    }

    @media (max-height: 768px) {
      padding-bottom: 90px;
    }
  
    @keyframes slide-in {
      0% {
        transform: translate(100vw);
      }
  
      99% {
        transform: unset;
      }
      100% {
        transform: unset;
      }
    }
  }

  .lighter {
    font-weight: 400;
  }

  .normal-weight {
    font-weight: 500;
  }

  .bolder {
    font-weight: 600;
  }

  label {
    font-size: 11px;
    color: ${props => props.theme.label};
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
    margin-top: 16px;

    &[aria-required=true] {
      &:after {
        content: '*';
        color: ${props => props.theme.red};
        margin-left: 2px;
        position: relative;
        top: -2px;
      }
    }
  }
  

  .required-indicator {
    color: ${props => props.theme.red};
    vertical-align: top;
    margin-top: -2px;
    margin-left: 1px;
    display: inline-block;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &.wrap {
      flex-wrap: wrap;
    }
  }

  @keyframes tick {
    0% {
      transform: scale(0);
    }
    90% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }


  .panel {
    width: 630px;
    max-width: calc(100vw - 32px);
    padding: 8px 24px;
    background: ${props => props.theme.white};
    font-weight: 700;
    border-radius: 8px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;

      span:first-of-type {
        flex: 1
      }
     
      .quantity {
        width: 27%;
      }

      .muted {
        font-weight: 400;
        text-align: right;
        color: ${props => props.theme.mutedText}
      }

      .small {
        font-size: 12px;
        margin-right: 2px;
        line-height: 18px;
        vertical-align: top;
      }

      &.strong {
        font-weight: 600;
      }

      span {
        text-align: left;
        max-width: calc(100vw - 220px);
      }
    }

    &.condensed {
      padding-bottom: 16px;

      .line {
        padding-top: 12px;
        padding-bottom: 6px;
      
      }
    }

    &.compact {
      padding: 8px 0;
      width: auto;
      min-width: min(calc(100vw - 81px), 320px);
      background: transparent;

      .line {
        padding: 8px 0;
      }
    }
  }

  button.continue-button {
    margin-top: 32px;

    @media (max-height: 768px) {
      margin-top: 0;
      position: fixed;
      bottom: 16px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 250;
      width: 150px;
      justify-content: center;
      box-shadow: 0 0px 4px #454f5b44;
    }
  }

  a {
    color: ${props => props.theme.accent};
    font-weight: 600;

    :hover {
      text-decoration: none;
    }
  }

  [aria-label=animation] {
    margin-top: -24px;
    border-radius: 50px;
  }

  .error {
    color: ${props => props.theme.red};

    @media(min-width: 768px) {
      padding: 0 32px;
    }
    
  }

  .bullet {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    display: inline-block;
    background-color: ${props => props.theme.accent};
    margin-bottom: 2px;
    animation: pulse 3s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${props => props.theme.accent}66;
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }

  @keyframes pulse-white {
    0% {
      box-shadow: 0 0 0 0 ${props => props.theme.white}99;
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }

  .background-waves {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(46.5%) scaleY(0.5);
    @media(max-width: 768px) {
      transform: translateY(39%) scaleY(1) scaleX(1.5);
    }

    img {
      width: 100%;
    }

    z-index: 0;
    width: 100%;

    ~ * {
      z-index: 1;
    }
  }

  .react-toggle-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: 16px;

    span {
      margin-left: 8px;
    }

    .react-toggle {
      .react-toggle-thumb {
        height: 28px;
        width: 28px;
      }

      .react-toggle-track {
        height: 30px;
        width: 56px;
        background-color: ${props => props.theme.mutedText}88;
      }

      &.react-toggle--focus .react-toggle-thumb {
        box-shadow: none !important;
      }

      &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${props => props.theme.mutedText}44;
      }

      &:not(.react-toggle--checked) .react-toggle-thumb {
        border-color: ${props => props.theme.mutedText}88;
      }

      &.react-toggle--checked {
        &:hover:not(.react-toggle--disabled) .react-toggle-track {
          background-color: ${props => props.theme.accent}bb;
        }
        .react-toggle-thumb {
          border-color: ${props => props.theme.accent};
        }

        .react-toggle-track {
          background-color: ${props => props.theme.accent};
        }
      } 
    } 
  }

  .background-emoji {
    position: absolute;
    bottom: -40px;
    left: -60px;
    font-size: 160px;
    opacity: 0.25;
    transform: rotate(-42deg);

    ~ * {
      z-index: 1;
    }
  }

  span.error {
    display: inline-block;
    width: 100%;
    margin-top: 8px;
    color: ${props => props.theme.red};
    font-weight: 500;
    text-align: center;
  }

  .beta-tag {
    background: #ff8922;
    text-transform: uppercase;
    border-radius: 16px;
    padding: 2px 8px;
    margin-left: 8px;
    display: inline-block;
    color: ${props => props.theme.white}!important;
    font-size: 11px;
  }
`

export default GlobalStyle
