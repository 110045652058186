import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import { CurrencyCode, UserPatchType } from '@meniudigital/shared'
import { Wrapper } from './styles'

export default function CurrencyToggle() {
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(user?.defaultCurrency || CurrencyCode.Ron)

  const displayedCurrencies = Object.values(CurrencyCode).reverse()

  const selectCurrencyCode = async (code: CurrencyCode) => {
    try {
      await window.showModal({
        title: `Schimbare monedă meniu`,
        text: `Ești sigur că dorești schimbarea monedei meniului tău în ${code.toUpperCase()}?`,
        confirmButtonText: 'Da, schimbă',
        cancelButtonText: 'Nu',
      })

      setCurrencyCode(code)
      dispatch(patchCurrentUser({ type: UserPatchType.DefaultCurrency, data: code }))
    } catch (e) {}
  }

  const activeIndex = Object.values(CurrencyCode).indexOf(currencyCode)

  return (
    <Wrapper>
      <div className="rail" style={{ width: displayedCurrencies.length * 50 }}>
        <div
          className="active-indicator"
          style={{
            width: 100 / displayedCurrencies.length + '%',
            transform: `translateX(calc(-${100 * activeIndex + (activeIndex > 0 ? 3 : 0)}%))`,
          }}
        />

        {displayedCurrencies.map(x => (
          <div key={x} className={'currency-icon' + (currencyCode === x ? ' active' : '')} onClick={() => selectCurrencyCode(x)}>
            {x}
          </div>
        ))}
      </div>
    </Wrapper>
  )
}
