import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 15vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 460px;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};
  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
  }

  .category {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 4px 12px ${props => props.theme.shadow};
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 10px 12px;
    margin-top: 8px;
    border: 1px solid ${props => props.theme.border};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    font-weight: 500;
    color: ${props => props.theme.normalText};
    cursor: pointer;
    
    :hover {
      background: #22181808;
    }
  }

  &.closed {
    pointer-events: none;
    opacity: 0;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const ModalContent = styled.div`
  padding: 24px;
  position: relative;
  max-height: calc(90vh - 180px);
  overflow: auto;

  .title {
    font-size: 22px;
    font-weight: 500;
  }

  .subtitle {
    color: ${props => props.theme.mutedText};
    margin: 8px 0;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;

    .fa {
      margin-right: 6px;
    }
  }

  input {
    margin-bottom: 24px;
  }

  .question {
    margin-bottom: 16px;
    margin-top: 40px;
    font-size: 14px;
  }
`

export const ModalText = styled.div`
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
