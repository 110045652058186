import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .page {
    font-weight: 400;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding: 20px 10vw;
    padding-top: 44px;

    button.loading {
      padding: 0 48px;
    }

    h1 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 40px;
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 16px;
      color: #11b7f3;
      font-size: 24px;
    }

    h2 ~ span {
      margin-top: 8px;
    }

    .business-button {
      background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      padding-left: 26px;
      padding-right: 38px;
      border-radius: 32px;
      cursor: pointer;
      margin-top: 8px;
      position: relative;
      height: 48px;
      display: flex;
      align-items: center;

      a {
        color: #fff;
      }

      &:hover {
        opacity: 0.9;
      }

      img {
        width: 25px;
        margin-right: 14px;
      }

      .brand-texts {
        .brand-name {
          font-size: 12px;
          margin-bottom: -3px;
          text-align: left;
        }
      }

      .icon-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -12px;
        top: 12px;
        background: ${props => props.theme.white};
        border-radius: 100%;
        padding-bottom: 1px;

        svg {
          color: ${props => props.theme.accent};
          font-size: 13px;
        }
      }
    }
  }
`
