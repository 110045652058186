import React from 'react'
import { MenuProduct } from '@meniudigital/shared'
import { Wrapper, FormTextarea } from './styles'

type Props = {
  label: string
  product: MenuProduct
  for: string | ((product: MenuProduct) => string)
  required?: boolean
  invalid?: boolean
  suffix?: string | React.ReactElement
  rightButton?: React.ReactElement
  asNumber?: boolean
  fullWidth?: boolean
  disabled?: boolean
  maxLength?: number
  max?: number
  description?: string
  multiline?: boolean
  placeholder?: string
  style?: any
  setProduct?: React.Dispatch<React.SetStateAction<MenuProduct>> | ((modifiedProduct: MenuProduct) => void)
  setProperty?: (newValue: string) => void
}

export default function FormField({
  label,
  for: propertyName,
  required,
  invalid,
  suffix,
  rightButton,
  asNumber,
  fullWidth,
  maxLength,
  max,
  style,
  product,
  disabled,
  placeholder,
  setProperty,
  setProduct,
  ...otherInputProps
}: Props) {
  const currentValue = typeof propertyName === 'function' ? propertyName(product!) : (product as any)[propertyName]

  const inputProps = {
    className: [asNumber ? 'small' : ''].join(' '),
    value: typeof currentValue === 'undefined' ? '' : currentValue,
    resizible: 'false',
    placeholder,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value

      if (asNumber && !/^(\d)*(\.)?(\d)*$/.test(newValue)) {
        return
      }

      if (maxLength && (newValue + '').length > maxLength) {
        return
      }

      if (max && +newValue > max) {
        return
      }

      if (setProperty) {
        setProperty(newValue)
      } else {
        setProduct?.({ ...product, [propertyName as string]: newValue })
      }
    },
    ...otherInputProps,
  }

  return (
    <Wrapper
      className={`
        ${asNumber ? 'small' : ''} 
        ${fullWidth ? 'full-width' : ''} 
        ${suffix ? 'with-suffix' : ''} 
        ${rightButton ? 'with-right-button' : ''} 
        ${required ? 'required' : ''}
        ${invalid ? 'invalid' : ''}
        ${disabled ? 'disabled' : ''}
        `}
      style={style}
    >
      {label && (
        <label>
          {label}
          {required ? <span className="required-indicator"> *</span> : null}
        </label>
      )}
      <div className="input-wrapper">
        {/* @ts-ignore */}
        {otherInputProps.multiline ? <FormTextarea {...inputProps} /> : <input {...inputProps} />}
        {suffix && <div className="suffix">{suffix}</div>}
        {rightButton}
      </div>
    </Wrapper>
  )
}
