const lightColors = {
  error: '#ff5723',
  normalText: '#353f4b',
  backdrop: '#454f5bbb',
  mutedText: '#708495',
  businessGray: '#586d7e',
  border: '#dde2e5',
  accent: '#11b7f3',
  accentForHover: '#ecf8ff',
  green: '#7ac943',
  teal: '#32bd98',
  pageBackground: '#ededf2',
  disabledButton: '#d3d8dc',
  white: '#ffffff',
  blue: '#11b7f3',
  darkBlue: '#33395c',
  red: '#ff5723',
  redOrange: '#ff8324',
  orange: '#ffa622',
  activeBackground: '#f8f9f9',
  inputBackground: '#fafbfb',
  shadow: 'rgba(0, 0, 0, 0.05)',
  middleShadow: 'rgba(0, 0, 0, 0.10)',
  darkerShadow: 'rgba(0, 0, 0, 0.25)',
  darkLogoDisplay: 'inline-block',
  lightLogoDisplay: 'none',
  placeholder: '#90a4ae',
  label: '#4e7183',
  iosDarkGray: '#e0dfe8',
  disabledOnGrey: '#6a6877',
  darkRed: 'rgb(167, 0, 38)',
  disabledRedHue: '#847e7e',
}

const darkColors: Record<keyof typeof lightColors, string> = {
  error: '#ff5723',
  normalText: '#ffffff',
  mutedText: '#b0b8bf',
  businessGray: '#ffffff',
  backdrop: '#fff6',
  border: '#1b1b1b',
  accent: '#11b7f3',
  accentForHover: '#1f3842',
  green: '#7ac943',
  teal: '#32bd98',
  pageBackground: '#1b1b1b',
  disabledButton: '#d3d8dc',
  white: '#313131',
  blue: '#11b7f3',
  darkBlue: '#ffffff',
  red: '#ff5723',
  redOrange: '#ff8324',
  orange: '#ffa622',
  activeBackground: '#3f3f3f',
  inputBackground: '#212121',
  shadow: 'rgba(0, 0, 0, 0.4)',
  middleShadow: 'rgba(0, 0, 0, 0.5)',
  darkerShadow: 'rgba(0, 0, 0, 0.8)',
  lightLogoDisplay: 'inline-block',
  darkLogoDisplay: 'none',
  placeholder: '#9e9e9e',
  label: '#ffffff',
  iosDarkGray: '#464749',
  disabledOnGrey: '#949494',
  darkRed: 'rgb(167, 0, 38)',
  disabledRedHue: '#847e7e',
}

const globals = {
  easing: 'cubic-bezier(0.25, 1, 0.32, 1)',
}

const theme = (isDark: boolean) => ({
  ...globals,
  ...(isDark ? darkColors : lightColors),
})

export default theme
