import { LanguageCode, MenuCategory, MenuProduct } from '@meniudigital/shared'
import { State } from '../index'

export const productsSelector = (state: State) => {
  const { products } = state

  const compatibleList = (products.list || []).map(withNames)

  return { ...products, list: compatibleList } as State['products']
}

export const categoriesSelector = (state: State) => {
  const { categories } = state

  const compatibleList = (categories.list || []).map(withNames)

  return { ...categories, list: compatibleList } as State['categories']
}

export const usersSelector = (state: State) => {
  const { users } = state

  return users as State['users']
}

export const themeSelector = (state: State) => {
  const { theme } = state

  return theme as State['theme']
}

export const featureVotesSelector = (state: State) => {
  const { featureVotes } = state

  return featureVotes as State['featureVotes']
}

function withNames(entity: MenuProduct | MenuCategory) {
  return {
    ...entity,
    names: entity.names || { [LanguageCode.Romanian]: (entity as any).name, [LanguageCode.English]: (entity as any).nameEn },
    descriptions: entity.descriptions || {
      [LanguageCode.Romanian]: (entity as any).description,
      [LanguageCode.English]: (entity as any).descriptionEn,
    },
  }
}
