import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;

    button {
      .icon {
        margin-top: 4px;
        color: ${props => props.theme.accent}!important;
        height: 15px;
      }
    }
  }

  .button-menu-wrapper {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: -10px;
    border-radius: 24px;
    cursor: pointer;

    button {
      svg {
        /* transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1); */
      }
    }

    :hover {
      button {
        border-color: ${props => props.theme.accent};

        svg {
          transform: scale(0.85);
        }
      }
    }

    .dropdown-menu {
      border-radius: 16px;
      position: absolute;
      top: 52px;
      right: 18px;
      box-shadow: 0 2px 12px ${props => props.theme.darkerShadow};
      font-weight: 500;
      font-size: 15px;
      padding: 4px 0px;
      background: ${props => props.theme.white};
      z-index: 100;
      overflow: hidden;

      transform: translate(50%, -50%) scale(0);
      transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1);

      @media (max-width: 768px) {
        right: auto;
        left: -100px;
      }

      &.open {
        transform: translate(0) scale(1);
      }

      .menu-item {
        padding: 12px 40px;
        padding-left: 12px;
        white-space: nowrap;

        &:hover {
          background: ${props => props.theme.activeBackground};
        }

        svg {
          margin-right: 12px;
          width: 20px;
          color: ${props => props.theme.accent};
        }
      }

      .separator {
        height: 1px;
        width: 100%;
        background: ${props => props.theme.border};
        margin: 4px 0;
      }
    }
  }
`
