import { Wrapper } from './styles'
import { DaySchedule, formatSchedule } from '@meniudigital/shared'

type Props = {
  schedule: DaySchedule[]
}

export default function SchedulePills({ schedule }: Props) {
  return (
    <Wrapper className="day-pills">
      {formatSchedule(schedule).map(({ dayString, hoursString }, i) => (
        <div key={dayString} className="day-pill">
          <div className="day-letter">
            {i === 0 && <img src="/icons/clock-white.png" alt="Clock icon" />}
            {dayString}
          </div>
          <div className="hour-interval">{hoursString}</div>
        </div>
      ))}
    </Wrapper>
  )
}
