import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  .page {
    width: auto;
  }

  .panel {
    h3 {
      margin-bottom: 0;
    }

    &.prefer-no-holders {
      padding: 32px;
      padding-top: 24px;
      padding-bottom: 16px;
      text-align: center;
      line-height: 32px;
      margin-bottom: 24px;

      button {
        margin-top: 32px;
      }

      .button-like {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        box-shadow: 0 2px 12px #0002;
        padding: 2px 12px;
        /* margin: -8px; */
        border-radius: 8px;
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;

        :hover {
          background-color: ${props => props.theme.activeBackground};
        }

        .icon {
          margin-left: 4px;
        }
      }
    }
  }

  p.info {
    font-size: 14px;
    margin-bottom: 24px;
    width: 450px;
    max-width: 90vw;
    text-align: center;

    .icon {
      margin-right: 5px;
      color: ${props => props.theme.accent};
    }
  }

  .no-stock-tag {
    background: orange;
    position: absolute;
    color: #fff;
    bottom: 70px;
    right: 0;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .no-stock {
    color: #fff;
    background: #ff3333;
    font-size: 13px;
    font-weight: 600;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .wifi-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;

    .react-toggle-wrapper {
      margin-top: 0;
    }

    .icon {
      font-size: 16px;
      color: ${props => props.theme.accent};
    }

    span {
      padding: 0 8px;
    }
  }
`
