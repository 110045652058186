import React, { useState } from 'react'

import { Wrapper } from './styles'
import Label from 'src/global/form/Label'

type Props = {
  onUpdate: (reasonText: string) => void
  type: string
}

export default function ReasonBox(props: Props) {
  const [rejectionReason, setRejectionReason] = useState('')

  return (
    <Wrapper>
      <Label>Mențiune (Opțional)</Label>
      <input
        placeholder={props.type === 'rejected' ? 'e.g. Scuze, nu mai avem mese disponibile' : 'e.g. Vă așteptăm cu drag! 😊'}
        value={rejectionReason}
        onChange={e => {
          setRejectionReason(e.target.value)
          props.onUpdate(e.target.value)
        }}
        style={{ paddingRight: 8 }}
      />
    </Wrapper>
  )
}
