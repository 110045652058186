import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  padding: 0 8px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .label-text {
    color: ${props => props.theme.normalText};
  }

  label.checkbox {
    cursor: pointer;
    display: block;
    font-size: 16px;
    text-transform: none;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;

    input[type='checkbox'] {
      display: none;

      + .label-text:hover:before {
        color: ${props => props.theme.accent};
      }

      + .label-text:before {
        content: '\f096';
        font-family: 'fontAwesome';
        line-height: 1;
        display: inline-block;
        font-size: 26px;
        margin-right: 8px;
        position: relative;
        top: 4px;
        height: 40px;
      }

      :checked + .label-text:before {
        content: '\f14a';
        color: ${props => props.theme.accent};
        animation: tick 150ms ease-in;
      }

      :disabled + .label-text:before {
        content: '\f0c8';
        color: #dddfe6;
      }
    }
  }
`
