import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .panel {
    padding: 40px;
    font-size: 20px;

    .icon {
      margin-bottom: 24px;
      color: ${props => props.theme.accent};
    }
  }

  button {
    align-self: center;
  }

  .error {
    max-width: 340px;
    font-size: 14px;
    margin-top: 8px;
  }

  .email {
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 600
  }

  .url-explainer {
    max-width: 340px;
    font-size: 14px;
    font-style: italic;
  }

  input {
    padding-right: 8px;
  }

  span {
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
  }
`
