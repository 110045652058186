import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import Toggle from 'react-toggle'
import { UserPatchType, isBusiness } from '@meniudigital/shared'
import Button from 'src/global/Button'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import SchedulePills from 'src/screens/SetScheduleScreen/SchedulePills'
import { IS_DESKTOP_OR_TABLET } from '..'

export default function ScheduleSetting() {
  const dispatch = useDispatch()

  const { user } = useSelector((state: State) => state.users)
  const navigate = useNavigate()

  const goToSchedulePicker = () => {
    window.hideSettingsModal()
    navigate(Screen.SetSchedule)
  }

  if (!user) {
    return null
  }

  const { schedule } = user

  const toggleSchedule = () => {
    const payload = {
      type: UserPatchType.Schedule,
      data: { ...(schedule || {}), isVisible: !schedule?.isVisible },
    }
    dispatch(patchCurrentUser(payload as any))
  }

  return (
    <div
      className={`setting grouped ${!isBusiness(user) ? 'disabled-in-plan' : ''}`}
      onClick={() => !isBusiness(user) && window.openBusinessModal('schedule')}
      title={
        !isBusiness(user)
          ? 'Programul de lucru afișsat pe meniu este disponibil doar în planul Business.'
          : 'Programul de lucru afișsat pe meniu'
      }
    >
      <div className="business-tag">
        <img src="/logo-white.svg" />
        {!isBusiness(user) && IS_DESKTOP_OR_TABLET && <div className="business-text">Business</div>}
      </div>

      <div className="left-side">
        <div className="setting-name">
          <div className="setting-icon">
            <img src="/icons/calendar.png" alt="Calendar icon" />
          </div>
          Programul de lucru
        </div>
        {Boolean(schedule?.schedule.length) && <SchedulePills schedule={schedule?.schedule!} />}

        <Button
          variant="as-text"
          text={`${schedule?.schedule.length ? 'Schimbă' : 'Setează'} programul de lucru...`}
          onClick={goToSchedulePicker}
        />
      </div>

      <div className="right-side" style={{ position: 'absolute', top: 14, right: 16, zIndex: 10 }}>
        <span className="only-desktop">{schedule?.isVisible ? 'Afișat pe meniu' : 'Ascuns'}</span>
        <Toggle className="toggle" icons={false} checked={schedule?.isVisible} onChange={toggleSchedule}></Toggle>
      </div>
    </div>
  )
}
