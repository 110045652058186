import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { statEndpoints, userEndpoints } from 'src/api'
import * as Sentry from '@sentry/react'
import { BusinessLink, LanguageCode, ProductSuggestion, User, UserDevice, UserPatchType } from '@meniudigital/shared'
import { hardLogOut } from 'src/api/methods'

export type StatsByReferral = {
  total: number
  facebook: number
  google: number
  none: number
}

export type StatBusinessAggregates = {
  totalActiveSubscriptions: number
  totalExpiredSubscriptions: number
}

export type StatDailyBusinessPurchase = {
  date: string // Assuming this already exists
  new6: number // New Business 6 subscriptions
  new12: number // New Business 12 subscriptions
  renew6: number // Renewed Business 6 subscriptions
  renew12: number // Renewed Business 12 subscriptions
}

export type StatNewRegistrationsAggregates = {
  today: StatsByReferral
  yesterday: StatsByReferral
  'day-before-yesterday': StatsByReferral
  'thirty-days': StatsByReferral
}

export type State = {
  token?: string
  user?: User
  list: User[]
  isLoading: boolean
  stats?: StatNewRegistrationsAggregates
}

export const initialState: State = {
  token: undefined,
  user: undefined,
  list: [],
  isLoading: false,
  stats: undefined,
}

function sortUsers(a: User, b: User) {
  return +new Date(b.joinDate) > +new Date(a.joinDate) ? 1 : -1
}

export const login = createAsyncThunk('users/login', userEndpoints.login)
export const getCurrentUser = createAsyncThunk('users/getCurrentUser', userEndpoints.getCurrentUser)
export const updateCurrentUser = createAsyncThunk('users/updateCurrentUser', userEndpoints.updateCurrentUser)
export const patchCurrentUser = createAsyncThunk('users/patchCurrentUser', userEndpoints.patchCurrentUser)
export const patchUser = createAsyncThunk('users/patchUser', userEndpoints.patchUser)
export const createAccountAndLogin = createAsyncThunk('users/createAccountAndLogin', userEndpoints.createAccountAndLogin)
export const confirmEmail = createAsyncThunk('users/confirmEmail', userEndpoints.confirmEmail)
export const setLogo = createAsyncThunk('users/setLogo', userEndpoints.setLogo)
export const setCoverPhoto = createAsyncThunk('users/setCoverPhoto', userEndpoints.setCoverPhoto)
export const setProductSuggestion = createAsyncThunk('users/setProductSuggestion', userEndpoints.setProductSuggestion)
export const setMenuLanguages = createAsyncThunk('users/setMenuLanguages', userEndpoints.setMenuLanguages)
export const setBusinessLink = createAsyncThunk('users/setBusinessLink', userEndpoints.setBusinessLink)
export const getMyStats = createAsyncThunk('users/getMyStats', userEndpoints.getMyStats)
export const getUsers = createAsyncThunk('users/getAll', userEndpoints.getAll)
export const getStatRegistrationsAggregate = createAsyncThunk(
  'users/getStatRegistrationsAggregate',
  statEndpoints.getRegistrationsAggregate
)
export const removeDevice = createAsyncThunk('users/removeDevice', userEndpoints.removeDevice)

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    logOut() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localStorage happens in rootReducer.
    },
    setToken(state, { payload }: PayloadAction<string>) {
      state.token = payload
      localStorage.token = payload
    },
  },
  extraReducers: actions => {
    // PENDINGS & REJECTEDS
    ;[
      ...[login, setLogo, setCoverPhoto, getCurrentUser],
      ...[setMenuLanguages, setProductSuggestion, confirmEmail],
      ...[getUsers, getStatRegistrationsAggregate, patchUser, removeDevice],
    ].forEach(reducerAction => {
      actions.addCase(reducerAction.pending, (state: State) => {
        state.isLoading = true
      })
      actions.addCase(reducerAction.rejected, (state: State) => {
        state.isLoading = false
      })
    })
    actions.addCase(patchCurrentUser.pending, (state, action: any) => {
      const { type, data } = action.meta.arg
      if (type === UserPatchType.Location && state.user?.coords) {
        state.user.coords.isVisible = data.isVisible
      }
      if (type === UserPatchType.WifiInfo && state.user?.wifiInfo) {
        state.user.wifiInfo.isVisible = data.isVisible
      }
      if (type === UserPatchType.Schedule && state.user?.schedule) {
        state.user.schedule.isVisible = data.isVisible
      }
    })
    actions.addCase(setBusinessLink.pending, (state, action: any) => {
      const { type, isVisible } = action.meta.arg

      const updatedSocial = state.user?.businessInfo?.find(x => x.type === type)
      if (updatedSocial) {
        updatedSocial.isVisible = isVisible
      }
    })

    // Successes
    const onLogin = (state: State, { payload }: PayloadAction<string>) => {
      state.isLoading = false
      state.token = payload

      localStorage.token = payload
    }

    actions.addCase(login.fulfilled, onLogin)
    actions.addCase(createAccountAndLogin.fulfilled, onLogin)
    actions.addCase(getCurrentUser.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.isLoading = false

      if (payload) {
        state.user = payload

        const { newAuthToken } = payload

        if (newAuthToken) {
          state.token = newAuthToken
          localStorage.token = newAuthToken
        }

        if (process.env.REACT_APP_BANNED_USERNAME === payload.username) {
          return hardLogOut()
        }

        Sentry.setUser({ ...payload })
      }
    })
    actions.addCase(updateCurrentUser.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.isLoading = false

      if (payload) {
        state.user = payload

        Sentry.setUser({ ...payload })
      }
    })
    actions.addCase(patchCurrentUser.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.isLoading = false

      if (payload) {
        state.user = payload

        Sentry.setUser({ ...payload })
      }
    })
    actions.addCase(patchUser.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.isLoading = false
      const updatedUser = { ...state.list.find(x => x.id === payload.id) } as User
      updatedUser.name = payload.name
      updatedUser.city = payload.city
      updatedUser.coords = payload.coords
      state.list = [...state.list.filter(x => x.id !== payload.id), updatedUser].sort(sortUsers)
    })
    actions.addCase(setLogo.fulfilled, state => {
      state.isLoading = false
    })
    actions.addCase(setCoverPhoto.fulfilled, state => {
      state.isLoading = false
    })
    actions.addCase(setMenuLanguages.fulfilled, (state, { payload }: PayloadAction<{ newLanguages: LanguageCode[] }>) => {
      state.isLoading = false
      state.user!.menuLanguages = payload.newLanguages
    })
    actions.addCase(setBusinessLink.fulfilled, (state, { payload }: PayloadAction<{ newBusinessInfo: BusinessLink[] }>) => {
      state.isLoading = false
      state.user!.businessInfo = payload.newBusinessInfo
    })
    actions.addCase(confirmEmail.fulfilled, (state, { payload }: PayloadAction<{ successfullyConfirmed: boolean }>) => {
      state.isLoading = false

      if (payload.successfullyConfirmed && state.user) {
        state.user!.isEmailConfirmed = payload.successfullyConfirmed
      }
    })
    actions.addCase(
      setProductSuggestion.fulfilled,
      (state, { payload }: PayloadAction<{ newProductSuggestion: ProductSuggestion }>) => {
        state.isLoading = false
        state.user!.activeProductSuggestion = payload.newProductSuggestion
      }
    )
    actions.addCase(getUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
      state.isLoading = false
      const pageNumber = (action as any).meta?.arg?.pageNumber || 0
      state.list = [...(pageNumber ? state.list : []), ...action.payload]
    })
    actions.addCase(
      getStatRegistrationsAggregate.fulfilled,
      (state, { payload }: PayloadAction<StatNewRegistrationsAggregates>) => {
        state.isLoading = false
        state.stats = payload
      }
    )

    actions.addCase(removeDevice.fulfilled, (state, { payload }: PayloadAction<UserDevice>) => {
      state.isLoading = false
      state.user!.devices = state.user!.devices.filter(x => x.deviceId !== payload.deviceId)
    })
  },
})
export const { logOut, setToken } = slice.actions
export default slice.reducer
