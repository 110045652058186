import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .title {
    margin-bottom: 40px;
    margin-top: 16px;
  }

  p {
    line-height: 26px;
    padding: 0 50px !important;

    @media (max-width: 768px) {
      padding: 0 24px !important;
    }
  }

  .error {
    color: ${props => props.theme.red};
  }

  .top-left-business {
    background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 10px 26px;
    padding-left: 18px;
    border-bottom-right-radius: 16px;

    img {
      width: 28px;
      margin-right: 11px;
    }

    .brand-texts {
      .brand-name {
        font-size: 12px;
        margin-bottom: -3px;
        text-align: left;
      }
    }
  }
`
