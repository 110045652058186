import React, { useRef } from 'react'

import { Line, getElementAtEvent } from 'react-chartjs-2'
import { NavigationStatsObject } from 'src/api'

export default function TotalVisitsPerDay({ data }: { data: NavigationStatsObject['totalVisitsPerDay'] }) {
  const chartRef = useRef()

  return (
    <Line
      ref={chartRef}
      width={Math.max(window.innerWidth - 120, 370)}
      height={window.innerHeight / 3}
      onClick={e => {
        const { index } = getElementAtEvent(chartRef.current!, e)[0]
        const username = data[index].label
        window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${username}`)
      }}
      options={{
        responsive: false,
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'start',
          },
          title: {
            position: 'top',
            align: 'start',
            display: true,
            text: `Total daily menu visits - daily - last 30 days`,
          },
        },
      }}
      data={{
        labels: data.map(x => x.label),
        datasets: [
          {
            label: 'Unique Users',
            data: data.map(x => x.uniqueUserCount),
            backgroundColor: '#003c89',
          },
          {
            label: 'Visits',
            data: data.map(x => x.visitCount),
            backgroundColor: '#11b7f3',
          },
        ],
      }}
    />
  )
}
