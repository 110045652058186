import React, { useCallback, useRef, useState } from 'react'
import { Wrapper } from './styles'
import { debounce } from 'throttle-debounce'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import CloseButton from 'src/global/CloseButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { productsSelector } from 'src/state/selectors'

type Props = {
  onQueryChange: (query: string) => void
}
export default function SearchInput(props: Props) {
  const [inputValue, setInputValue] = useState<string>('')

  const { list: products } = useSelector(productsSelector)

  const onQueryChangeDebounced = useCallback(debounce((products.length || 0) / 2, props.onQueryChange), [])

  const inputRef = useRef<HTMLInputElement>(null)

  const setValue = (newValue: string) => {
    setInputValue(newValue)

    onQueryChangeDebounced(newValue)
  }

  const clearValue = () => {
    setInputValue('')

    setTimeout(() => {
      props.onQueryChange('')
      inputRef?.current?.blur()
    }, 500)
  }

  return (
    <Wrapper className="search-input-wrapper">
      <input
        ref={inputRef}
        title="Caută..."
        className="search-input data-hj-allow"
        placeholder="Caută produs..."
        value={inputValue}
        onChange={e => setValue(e.target.value)}
      />
      <CloseButton title="Șterge" onClick={clearValue} />
      <FontAwesomeIcon style={{ marginRight: '8px', fontSize: '18px' }} color="#11b7f3" icon={faSearch} />
    </Wrapper>
  )
}
