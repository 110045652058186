import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .page {
    font-weight: 400;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding: 20px 10vw;
    padding-top: 44px;

    h1 {
      margin-bottom: 80px;
      color: #11b7f3;
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 16px;
      color: #11b7f3;
      font-size: 24px;
    }
  }
`
