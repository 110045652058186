import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;

  .product-name-row {
    align-items: flex-start;
    flex: 1;
  }

  .product-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    padding-left: 16px;
    background-color: white;
    overflow: visible;
    z-index: 2;
    max-width: 100%;
  }

  .left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin-right: 8px;
    position: relative;
    z-index: 3;
    background-color: #fff;
  }

  .product-name {
    font-weight: 600;
    font-size: 16px;
    flex: 1;
  }

  .variant-tag {
    background-color: ${props => props.theme.placeholder};
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 16px;
    margin-top: 1px;
    padding-bottom: 2px;
    margin-bottom: 2px;
  }

  .variant-tag-text {
    color: white;
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
  }

  .product-image-wrapper {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${props => props.theme.iosDarkGray};
  }

  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .total-tag {
    height: 22px;
    padding: 0 8px;
    background-color: ${props => props.theme.blue};
    margin-right: 2px;
    border-radius: 80px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: 14px;
    flex-direction: row;
    z-index: 1;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }

  .total-tag-text {
    color: ${props => props.theme.white};
    font-family: 'semibold';
    font-size: 14px;
  }

  .action-text {
    color: white;
    font-size: 16px;
  }

  .action-icon {
    width: 30px;
    height: 30px;
    margin-right: 24px;
    justify-content: center;
    align-items: center;
  }

  .inline-quantity {
    font-size: 16px;
    color: ${props => props.theme.blue};
    font-weight: 600;
  }

  .connector-wrapper {
    width: 8px;
    height: 24px;
    margin-right: 6px;
    margin-left: 8px;
    overflow: hidden;
    position: relative;
    align-self: flex-start;
  }

  .connector {
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px solid ${props => props.theme.blue};
    border-left: 2px solid ${props => props.theme.blue};
    width: 22px;
    height: 60%;
    border-bottom-left-radius: 6px;
  }

  .connector-continuation {
    position: absolute;
    left: 24px;
    right: 0;
    width: 120%;
    border-left: 2px solid ${props => props.theme.blue};
    height: 100%;
    bottom: -8px;
  }

  .side-row {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .price {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: right;

    .currency {
      font-weight: 400;
      font-size: 14px;
    }
  }
`
