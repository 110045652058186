import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login, getCurrentUser } from 'src/state/users'
import Button from 'src/global/Button'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Wrapper } from './styles'
import useForm from 'src/helpers/useForm'
import { State } from 'src/state'

export default function Login() {
  const { register, setValue, formValues } = useForm()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const { isLoading } = useSelector((state: State) => state.users)

  useEffect(() => {
    setTimeout(() => {
      if (window.refreshDataInterval) {
        clearInterval(window.refreshDataInterval)
      }
    }, 300)

    if (params.email) {
      setValue('username', decodeURIComponent(params.email))
    }
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const attemptLogin = async () => {
    await dispatch(login({ username: formValues.username.value, password: formValues.password.value }))

    setTimeout(async () => {
      await dispatch(getCurrentUser())
      navigate(Screen.Home)
    }, 0)
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      attemptLogin()
    }
  }

  return (
    <Wrapper onKeyDown={onKeyDown}>
      <div className="page">
        <form>
          <label>id restaurant / e-mail</label>
          <input
            type="text"
            autoFocus={Boolean(!params.email)}
            placeholder="id restaurant / email"
            autoCapitalize="off"
            {...register('username')}
            onFocus={() => {}}
          />

          <label>parolă</label>
          <input
            autoFocus={Boolean(params.email)}
            type="password"
            placeholder="parolă"
            autoCapitalize="off"
            autoComplete="current-password"
            {...register('password')}
            onFocus={() => {}}
          />

          <Button onClick={attemptLogin} style={{ width: 130 }} text="Intră" isLoading={isLoading} icon={faArrowRight} />
          <div className="bottom-buttons">
            <Button variant="as-text" text="Am uitat parola" onClick={() => navigate(Screen.ForgotPassword)} />
            <div className="small-separator"></div>
            <Button
              variant="as-text"
              text="Înregistrare"
              onClick={() => (window.location.href = `${process.env.REACT_APP_MENU_BASE_URL}/#register`)}
            />
          </div>
        </form>
        <Button variant="as-text" text="Contact" onClick={() => navigate(Screen.Contact)} />
      </div>
    </Wrapper>
  )
}
