import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  background: ${props => props.theme.white};
  position: absolute;
  left: calc(((100vw - 720px) / 2) - (250px + 20px));
  width: 250px;
  z-index: 10;
  border-radius: 8px;
  padding: 16px;

  .content {
    transition: all 0.2s ease-in-out;

    &.non-business {
      filter: blur(3px);
      user-select: none;
      cursor: pointer;

      .graph {
        margin-top: 65px;
      }

      .bar {
        background: #8fa8bd;
      }
    }
  }

  &:hover {
    .business-button {
      opacity: 1;
    }

    .non-business {
      filter: blur(5px);
    }
  }

  .business-button {
    background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    /* padding-right: 8px; */
    border-radius: 8px;
    cursor: pointer;
    height: 44px;
    width: 145px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    z-index: 10;
    margin: auto;
    opacity: 0.0001;
    transition: opacity 0.2s ease-in-out;

    a {
      color: #fff;
    }

    img {
      width: 22px;
      margin: 0 12px;
    }

    .brand-texts {
      .brand-name {
        font-size: 11px;
        margin-bottom: -3px;
        text-align: left;
      }
    }

    .icon-wrapper {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -10px;
      top: 10px;
      background: ${props => props.theme.white};
      border-radius: 100%;
      padding-bottom: 1px;

      svg {
        color: ${props => props.theme.businessGray};
        font-size: 11px;
      }
    }
  }

  .stat {
    margin-top: 16px;
    color: ${props => props.theme.normalText};

    .label {
      font-size: 14px;
    }

    .value {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .graph {
    height: 80px;
    width: 100%;
    margin-top: 32px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;

    .bar {
      flex: 1;
      background: linear-gradient(50deg, hsl(206 100% 53% / 1), hsl(192deg 98% 52%));
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: ${props => props.theme.white};
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      min-height: 22%;
      position: relative;

      :hover {
        .value {
          display: block;
        }
      }

      .value {
        position: absolute;
        top: -16px;
        left: 0;
        right: 0;
        font-size: 12px;
        color: ${props => props.theme.mutedText};
        text-align: center;
      }
    }
  }
`
