import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useSelector } from 'react-redux'
import { LanguageCode, MenuCategory } from '@meniudigital/shared'
import { categoriesSelector } from 'src/state/selectors'
import { Backdrop } from '../ProductModal/styles'

type Props = {
  onCategoryPick: (category: MenuCategory) => void
}

export default function CategoryPickerModal({ onCategoryPick }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState<string>('')
  const [sourceCategoryName, setSourceCategoryName] = useState<string>('')

  const { list: categories } = useSelector(categoriesSelector)

  useEffect(() => {
    window.openCategoryPicker = open
  }, [])

  const open = (categoryName: string) => {
    setIsOpen(true)
    setSourceCategoryName(categoryName)
    setQuery('')
  }

  const close = () => {
    setIsOpen(false)
  }

  const pickCategory = (category: MenuCategory) => {
    close()
    onCategoryPick(category)
  }

  const sortCategories = (a: MenuCategory, b: MenuCategory) => a.index - b.index
  const filterCategories = (x: MenuCategory) =>
    x.names[LanguageCode.Romanian]!.toLowerCase()
      .normalize('NFKD')
      .replace(/[^\w]/g, '')
      .includes(query.toLowerCase().normalize('NFKD').replace(/[^\w]/g, ''))

  const getCategories = () => categories.filter(filterCategories).sort(sortCategories).slice(0, 5)

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} style={{ zIndex: 100 }} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Alege categorie însoțitoare
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        <ModalContent>
          <div className="question" style={{ marginTop: 0 }}>
            Ce produse va putea adăuga clientul după ce alege un produs din categoria "{sourceCategoryName}"?
          </div>

          <input
            title="Caută categorie..."
            className="search-input"
            placeholder="Caută categorie..."
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ width: '100%' }}
          />

          {getCategories().map(category => (
            <div className="category" key={category.id} onClick={() => pickCategory(category)}>
              {category.names[LanguageCode.Romanian]}
            </div>
          ))}
        </ModalContent>
      </Modal>
    </>
  )
}
