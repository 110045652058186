import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from 'src/global/Button'
import Slider from 'react-slider'
import { useState } from 'react'
import { Wrapper } from './styles'
import moment from 'moment'
import { DaySchedule, formatScheduleHour } from '@meniudigital/shared'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import { UserPatchType } from '@meniudigital/shared'
import SchedulePills from './SchedulePills'
import Checkbox from 'src/global/Checkbox'

const DEFAULT_SCHEDULE: DaySchedule[] = [
  { dayIndex: 1, openHour: 8, closeHour: 22 },
  { dayIndex: 2, openHour: 8, closeHour: 22 },
  { dayIndex: 3, openHour: 8, closeHour: 22 },
  { dayIndex: 4, openHour: 8, closeHour: 22 },
  { dayIndex: 5, openHour: 8, closeHour: 22 },
  { dayIndex: 6, openHour: 8, closeHour: 22, closedAllDay: true },
  { dayIndex: 7, openHour: 8, closeHour: 22, closedAllDay: true },
]

export default function SetSchedule() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state: State) => state.users)
  const [newSchedule, setNewSchedule] = useState(user?.schedule?.schedule.length ? user?.schedule?.schedule : DEFAULT_SCHEDULE)

  const updateHoursForDay = (newHoursForDay: number[], i: number) =>
    setNewSchedule(
      newSchedule.map(x => (x.dayIndex - 1 === i ? { ...x, openHour: newHoursForDay[0], closeHour: newHoursForDay[1] } : x))
    )

  const updateIsClosedForDay = (isClosed: boolean, i: number) =>
    setNewSchedule(newSchedule.map(x => (x.dayIndex - 1 === i ? { ...x, closedAllDay: isClosed } : x)))

  const save = async () => {
    await dispatch(
      patchCurrentUser({
        type: UserPatchType.Schedule,
        data: { schedule: newSchedule, isVisible: user?.schedule?.isVisible || false },
      })
    )
    await window.showConfirm({ text: `Programul de lucru a fost actualizat cu succes.` })

    navigate(-1)
  }

  return (
    <Wrapper className="page">
      <h3>Când e deschis restaurantul tău?</h3>
      <SchedulePills schedule={newSchedule} />
      <div className="panel">
        {newSchedule.map((day, i) => (
          <div key={day.dayIndex} className="day">
            <div className="toggle-wrapper">
              <Checkbox
                text={moment().startOf('week').add(i, 'days').format('dddd')}
                isChecked={!day.closedAllDay}
                onChange={() => updateIsClosedForDay(!newSchedule[i].closedAllDay, i)}
              />
            </div>
            {day.closedAllDay ? (
              <span>Închis</span>
            ) : (
              <div className="day-schedule">
                {formatScheduleHour(day.openHour)}-{formatScheduleHour(day.closeHour)}
              </div>
            )}

            {!day.closedAllDay && (
              <Slider
                className="slider"
                value={[day.openHour, day.closeHour]}
                min={4.0}
                max={30.0}
                minDistance={1}
                step={0.5}
                onChange={newHoursForDay => updateHoursForDay(newHoursForDay, i)}
                renderTrack={(props, state) => {
                  const style = props.style || {}
                  if (state.index === 1) {
                    style.backgroundColor = '#11b7f3'
                    style.height = '3px'
                  }
                  return <div {...props} style={style} />
                }}
              />
            )}
          </div>
        ))}
      </div>
      <Button className="continue-button" text="Salvează" onClick={save} icon={faCheck} />
    </Wrapper>
  )
}
