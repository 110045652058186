import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Wrapper } from './styles'

import Button from 'src/global/Button'
import { formatAddress, getDaysLeft, isBusiness, User } from '@meniudigital/shared'
import { SearchInput } from '../../HomeScreen/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faList,
  faMapMarkerAlt,
  faPencilAlt,
  faQuestionCircle,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons'
import { GoogleMap, OverlayView } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { getUsers, getStatRegistrationsAggregate, setToken, getCurrentUser } from 'src/state/users'
import moment from 'moment'
import Flag from 'src/global/Flag'
import { IS_DESKTOP_OR_TABLET } from '../../HomeScreen'
import EditModal from './EditModal'
import { userEndpoints } from 'src/api'
import { debounce } from 'throttle-debounce'

const ROMANIA_COORDS = { lat: 45.9432, lng: 24.9668 }
const DEFAULT_ZOOM = 7
const PAGE_SIZE = 50
export default function Users() {
  const [query, setQuery] = useState('')
  const { user: { isSuperAdmin } = {}, list: users, stats, isLoading } = useSelector((state: State) => state.users)
  const [viewType, setViewType] = useState<'list' | 'map'>('list')
  const [showsOnlyActiveClients, setShowsOnlyActiveClients] = useState(false)
  const [showsOnlyBusinessClients, setShowsOnlyBusinessClients] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>()

  const nextPage = useRef(Math.floor(users.length / PAGE_SIZE))
  const lastCall = useRef(+new Date())

  const onMapLoad = useCallback((mapRef: google.maps.Map) => setMap(mapRef), [])
  const onMapUnmount = useCallback(() => setMap(null), [])

  const dispatch = useDispatch()

  const onScroll = () => {
    if (+new Date() - lastCall.current < 1000) {
      return
    }

    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    if (scrollTop + clientHeight >= scrollHeight - 1000) {
      dispatch(
        getUsers({
          pageNumber: nextPage.current,
          onlyActive: showsOnlyActiveClients,
          onlyBusiness: showsOnlyBusinessClients,
          searchQuery: query,
        })
      )
      nextPage.current = nextPage.current + 1
      lastCall.current = +new Date()
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [query, showsOnlyActiveClients, showsOnlyBusinessClients])

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getStatRegistrationsAggregate())
    }
  }, [])

  useEffect(() => {
    dispatch(
      getUsers({ pageNumber: 0, onlyActive: showsOnlyActiveClients, onlyBusiness: showsOnlyBusinessClients, searchQuery: query })
    )
    nextPage.current = 1
    lastCall.current = +new Date()
  }, [showsOnlyActiveClients, showsOnlyBusinessClients])

  const zoomOnUser = (user: User) => {
    const currentZoom = map?.getZoom() || DEFAULT_ZOOM

    if (currentZoom > 17) {
      map?.panTo(ROMANIA_COORDS)
      map?.setZoom(DEFAULT_ZOOM)
    } else {
      map?.panTo({ lat: user.coords?.latitude!, lng: user.coords?.longitude! })
      map?.setZoom(currentZoom + 2)
      setTimeout(() => {
        map?.panTo({ lat: user.coords?.latitude!, lng: user.coords?.longitude! })
        map?.setZoom(currentZoom + 4)
      }, 500)
    }
  }

  const impersonateUser = async (user: User) => {
    try {
      await window.showModal({
        title: `Confirmă accesarea contului`,
        text: `Ești sigur că dorești să accesezi contul clientului ${user.username}?`,
        confirmButtonText: 'Accesează',
      })

      const token = await userEndpoints.impersonateUser(user.id)
      if (token) {
        localStorage.clear()
        sessionStorage.clear()
        setTimeout(() => {
          dispatch(setToken(token))
          setTimeout(async () => {
            await dispatch(getCurrentUser())
            setTimeout(() => {
              window.location.href = '/'
            }, 0)
          }, 0)
        }, 0)
      }
    } catch {}
  }

  const search = (newValue: string) => {
    dispatch(
      getUsers({
        pageNumber: 0,
        onlyActive: showsOnlyActiveClients,
        onlyBusiness: showsOnlyBusinessClients,
        searchQuery: newValue,
      })
    )
    nextPage.current = 1
    lastCall.current = +new Date()
  }

  const searchDebounced = useCallback(debounce(300, search), [showsOnlyActiveClients, showsOnlyBusinessClients])

  return (
    <>
      <Wrapper>
        <div className={'top-bar ' + (viewType === 'map' ? 'hovering' : '')}>
          <SearchInput
            placeholder="Caută client"
            value={query}
            onChange={e => {
              setQuery(e.target.value)
              searchDebounced(e.target.value)
            }}
          />
          <div className="list-toggle">
            <div className={'list-toggle-button ' + (viewType === 'list' ? 'active' : '')} onClick={() => setViewType('list')}>
              <FontAwesomeIcon className="icon" icon={faList} />
            </div>
            <div className={'list-toggle-button  ' + (viewType === 'map' ? 'active' : '')} onClick={() => setViewType('map')}>
              <img src="/romania-outline.svg" alt="Romania outline" />
            </div>
          </div>
        </div>

        {viewType === 'list' ? (
          <>
            <div className="stats-and-checkbox">
              {stats && (
                <div className="stats">
                  {[
                    { interval: 'today', name: IS_DESKTOP_OR_TABLET ? 'Useri noi astăzi' : 'Astăzi' },
                    { interval: 'yesterday', name: 'Ieri' },
                    { interval: 'day-before-yesterday', name: 'Alaltăieri' },
                    { interval: 'thirty-days', name: 'Ultimele 30 zile' },
                  ].map(({ interval, name }) => (
                    <div key={interval} className="stat">
                      <span>{name}</span>
                      <div className="stat-value">{stats[interval as 'today']?.total}</div>
                      <div className="referral-values">
                        <div className="referral-value">
                          <img loading="lazy" src={`/logos/facebook.png`} alt={`Facebook logo`} />
                          {stats[interval as 'today']?.facebook}
                        </div>
                        <div className="referral-value">
                          <img loading="lazy" src={`/logos/google.png`} alt={`Google logo`} />
                          {stats[interval as 'today']?.google}
                        </div>
                        <div className="referral-value">
                          <img loading="lazy" src={`/logo.svg`} alt={`MeniuDigital logo`} />
                          {stats[interval as 'today']?.none}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="filters">
                    <div
                      className={'filter-button' + (showsOnlyActiveClients ? ' active' : '')}
                      onClick={() => setShowsOnlyActiveClients(!showsOnlyActiveClients)}
                    >
                      5+ products
                    </div>
                    <div
                      className={'filter-button' + (showsOnlyBusinessClients ? ' active' : '')}
                      onClick={() => setShowsOnlyBusinessClients(!showsOnlyBusinessClients)}
                    >
                      Business
                    </div>
                  </div>
                </div>
              )}
            </div>

            {users.map(user => (
              <div className="panel client" key={user.id}>
                <div
                  className="field hoverable"
                  onClick={() => window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${user.username}`)}
                  style={{ background: `linear-gradient(to right, #11b7f3, #11b7f322)`, borderRight: 0 }}
                >
                  {user.coverPhotoUrl && <img loading="lazy" className="cover-photo" src={user.coverPhotoUrl} alt="User cover" />}
                  <div className="referral-icon" title="User Referral Source">
                    <img
                      src={referralIcons[user.referralSource || 'none'] || './romania-outline.svg'}
                      loading="lazy"
                      title={user.referralSource}
                      alt={user.referralSource}
                    />
                  </div>
                  {user.lastRemindedAboutExpiryAt && (
                    <div className="reminded-ago">
                      🔔{' '}
                      {getDaysPassed(user.lastRemindedAboutExpiryAt || '') === 0
                        ? 'astăzi'
                        : `acum ${getDaysPassed(user.lastRemindedAboutExpiryAt || '')} zi${
                            getDaysPassed(user.lastRemindedAboutExpiryAt || '') > 1 ? 'le' : ''
                          }`}
                    </div>
                  )}
                  <div className="client-info">
                    {user.logoUrl && <img loading="lazy" src={user.logoUrl} alt="User logo" />}
                    <div className="flex-column">
                      <span className="bolder">
                        {user.name}
                        {isBusiness(user) && <span className="business-logo">B</span>}
                      </span>
                      <span>/{user.username}</span>
                      <span>{user.city}</span>
                      {Boolean(user.coords?.latitude) && (
                        <>
                          {user.coords?.addressObject && (
                            <span>
                              <FontAwesomeIcon style={{ marginRight: '8px', color: '#fff' }} icon={faMapMarkerAlt} />
                              {formatAddress(user.coords.addressObject)}
                            </span>
                          )}
                        </>
                      )}
                      {user.subscriptionExpiresAt && (
                        <span
                          className="business-logo"
                          style={{
                            fontWeight: 500,
                            marginLeft: 0,
                            background: `linear-gradient(33deg, hsl(210deg 32% 30%), hsl(210deg 32% 30%), ${getColourFor(
                              getDaysLeft(user.subscriptionExpiresAt)
                            )})`,
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>{getDaysLeft(user.subscriptionExpiresAt)}</span> zile rămase
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field full white-bg" style={{ zIndex: 3 }}>
                  {IS_DESKTOP_OR_TABLET && (
                    <>
                      <label>ID</label>
                      <span>{user.id}</span>
                    </>
                  )}

                  <label>Înregistrat pe</label>
                  <span>
                    {moment(user.joinDate).format('DD MMM @ HH:mm')} (acum {moment(user.joinDate).fromNow(true)})
                  </span>
                  <label>E-mail </label>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {user.isEmailConfirmed ? (
                      <FontAwesomeIcon title="Email Confirmed" color="#7ac943" className="icon" icon={faCheckCircle} />
                    ) : (
                      <FontAwesomeIcon title="Email Unconfirmed" color="#ff5723" className="icon" icon={faQuestionCircle} />
                    )}{' '}
                    {user.email}
                  </span>
                  <div className="icons-list">
                    {user.menuLanguages?.map(languageCode => (
                      <Flag key={languageCode} of={languageCode} />
                    ))}
                  </div>
                  <div className="icons-list" style={{ bottom: '7px', top: 'auto', right: '3px' }}>
                    {user.businessInfo
                      ?.filter(x => x.isVisible)
                      .map(({ type, url }) => (
                        <a href={url} target="_blank" rel="noreferrer" key={type}>
                          <img loading="lazy" src={`logos/${type}.png`} alt={`${type} logo`} />
                        </a>
                      ))}
                    {user.wifiInfo?.isVisible && (
                      <a href="#">
                        <img loading="lazy" src={`icons/wifi.png`} alt="Wifi symbol" />
                      </a>
                    )}
                  </div>

                  <label>Mărime Meniu</label>
                  <span>
                    <b>{(user as any).categoryCount}</b> categorii, <b>{(user as any).productCount}</b> produse,{' '}
                    <b>{user.quotas?.nutritionalGenerations || 0}</b> generări
                  </span>
                </div>

                {IS_DESKTOP_OR_TABLET && (
                  <div className="field actions">
                    <Button
                      variant="as-text"
                      text="Vezi meniu"
                      onClick={() => window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${user.username}`)}
                    />
                    <Button
                      icon={faPencilAlt}
                      onClick={() => window.openUserEditModal(user)}
                      style={{ width: '220px' }}
                      text="Modifică date client"
                    />
                    <Button
                      icon={faUserSecret}
                      onClick={() => impersonateUser(user)}
                      style={{ width: '220px' }}
                      text="Accesează cont"
                    />
                  </div>
                )}
              </div>
            ))}
            <span style={{ padding: 32, fontWeight: 500 }}>
              {isLoading || !users.length ? 'Se încarcă...' : 'Sfârșit de listă :D'}
            </span>
          </>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            center={ROMANIA_COORDS}
            zoom={DEFAULT_ZOOM}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
          >
            {users
              .filter(x => x.coords?.latitude && x.coords?.longitude)
              .map(user => (
                <OverlayView
                  key={user.id}
                  position={{ lat: user.coords?.latitude!, lng: user.coords?.longitude! }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    onClick={() => zoomOnUser(user)}
                    className="client-marker-wrapper"
                    style={{
                      position: 'relative',
                      zIndex: getDaysLeft(user.subscriptionExpiresAt),
                    }}
                  >
                    <div
                      className="client-marker"
                      style={{
                        borderColor: getColourFor(getDaysLeft(user.subscriptionExpiresAt)),
                      }}
                    >
                      <img loading="lazy" src={user.logoUrl} alt="Restaurant logo" className="client-logo" />
                      <span className="client-name">{user.name}</span>
                    </div>
                  </div>
                </OverlayView>
              ))}
          </GoogleMap>
        )}
        <EditModal />
      </Wrapper>
    </>
  )
}

const referralIcons = {
  facebook: '/logos/facebook.png',
  google: '/logos/google.png',
  none: '/logo-white.svg',
}

export const getDaysPassed = (fromDate: Date | undefined) => {
  const expiresTimestamp = fromDate ? +new Date(fromDate) : +new Date()
  const currentTimestamp = +new Date()
  const day = 24 * 60 * 60 * 1000

  return Math.floor((currentTimestamp - expiresTimestamp) / day)
}

function getColourFor(daysLeft: number): string {
  if (daysLeft < 1) {
    return 'black'
  }

  let hueValue = daysLeft * 2.5

  hueValue = hueValue > 120 ? 120 : hueValue

  return `hsl(${hueValue},  100%, 37%)`
}
