import React from 'react'

import { Bar } from 'react-chartjs-2'
import { NavigationStatsObject } from 'src/api'

export default function UserSummary({ data }: { data: NavigationStatsObject['userSummary'] }) {
  return (
    <Bar
      width={Math.max(window.innerWidth - 120, 370)}
      height={window.innerHeight / 3}
      data={{
        labels: data.map(x => x.label),
        datasets: [
          {
            label: 'Count',
            data: data.map(x => x.count),
            backgroundColor: '#689f38',
          },
        ],
      }}
      options={{
        responsive: false,
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'start',
          },
          title: {
            position: 'top',
            align: 'start',
            display: true,
            text: `Total users summary - all time`,
          },
        },
      }}
    />
  )
}
