import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .language-icon {
    width: 20px;
    height: 20px;
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 720px;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

    @media (max-width: 768px) {
      width: min(1024px, calc(100vw - 32px)) !important;
      margin-right: 0;
    }
  }

  label.checkbox {
    cursor: pointer;
    display: block;
    font-size: 16px;
    text-transform: none;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;

    .label-text {
      color: ${props => props.theme.normalText};
    }

    input[type='checkbox'] {
      display: none;

      + .label-text:hover:after {
        color: ${props => props.theme.accent};
      }

      + .label-text:after {
        content: '\f096';
        font-family: 'fontAwesome';
        line-height: 1;
        display: inline-block;
        font-size: 26px;
        margin-right: 0px;
        margin-left: 7px;
        position: relative;
        top: 5px;
        height: 40px;
      }

      :checked + .label-text:after {
        content: '\f14a';
        color: ${props => props.theme.accent};
        animation: tick 150ms ease-in;
      }

      :disabled + .label-text:after {
        content: '\f0c8';
        color: #dddfe6;
      }
    }
  }

  .hovering {
    background: #fffc;
    border-radius: 16px;
    padding: 16px;
    position: relative;
    z-index: 2;
    box-shadow: 4px 4px 12px #0004;
  }

  .map-container {
    height: calc(100vh - 56px);
    width: 100vw;
    overflow: hidden;
    margin-top: -98px;

    @media (max-width: 768px) {
      height: calc(100vh - 198px);
    }

    .client-marker-wrapper {
      padding-right: 8px;

      .client-marker {
        background: #fff;
        border-radius: 16px;
        border-top-left-radius: 0;
        box-shadow: 4px 4px 12px #0008;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 3px solid red;

        .client-logo {
          height: 100%;
        }

        .client-name {
          height: 100%;
          padding-left: 8px;
          padding-right: 16px;
          display: flex;
          align-items: center;
          /* border-bottom: 2px solid red; */

          font-size: 14px;
          font-weight: 500;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
  }

  .list-toggle {
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    overflow: hidden;

    .list-toggle-button {
      height: 40px;
      width: 50px;
      margin: 0 4px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      img {
        width: 100%;
      }

      &.active {
        background: ${props => props.theme.white};

        img {
          filter: invert(42%) sepia(93%) saturate(481%) hue-rotate(38deg) brightness(105%) contrast(119%);
        }
      }

      :not(.active):hover {
        background: ${props => props.theme.white}88;
      }
    }
  }

  .client {
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    width: min(100vw - 32px, 1250px);
    margin-top: 16px;
    align-items: stretch;
    position: relative;
    box-shadow: 4px 0 12px ${props => props.theme.middleShadow};
    border: 1px solid ${props => props.theme.border};
    overflow: hidden;
    max-width: 1400px;

    .field {
      display: flex;
      flex-direction: column;
      padding: 16px;
      padding-right: 16px;
      border-right: 1px solid ${props => props.theme.border};
      flex: 1;
      position: relative;
      overflow: hidden;

      &.white-bg {
        background: ${props => props.theme.white};
      }

      &.full {
        @media (max-width: 768px) {
          min-width: 90vw;
        }
      }

      &.hoverable {
        cursor: pointer;

        &:hover {
          filter: brightness(1.1);
        }
      }

      &.actions {
        max-width: 405px;
        justify-content: center;
        align-items: center;
      }

      label {
        margin-top: 0;
        margin-bottom: 4px;

        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }

      [data-variant='as-text'] {
        height: 30px;
      }

      .cover-photo {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: calc(100% + 32px);
        object-fit: cover;
        z-index: 0;
        filter: brightness(0.5);
      }

      .client-info {
        display: flex;
        align-items: flex-start;
        position: relative;
        color: #fff;
        font-size: 18px;

        .business-logo {
          display: inline-block;
          padding: 3px 8px;
          margin-left: 4px;
          font-weight: 700;
          border-radius: 8px;
          margin-bottom: -8px;
          background: linear-gradient(33deg, hsl(210deg 32% 30%), hsl(210deg 32% 40%));
          box-shadow: 4px 4px 12px ${props => props.theme.shadow};
          border: 1px solid #fff4;
        }

        & > *:not(.cover-photo) {
          z-index: 1;
        }

        span {
          margin-bottom: 8px;
          font-weight: 500;

          &.bolder {
            font-weight: 700;
          }
        }

        .flex-column {
          display: flex;
          flex-direction: column;
        }
      }

      .referral-icon {
        position: absolute;
        top: 16px;
        right: 14px;
        z-index: 10;
        width: 24px;
        color: #fff;
        filter: drop-shadow(0px 0px 10px ${props => props.theme.accent});

        img {
          width: 100%;
        }
      }

      .days-left {
        position: absolute;
        width: 180px;
        bottom: 0;
        left: 0;
        padding: 8px 12px;
        background: green;
        border-top-right-radius: 16px;
        color: #fff;
        z-index: 10;
      }

      .reminded-ago {
        position: absolute;
        bottom: 0;
        left: 180px;
        width: 180px;
        padding: 8px 12px;
        border-top-right-radius: 16px;
        color: #fff;
        z-index: 10;
      }

      img {
        width: 100px;
        margin-right: 16px;
      }

      .icons-list {
        position: absolute;
        top: 10px;
        right: 8px;
        display: flex;
        flex-direction: row;

        img {
          width: 20px;
          margin-right: 8px;
        }
      }

      :last-of-type {
        border-right-width: 0;
      }

      .order-status {
        position: absolute;
        font-weight: 700;
        color: #fff;
        padding: 8px;
        border-radius: 16px;
        background-color: orange;
        text-align: center;
        bottom: 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
        width: calc(100%);
        width: 280px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 14px;
      }
    }
  }

  .stats-and-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    width: min(100vw - 32px, 1250px);
  }

  .stats {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 16px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;

      .stat {
        flex: 1;
      }
    }

    .stat {
      background: ${props => props.theme.white};
      border-radius: 8px;
      padding: 8px 12px;

      span {
        font-size: 14px;
        font-weight: 500;
      }

      .stat-value {
        font-weight: 500;
        font-size: 30px;
        color: ${props => props.theme.accent};
      }
    }

    .referral-values {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      font-size: 18px;
      border-top: 1px solid ${props => props.theme.border};
      padding-top: 8px;

      img {
        width: 15px;
        margin-right: 2px;

        @media (max-width: 768px) {
          width: 12px;
        }
      }

      .referral-value {
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }
  }

  .filters {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    margin-left: 8px;

    .filter-button {
      background: ${props => props.theme.white};
      border-radius: 8px;
      padding: 10px 16px;
      cursor: pointer;
      font-weight: 500;
      box-shadow: 4px 0 12px ${props => props.theme.middleShadow};

      &:hover {
        background: ${props => props.theme.accentForHover};
        box-shadow: 4px 0 12px ${props => props.theme.darkerShadow};
      }

      &.active {
        background: ${props => props.theme.blue};
        color: ${props => props.theme.white};
        font-weight: 700;
      }
    }
  }
`
