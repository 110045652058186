export type EmptyProductProps = { index?: number; quantities?: string; kcalories?: string; price?: number; categoryId?: string }

export enum EditMode {
  Create = 'create',
  Edit = 'edit',
  Clone = 'clone',
}

export type SelectedImageProperties = {
  width: number
  height: number
  hasWhiteBackground: boolean
  isGeneric: boolean
}

export type SelectedFileProperties = {
  sizeInKb: number
  uncompressedSizeInKb: number
}

export type SelectedImage = {
  binary: File | Blob
  base64: string
  name: string
}
