import React, { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { SearchInput } from '../HomeScreen/styles'
import moment from 'moment'
import Reservation from './Reservation'
import { getReservations } from 'src/state/consumer-reservations'
import { State } from 'src/state'

export default function ReservationsScreen() {
  const [query, setQuery] = useState('')
  const dispatch = useDispatch()

  const reservations = useSelector((state: State) => state.reservations.list)

  useEffect(() => {
    dispatch(getReservations())
  }, [])

  const filteredReservations = reservations
    .filter(x => (x.consumer.fullName || '').toLowerCase().includes(query.toLowerCase()))
    .sort((a, b) => (+moment(b.createdAt) > +moment(a.createdAt!) ? 1 : -1))
    .slice(0, 50)

  return (
    <>
      <Wrapper>
        <div className="top-bar">
          <SearchInput
            placeholder="Caută rezervare..."
            value={query}
            onChange={e => {
              setQuery(e.target.value)
            }}
          />
        </div>

        <div className="action-list">
          {filteredReservations.map(x => (
            <Reservation reservation={x} />
          ))}
        </div>
      </Wrapper>
    </>
  )
}
