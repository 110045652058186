import styled from 'styled-components/macro'

let shrinkFactor = 0.8

if (window.innerWidth < 1500) {
  shrinkFactor = 0.8
}
if (window.innerWidth < 1300) {
  shrinkFactor = 0.65
}

export const Wrapper = styled.div`
  position: absolute;
  right: calc(((100vw - 720px) / 2) - (372px * ${shrinkFactor} + 24px));
  width: 372px * ${shrinkFactor};
  z-index: 10;
  border-radius: 8px;

  .preview {
    overflow: hidden;
    width: calc(372px * ${shrinkFactor});
    height: calc(750px * ${shrinkFactor});
    position: relative;
    perspective: 3127px;
    perspective-origin: bottom;
    transition: transform 0.5s ease-in-out;
    filter: drop-shadow(0px 0px 4px #0004);
  }

  .iphone-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }

  .iframe-wrapper {
    height: 100%;
    width: calc(100% - 40px * ${shrinkFactor});
    height: calc(100% - 37px * ${shrinkFactor});
    margin-left: calc(20px * ${shrinkFactor});
    margin-top: calc(18px * ${shrinkFactor});
    overflow: hidden;
    border-radius: 40px * ${shrinkFactor};
    transform-style: preserve-3d;
    background: #fff;
  }

  .notch-area {
    width: 100%;
    margin-bottom: -5px;
    border-top-right-radius: 40px;
    border-top-left-radius: 30px;
  }

  iframe {
    height: 100%;
    width: calc(100% - 0px);
    border: none;
  }

  .refresh-button {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -26px;
    width: 80px;
    height: 28px;
    background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: -1;
    box-shadow: 0 2px 12px ${props => props.theme.middleShadow};
    transition: all 0.3s ease-out;

    :hover {
      box-shadow: 0 2px 12px ${props => props.theme.darkerShadow};
      filter: brightness(109%);

      svg {
        transform: rotate(180deg);
      }
    }

    :active {
      box-shadow: 0 0px 0px ${props => props.theme.darkerShadow};

      svg {
        transform: rotate(270deg);
      }
    }

    svg {
      font-size: 16px;
      color: ${props => props.theme.white};
      transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    }
  }

  .priority-indicator {
    background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);

    .explainer {
      background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
      width: 267px;
      color: #fff;
      border-radius: 16px;
      position: absolute;
      top: 36px;
      right: 0;
      padding: 8px 12px;
      box-shadow: 0 2px 12px ${props => props.theme.darkerShadow};
      font-weight: 500;
      font-size: 15px;

      transform: translate(120px, -35px) scale(0);
      transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1);
    }

    &.active {
      background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));

      svg {
        color: ${props => props.theme.white};
      }

      .explainer {
        background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
      }
    }

    &:hover {
      box-shadow: 0 2px 12px ${props => props.theme.darkerShadow};

      .explainer {
        transform: translate(0) scale(1);
      }
    }

    svg {
      font-size: 14px;
      color: ${props => props.theme.white};
    }
  }
`
