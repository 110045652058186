import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'src/global/Button'
import { userEndpoints } from 'src/api'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'

export default function SetNewPassword() {
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()

  const [username, setUsername] = useState('')

  useEffect(() => {
    populateUsername()
  }, [])

  const populateUsername = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    try {
      const { username: u } = await userEndpoints.getPasswordResetRequest(params.token)
      setUsername(u)
    } catch (e) {
      alert('Linkul nu este valid')
      setTimeout(() => {
        navigate(Screen.Home)
      }, 0)
    }
  }

  const onSubmit = async (passwords: { newPassword: string; newPasswordAgain: string }) => {
    const { newPassword, newPasswordAgain } = passwords
    if (newPassword.length < 6) {
      alert('Parola trebuie să aibă cel puțin 6 caractere.')
      return
    }
    if (newPassword !== newPasswordAgain) {
      alert('Parolele nu sunt la fel.')
      return
    }

    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      await userEndpoints.setNewPassword({ ...passwords, token: params.token })

      await window.showModal({
        title: `Parolă schimbată`,
        text: `Parola ta a fost schimbată cu succes. De acum te poți loga folosind ID-ul de utilizator "${username}" și noua ta parolă.`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })

      navigate(Screen.Login)
    } catch (e) {
      await window.showModal({
        title: `Eroare`,
        text: `Ceva nu a mers ok. Te rog încearcă din nou.`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })
    }
  }

  return (
    <div className="page">
      <form onSubmit={handleSubmit(onSubmit as any)}>
        <label htmlFor="username">ID restaurant</label>
        <input autoComplete="username" type="text" id="username" maxLength={30} value={username} disabled />

        <label>parola nouă</label>
        <input type="password" maxLength={50} placeholder="parolă nouă" {...register('newPassword')} />

        <label>confirmă parola nouă</label>
        <input type="password" maxLength={50} placeholder="confirmă parola nouă" {...register('newPasswordAgain')} />

        <Button style={{ alignSelf: 'center' }} type="submit" text="Schimb parola" />
      </form>
    </div>
  )
}
